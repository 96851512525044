const ja_JP = {
  // header
  'header.ticket.text': '引換券',
  // AssetSales
  'assetSales.header': 'アセット一覧',
  'assetSales.button': 'More',
  // Fortune
  'fortune.param.error':
    '日付・性別設定が正しくありません。もう一度確認してください。',
  'fortune.fortune.button': '今日の運勢',
  'fortune.fortune.title': '運勢',
  'fortune.fortune.text.1':
    '<p>この占いは陰陽五行に基づいています。<br/>陰陽五行説は、自然界の万物は陰と陽の二気から生ずるとする陰陽と、万物は木・火・土・金・水の五行からなるとする五行思想を組み合わせた占術です。<br/></p>',
  'fortune.fortune.text.2':
    '<p>あなたの誕生日から12種類の運命星が決まります<br><br>五行説と陰陽説を組み合わせた十干と十二支を、それぞれ順番に暦に振っていくと60通りの組み合わせが出来ます。そして、これら60通りの組み合わせを６つのグループに分けたものを宿命星と言います。宿命星には「土星人」「金星人」「火星人」「準星人」「木星人」「水星人」があり、生まれた日の十干と十二支の組み合わせによって自分がどの宿命星に属するかが決まります。<br><br><strong>土星人+、土星人-、金星人+、金星人-、火星人+、火星人-、<br class="pc">準星人+、準星人-、木星人+、木星人-、水星人+、水星人-</strong><br><br>運気パワーにはリズムがあります。リズムは12種類の要素で循環します。<br><br><strong>結実期、禄財期、収穫期、危険期、沖殺期、忍耐期、<br class="pc">	実行期、成長期、決断期、疲労期、制覇期、油断期</strong><br></p>',
  // FortuneResult
  'fortuneResult.unmai.star.title': '運命星',
  'fortuneResult.unmai.star.text':
    'あなたの誕生日から12種類の運命星が決まります。',
  'fortuneResult.unmai.star.graph':
    '<table class="fortuneTable1"><tr><td>土星人+</td><td>Saturn+</td><td>空間、刺激、神秘、気位、理想、攻撃、向上心、合理的、孤独、電波</td></tr><tr><td>土星人-</td><td>Saturn-</td><td>繊細、研究熱心、持久力、感受性、自尊心、執拗、一直線、冷静、教育、知性</td></tr><tr><td>金星人+</td><td>Venus-</td><td>開拓、希望、新規、突進、復興、好奇心、現実、貫く、夜明け、闘志</td></tr><tr><td>金星人-</td><td>Venus-</td><td>前進、平和、自由、平等、マイペース、単調、情報、淡白、解放、軽快</td></tr><tr><td>火星人+</td><td>Mars+</td><td>清潔、神経質、制動、質素、こだわり、警戒、過去、分析、理性、家族</td></tr><tr><td>火星人-</td><td>Mars-</td><td>無限、記録、頑固、責任感、大胆、スロースターター、強情、晩年、鍛錬、相続</td></tr><tr><td>準星人+</td><td>Moon+</td><td>理解、充実、包容力、誠実、忠実、人情、 優柔不断、温厚、無欲、横社会</td></tr><tr><td>準星人-</td><td>Moon-</td><td>心配、親切、不安、同情、短気、パニック、福祉、人間味、協調、愚痴</td></tr><tr><td>木星人+</td><td>Jupiter+</td><td>変身、再生、真似、熟考、器用、策士、実直、地味、参謀、知恵</td></tr><tr><td>木星人-</td><td>Jupiter-</td><td>計画性、したたか、多趣味、ブランド、堅実、努力、センス、饒舌、奥手、粘り強い</td></tr><tr><td>水星人+</td><td>Mercury+</td><td>陽気、活発、競争、サービス精神、開放的、情熱、軽率、華やか、浪費、無責任</td></tr><tr><td>水星人-</td><td>Mercury-</td><td>笑顔、現実的、仲介、怠惰、曖昧、虚構、社交的、柔軟、享楽、ムードメーカー</td></tr></table>',
  'fortuneResult.unmai.power.title': '運気パワー',
  'fortuneResult.unmai.power.text':
    '運気パワーにはリズムがあります。リズムは12種類の要素で循環します。',
  'fortuneResult.unmai.power.graph':
    "<table class='fortuneTable2'><tr><td>結実期</td><td>実りの時期。今まで積み重ねてこなければ結果も無に。</td></tr><tr><td>禄財期</td><td>一番の強運期。物事が成就したり、財運にも恵まれる。</td></tr><tr><td>収穫期</td><td>絶頂期は過ぎ、来る天沖殺期に備える時。何ごとも早めに。</td></tr><tr><td>危険期</td><td>予期しないことが起こりやすくなる。天冲殺期の始まり。</td></tr><tr><td>沖殺期</td><td>一番の低調期。受け身の姿勢を保ち、慎重に行動を。</td></tr><tr><td>忍耐期</td><td>運勢の回復が見られても、まだ動く時ではない。焦らずに。</td></tr><tr><td>実行期</td><td>種まきの時期。自分の信念に基づいて行動すべき時。</td></tr><tr><td>成長期</td><td>大きく広がっていく時期。積極的に行動すると良い。</td></tr><tr><td>決断期</td><td>決断と実行。ここでの決断が、のちの運命を左右する。</td></tr><tr><td>疲労期</td><td>見直しの時期。決断期に決めたことを曲げないように。</td></tr><tr><td>制覇期</td><td>周囲から認められ、何事もうまくいく時。気力も充実。</td></tr><tr><td>油断期</td><td>現状維持が鍵となる。うまくいっていても油断は禁物。</td></tr></table>",

  'fortuneResult.fortune.header': '運勢',
  'fortuneResult.fortune.symbol': '運命星',
  'fortuneResult.fortune.cycle': '運気パワー',
  'fortuneResult.unmai.star.description': '運命星と運気パワー',
  'fortuneResult.unmai.star.close': '閉じる',
  'fortuneResult.asset.message': 'お好みのアセットを手に入れましょう！',
  'fortuneResult.no.asset.message': 'アセット準備中、暫くお待ちください。',
  'fortuneResult.asset.button': 'Get It !',
  // MyAsset
  'myAsset.header': '保有アセット',
  'myAsset.button': 'More',
  // TermsOfService
  // Top
  //　終了予告
  'service.end.notice':
    '\n2023年9月1日を持ちまして「dApps神社」は全サービスを終了いたしました<br>\n',
  'service.end.guidance':
    '<ul class="topCol11ul"><li>\n2023年9月1日を持ちまして「dApps神社」は全サービスを終了いたしました。<br>ご利用頂き誠にありがとうございました。<br><br>◆注意点<br></li>すでに配布されたNFTは引き続き所有することが可能ですが、それに関連付けられた画像の表示が削除される可能性があります。<br>また、本件につきまして如何なる補償も致しかねますので、ご了承のほどよろしくお願いいたします。<br><br><li>As of Sep 1st, 2023, "dApps Shrine" has terminated all its services.<br>Thank you very much for using our services<br><br>◆Points to Note<br>You can continue to own any already distributed NFTs; however, there is a possibility that the images associated with them may be removed.\nAlso, please understand that we are unable to offer any compensation in regard to this matter.</li></ul>',
  'top.get.fortune':
    ' 今すぐ運勢をみる',
  'top.what.dapps.jinja.summary':
    '<ul class="topCol11ul"><li><strike>0.02ETH</strike><span style="font-size:0.8em;"> ※ </span>であなたの運勢を占うサービスです。<br><span style="font-size:0.8em;">（※ETH = 仮想通貨イーサリアム、Gas手数料はご負担頂きます。）</span></li><li>今日の運勢の詳細と、5日先までの運気パワーを知ることが出来ます。</li><li>購入日から30日間は毎日無料で占えます。</li><li>アセットと交換できるチケットがもらえます。</li></ul>',
  'top.what.digital.asset.summary':
    '<p>占いを購入するとアセットと交換できるチケットがもらえます。もらえるアセットは他のdAppsゲームで使えるものやdApps神社オリジナルのものがあります。</p>',
  'top.what.digital.asset.text1':
    '<p>チケットで交換できるアセットはEthereumのERC721トークンです。購入すると所有権はあなたのものとなり<a href="https://opensea.io" target="_blank" rel="noopener noreferrer">OpenSea.io</a>などの外部のアセット交換所で自由に流通・売買することができます。</p>',
  'top.what.digital.asset.text2':
    '<p class="topCol23">チケットで交換できるアセット（NFT）は、dApps神社オリジナルアセットか他のdAppsゲームのアセットのいずれかひとつを選べます。<br />アセットの詳しい情報は[MyAsset]メニューで見ることが出来ます。</p>',
  'top.what.digital.asset.text3':
    '<p><strong>dApps神社オリジナルアセット</strong><br />所有権はあなたにあります。<br />商用利用でない限り、ご自由にお使い下さい。<br />例）T-shirt や マグカップ に印刷<br /></p>',
  'top.what.digital.asset.text4':
    '<p><strong>他のdAppsゲームのアセット</strong><br />アセットの詳細情報から配布元のサイトに移動してdAppsゲームを楽しんで下さい。<br /></p>',
  'top.what.start.summary':
    '<p>dApps神社で占うには仮想通貨イーサリウム（Ethereum／ETH）と対応するウォレットが必要です。</p><p><img src="/img/top/wakaba.svg" class="wakaba">ブロックチェーンアプリ（dApps）や仮想通貨について詳しく知りたい方は以下の記事をご参照ください（外部サイト）</p><p><a href="https://dappsmarket.net/beginners/" target="_blank" rel="noopener noreferrer" class="textLink">初めてのdApps（dApps market）</a></p>',
  'top.what.start.ready':
    '<p><strong style="font-size:1.2em;">準備方法</strong><br />イーサリウム（Ethereum／ETH）を管理するためのウォレットが必要です。<br />METAMASKが世界でもっとも使われているウォレットです。<br />METAMASKはChromeブラウザーに対応しています。<br /></p>',
  'top.what.start.way':
    '<p>ChromeブラウザにMETAMASKをインストールしてください。<br /><strong>▼</strong><br />METAMASKをインストールしたら、ウォレットを作成してください。<br /><strong>▼</strong><br />イーサリウム（Ethereum／ETH）は仮想通貨取引所で購入できます。<br /><strong>▼</strong><br />作成したウォレットに、イーサリウム（Ethereum／ETH）を送金します。<br /><i class="fas fa-asterisk" style="color:#E17726;"></i> さらに詳しいMETAMASKの導入方法については、以下の記事をご参照ください（外部サイト）<br /><a href="https://dappsmarket.net/beginner/metamask/app-howtouse/" target="_blank" rel="noopener noreferrer" class="textLink">スマホ版 META MASK 導入方法（dApps market）</a><br /><a href="https://dappsmarket.net/beginner/metamask/metamask-touroku/" target="_blank" rel="noopener noreferrer" class="textLink">PC版 META MASK 導入方法（dApps market）</a><br /><br /></p>',
  // Transaction
  'transaction.waiting.text':
    '<p class="assetModalPart">The Ethereum network is processing your transaction.<br />あなたの要求はイーサリアムネットワークで処理中です。<br />Please wait a moment.<br />少々おまちください。</p>',
  'transaction.waiting.status': 'Waiting',
  'transaction.complete.text':
    '<p class="assetModalPart">The Ethereum network is processing your transaction.<br />あなたの要求はイーサリアムネットワークで処理中です。<br />Please wait a moment.<br />少々おまちください。</p>',
  'transaction.complete.status': 'Completion',
  'transaction.failed.text':
    '<p class="assetModalPart">The Ethereum network is processing your transaction.<br />あなたの要求はイーサリアムネットワークで処理中です。<br />Please wait a moment.<br />少々おまちください。</p>',
  'transaction.failed.status': 'Failed',
  // Unlock
  // Footer
  'footer.caution':
    '当サービスは、 Google Chromeブラウザでのご利用を推奨しています。他のブラウザをご利用の場合、正しく表示されない可能性があります。<br>また、全てのコンテンツをご利用いただくには、JavaScript、Cookieの設定を有効にしてください。',
  // Error
  'error.main': 'サーバーで予期せぬエラーが発生しました。',
  'error.notMain': 'メインイーサリアムネットワークに切り替えてください。'
};
export default ja_JP;
