import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { withStyles } from '@material-ui/core/styles';
import '../css/loading/loading1.css';

const styles = theme => ({
  dialogRoot: {
    zIndex: '10000 !important',
    minWidth: '0 !important'
  }
});

class WaitDialog extends Component {
  constructor(props) {
    super(props);

    let text = 'waiting...';
    if (this.props.waitText !== void 0) {
      text = this.props.waitText;
    }
    this.state = {
      text: text
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        style={{ textAlign: 'center', minHeight: '400px', minWidth: '400px' }}
        onClose={this.props.onClose}
        classes={{
          root: classes.dialogRoot
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <img src="./loading/oval.svg" alt="" />
          </div>
          {this.state.text}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(WaitDialog);
