import { connect } from 'react-redux';
import * as common from '../actions/common';
import * as wallet from '../actions/wallet';
import * as fortune from '../actions/fortune';
import FortuneResult from '../views/FortuneResult';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // common
    initBaseLogicOfDidMount: (_props, _account) =>
      dispatch(common.initBaseLogicOfDidMount(_props, _account)),
    setAssetListCreator: _assetListCreator =>
      dispatch(common.setAssetListCreator(_assetListCreator)),

    // wallet
    connectWallet: _tryActivate => dispatch(wallet.connectWallet(_tryActivate)),
    // fortune
    setRefFortuneResult: _bool => dispatch(fortune.setRefFortuneResult(_bool))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FortuneResult);
