/**
 * ストレージ保存有効期限(時)
 */
export const STORAGE_EXPIRATION = 2;

/**
 * トークンデータ初期値
 */
export const DEFAULT_TOKEN_NAME = 'no name';
export const DEFAULT_TOKEN_IMAGE = '/asset/_no_asset.svg';
export const DEFAULT_TOKEN_DESCRIPTION = 'no description';

/**
 * ロード中トークン画像
 */
export const LOADING_TOKEN_IMAGE = '/asset/_asset_loading.svg';

/**
 * トークン情報取得失敗時の値
 */
export const ERR_TOKEN_NAME = '';
export const ERR_TOKEN_IMAGE = '/asset/_no_asset.svg';
export const ERR_TOKEN_DESCRIPTION = 'Server Busy';

/**
 * 保持トークン画面表示件数
 */
export const DEFAULT_DRAW_MY_TOKEN_CNT = 8;

/**
 * EthScanのURL
 */
export const ETH_SCAN_URL = 'https://etherscan.io/tx/';

/**
 * トークンURI取得用
 */
export const C_FUNCTION_GET_TOKEN_INFO = 'getInfo';

/**
 * エラー取得用
 */
export const C_FUNCTION_ERR_DATA_STOCK = 'errDataStock';

/**
 * 占い初期値
 */
export const FORTUNE_PARAM_YEAR = '1990';
export const FORTUNE_PARAM_MONTH = '1';
export const FORTUNE_PARAM_DAY = '1';
export const FORTUNE_PARAM_GENDER = 'other';
/**
 * 占いAPI URL
 */
export const FORTUNE_API_URL =
  'https://www.uranai-api.jp/srv/clapi/dapps/daily.php';

/**
 * アセット詳細 moreボタン表示個数
 */
export const ASSET_DETAIL_MORE_VIEW_CNT = 3;

/**
 * 言語
 */
export const DEFAULT_LANG = 'en';
export const LANG_JAPANESE = 'ja';
export const LANG_ENGLISH = 'en';
