import * as web3_util from '../utils/web3';
import Transaction from '../utils/classes/Transaction';

/**
 * チケット情報更新
 */
export function updateTicketInfoTrnCompTiming(ticketInfo) {
  return {
    type: 'UPDATE_TICKET_INFO_TRN_COMP_TIMING',
    ticketInfo
  };
}

/**
 * トランザクション情報設定
 */
export function setTransaction(_hash, _status, _from) {
  const transaction = new Transaction(_hash, _status, _from);

  return {
    type: 'SET_TRANSACTION',
    transaction: transaction
  };
}

/**
 * 占い
 */
export function fortune(_promise, _props) {
  var isComplete = false;
  let hash = '';
  var from = 'fortune';

  return dispatch => {
    _promise
      .on('transactionHash', _hash => {
        hash = _hash;
        dispatch(setTransaction(hash, 'start', from));
        dispatch(setTransaction(hash, 'waiting', from));
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        if (!isComplete) {
          isComplete = true;
          dispatch(setTransaction(hash, 'complete', from));

          // チケット情報更新
          web3_util
            .tryConnectDAppsJinjaContract(_props.wallet.web3)
            .then(async contract => {
              return await web3_util.getTicketInfo(
                contract,
                _props.wallet.account
              );
            })
            .then(ticket => {
              dispatch(updateTicketInfoTrnCompTiming(ticket));
            });
        }
      })
      .on('error', error => {
        dispatch(setTransaction(hash, 'alertError', from));
        dispatch(setTransaction(hash, 'error', from));
      });
  };
}

/**
 * Eth購入
 * @param {*} _promise
 */
export function buyEth(_promise) {
  var isComplete = false;
  let hash = '';
  const from = 'asset_sale';
  return dispatch => {
    _promise
      .on('transactionHash', _hash => {
        hash = _hash;
        dispatch(setTransaction(hash, 'start', from));
        dispatch(setTransaction(hash, 'waiting', from));
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        if (!isComplete) {
          isComplete = true;
          dispatch(setTransaction(hash, 'complete', from));
        }
      })
      .on('error', error => {
        dispatch(setTransaction(hash, 'alertError', from));
        dispatch(setTransaction(hash, 'error', from));
      });
  };
}

/**
 * 引換券購入
 * @param {*} _promise
 */
export function getFree(_promise, _props) {
  var isComplete = false;
  let hash = '';
  const from = 'asset_sale';
  return dispatch => {
    _promise
      .on('transactionHash', _hash => {
        hash = _hash;
        dispatch(setTransaction(hash, 'start', from));
        dispatch(setTransaction(hash, 'waiting', from));
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        if (!isComplete) {
          isComplete = true;
          dispatch(setTransaction(hash, 'complete', from));

          // チケット情報更新
          web3_util
            .tryConnectDAppsJinjaContract(_props.wallet.web3)
            .then(async contract => {
              return await web3_util.getTicketInfo(
                contract,
                _props.wallet.account
              );
            })
            .then(ticket => {
              dispatch(updateTicketInfoTrnCompTiming(ticket));
            });
        }
      })
      .on('error', error => {
        dispatch(setTransaction(hash, 'alertError', from));
        dispatch(setTransaction(hash, 'error', from));
      });
  };
}
