/**
 * トークンインデックス情報
 */
export default class TokenIndexInfo {
  /** トークンを所有しているアカウントのアドレス */
  accountAddress;
  /** トークン発行元のERC721コントラクトアドレス */
  contractAddress;
  /** トークンのインデックス(トークンIDとは異なる) */
  index;

  constructor(_accountAddress, _contractAddress, _index) {
    this.accountAddress = _accountAddress;
    this.contractAddress = _contractAddress;
    this.index = _index;
  }
}
