import React, { Component } from 'react';
import AssetDetail from '../containers/AssetDetail';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dialogPaper: {
    // eslint-disable-next-line
    ['@media screen and (min-width: 1000px)']: {
      // デザインより参照
      width: '60%',
      maxWidth: '640px',
      height: '80%',
      maxHeight: '960px',
      overflowY: 'auto',
      boxSizing: 'border-box',
      background: '#fff',
      transition: '0.5s'
    },

    // eslint-disable-next-line
    ['@media screen and (max-width: 999px)']: {
      // デザインより参照
      width: '90%',
      height: '90%',
      overflowY: 'auto',
      boxSizing: 'border-box',
      background: '#fff',
      transition: '0.5s',
      maxHeight: 'initial',
      margin: 0
    },

    // デザインに合わせて制御
    borderRadius: 'initial'
  },
  // デザインに合わせて制御
  dialogRoot: {
    zIndex: '9999 !important',
    minWidth: '0 !important'
  },
  // デザインに合わせて制御
  dialogContent: {
    // eslint-disable-next-line
    ['@media screen and (min-width: 1000px)']: {
      padding: '2em',
      overflowY: 'initial'
    },
    // eslint-disable-next-line
    ['@media screen and (max-width: 999px)']: {
      padding: '1em',
      overflowY: 'initial'
    }
  },
  paperWidthSm: {
    // eslint-disable-next-line
    ['@media screen and (min-width: 1000px)']: {},
    // eslint-disable-next-line
    ['@media screen and (max-width: 999px)']: {
      maxWidth: 'initial'
    }
  }
});

class DetailDialog extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        scroll="paper"
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogPaper,
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogContent
          classes={{
            root: classes.dialogContent
          }}
        >
          <AssetDetail
            drawType={this.props.drawType}
            onClose={this.props.onClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}
export default withStyles(styles)(DetailDialog);
