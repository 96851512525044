import React, { Component } from 'react';
import Layout from '../containers/Layout';
import FooterAD from '../components/FooterAD';
import * as web3_util from '../utils/web3';
import * as common_util from '../utils/common';
import * as constant from '../config/constant';
import * as config from '../config/config';
import { FormattedHTMLMessage } from 'react-intl';

import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import WaitDialog from '../components/WaitDialog';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#333333',
    color: '#ffffff',
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'sans-serif'
      // -apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const styles = theme => ({
  icon: {
    color: '#ffffff'
  }
});

/**
 * 占い画面
 * スマートコントラクトより、公開鍵に紐づく占い無料期間情報を取得。また、占い価格を取得し占いボタンを作成する。
 * 占いボタン押し時、スマートコントラクトに対し占い課金を行う。
 */
class Fortune extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: '',
      month: '',
      day: '',

      gender: '',
      open: false,
      isFree: false,
      leftFreeTime: '',
      price: null,

      canFortune: false,
      isPriceChecking: true,

      yearHeight: 0,
      monthHeight: 0,
      dayHeight: 0,
      genderHeight: 0,
      birthdayLoad: false,

      fortuneAlert: false
    };

    this.setBirthDayGender = this.setBirthDayGender.bind(this);
    this.fortune = this.fortune.bind(this);
    this.fortuneClick = this.fortuneClick.bind(this);
    this.fortunePopperClose = this.fortunePopperClose.bind(this);
  }

  componentDidMount = async () => {
    this._isMounted = true;

    try {
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet(false);
      }
      // web3接続できない場合はtopへ
      if (!this.props.wallet.isLinked) {
        this.props.history.push('/top');
        return;
      }

      // ストレージに生年月日等の情報があれば初期設定しておく
      let fortune_param = common_util.getStrage('fortune_param');

      if (this._isMounted) {
        let year, month, day, gender;
        if (fortune_param === null) {
          year = config.FORTUNE_PARAM_YEAR;
          month = config.FORTUNE_PARAM_MONTH;
          day = config.FORTUNE_PARAM_DAY;
          gender = config.FORTUNE_PARAM_GENDER;
        } else {
          year = fortune_param.birthday.year;
          month = fortune_param.birthday.month;
          day = fortune_param.birthday.day;
          gender = fortune_param.gender;
        }

        this.setState({
          year: year,
          month: month,
          day: day,
          gender: gender,
          canFortune: true,
          birthdayLoad: true
        });
      }

      await this.props.initBaseLogicOfDidMount(
        this.props,
        this.props.wallet.account
      );

      // トランザクションが稼働していない場合は価格判定を行う
      if (!this.isRunFortuneTransaction()) {
        this.setFortuneFreeInfo();
      }
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this.ticketTimer !== 'undefined') {
      clearInterval(this.ticketTimer);
    }
  }

  async setFortuneFreeInfo() {
    try {
      // dApps神社コントラクト取得
      const contract = await web3_util.tryConnectDAppsJinjaContract(
        this.props.wallet.web3
      );

      // 無料判定
      let isFree = false;
      isFree = await contract.methods.judgeFreeFortune.call({
        from: this.props.wallet.account
      });

      // 無料の場合は期間を取得
      if (isFree) {
        const period = await contract.methods
          .freeFortunePeriodMap(this.props.wallet.account)
          .call({
            from: this.props.wallet.account
          });

        // タイマーが稼働している場合は停止し再作成
        if (this.ticketTimer !== 'undefined') {
          clearInterval(this.ticketTimer);
        }
        this.ticketTimer = setInterval(() => {
          const calcTime = this.calcFreeTime(period);
          const isMultiple = calcTime.time > 1 ? true : false;

          let leftFreeTime = '';
          let isFree = true;
          if (calcTime !== 0) {
            leftFreeTime =
              calcTime.time +
              common_util.getTimeTypeText(
                this.props.common.lang,
                calcTime.type,
                isMultiple
              );
          } else {
            isFree = false;
          }

          if (this._isMounted) {
            this.setState({
              isFree: isFree,
              leftFreeTime: leftFreeTime
            });
          }
        }, 1000);
      }

      const price = await contract.methods.fortunePrice
        .call()
        .then(hexPrice => {
          return hexPrice.toString();
        });

      if (this._isMounted) {
        this.setState({
          isFree: isFree,
          price: price,
          isPriceChecking: false
        });
      }
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  }

  /**
   * 占う
   */
  async fortune() {
    try {
      let birthday = {
        year: this.state.year,
        month: this.state.month,
        day: this.state.day
      };

      // 占い情報保存
      common_util.setStrage('fortune_param', {
        birthday: birthday,
        gender: this.state.gender
      });

      // ボタン押し時に無料か再チェック
      const res = await web3_util
        .tryConnectDAppsJinjaContract(this.props.wallet.web3)
        .then(contract => {
          return contract.methods.judgeFreeFortune.call({
            from: this.props.wallet.account
          });
        });

      this.setState({
        isFree: res
      });

      // 占い無料
      if (this.state.isFree) {
        this.props.setRefFortuneResult(true);
        this.props.history.push('/fortune-result');
      } else {
        // 占い有料
        web3_util
          .tryConnectDAppsJinjaContract(this.props.wallet.web3)
          .then(contract => {
            var promise = contract.methods.doFortune().send({
              from: this.props.wallet.account,
              value: this.props.wallet.web3.utils.toHex(this.state.price)
            });
            this.props.fortune(promise, this.props);
          });
        this.setState({ open: true });
      }
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  }

  // 残り有効日付表示
  calcFreeTime(_availablePeriod) {
    // 現在日時と比較
    const diff = new Date(_availablePeriod.toString() * 1000) - new Date();

    // 日
    if (diff >= constant.UNIX_TIME_ONE_DAY) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_DAY).toString(),
        type: 'd'
      };
      // 時
    } else if (
      constant.UNIX_TIME_ONE_DAY > diff &&
      diff >= constant.UNIX_TIME_ONE_HOUR
    ) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_HOUR).toString(),
        type: 'h'
      };
      // 分
    } else if (
      constant.UNIX_TIME_ONE_HOUR > diff &&
      diff >= constant.UNIX_TIME_ONE_MINUIT
    ) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_MINUIT).toString(),
        type: 'm'
      };
      // 秒
    } else if (constant.UNIX_TIME_ONE_MINUIT > diff && diff >= 0) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_SEC).toString(),
        type: 's'
      };
      // 期限切れ
    } else if (diff <= 0) {
      return 0;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // 占いトランザクションが発行された場合
    if (nextProps.transaction.nowTransaction.status === 'start') {
      nextProps.history.push('/transaction');

      // 占いトランザクション発行をやめた場合
    } else if (nextProps.transaction.nowTransaction.status === 'alertError') {
      return { open: false };
    }

    return null;
  }

  transactionUpdate = false;
  componentDidUpdate() {
    // 占いトランザクションが完了した場合、占い価格情報を再描写
    if (
      this.props.transaction.nowTransaction.from === 'fortune' &&
      (this.props.transaction.nowTransaction.status === 'complete' ||
        this.props.transaction.nowTransaction.status === 'error') &&
      !this.transactionUpdate
    ) {
      this.transactionUpdate = true;
      this.setFortuneFreeInfo();
    }
  }

  renderYear() {
    var items = [];

    for (var i = 1920; i < 2031; i++) {
      items.push(
        <option
          style={{ backgroundColor: '#333333' }}
          value={i}
          key={'year_' + i}
        >
          {i}
        </option>
      );
    }

    return items;
  }

  renderMonth() {
    var items = [];

    for (var i = 1; i <= 12; i++) {
      items.push(
        <option
          style={{ backgroundColor: '#333333' }}
          value={i}
          key={'month_' + i}
        >
          {i}
        </option>
      );
    }

    return items;
  }

  renderDay() {
    var day = moment(
      this.state.year + '-' + this.state.month + '-1',
      'YYYY-MM-DD'
    ).daysInMonth();
    var items = [];

    for (var i = 1; i <= day; i++) {
      items.push(
        <option
          style={{ backgroundColor: '#333333' }}
          value={i}
          key={'day_' + i}
        >
          {i}
        </option>
      );
    }

    return items;
  }

  /**
   * 生年月日、性別設定の設定 & パラメータとして適切か判定
   * @param {*} type
   * @param {*} value
   */
  setBirthDayGender(type, value) {
    let year = this.state.year;
    let month = this.state.month;
    let day = this.state.day;
    let gender = this.state.gender;

    switch (type) {
      case 'y':
        year = value;
        break;
      case 'm':
        month = value;
        break;
      case 'd':
        day = value;
        break;

      case 'g':
        gender = value;
        break;

      default:
        return;
    }

    let canFortune = false;

    // 誕生日&性別が全て設定されていたら占い可能
    if (
      (this.state.year !== '' || year !== '') &&
      (this.state.month !== '' || month !== '') &&
      (this.state.day !== '' || day !== '') &&
      (this.state.gender !== '' || gender !== '') &&
      this.checkExistDate(year, month, day)
    ) {
      canFortune = true;
    }

    this.setState({
      year: year,
      month: month,
      day: day,
      gender: gender,
      canFortune: canFortune
    });
  }

  /**
   * 占いトランザクションが発行中か判定
   */
  isRunFortuneTransaction() {
    var list = [];
    this.props.transaction.transactionMap.forEach(transaction => {
      if (transaction.from === 'fortune' && transaction.status === 'waiting')
        list.push(transaction);
    });

    if (list.length > 0) return true;

    return false;
  }

  checkExistDate(year, month, day) {
    var date = new Date(year, month - 1, day);

    if (
      date.getFullYear() === parseInt(year) &&
      date.getMonth() === parseInt(month - 1) &&
      date.getDate() === parseInt(day)
    ) {
      return true;
    }

    return false;
  }

  renderButton() {
    var price = 0;

    // 価格取得
    if (this.state.price !== null) {
      price = this.props.wallet.web3.utils.fromWei(
        this.state.price.toString(),
        'ether'
      );
    }

    let isDisable = true;
    let text = 'checking';

    // トランザクション状況、有効期限を参照し画面表示内容を生成
    if (this.isRunFortuneTransaction()) {
      text = 'waiting for transaction';
    } else if (this.state.isPriceChecking) {
      // text = 'checking';
    } else if (this.state.isFree && this.state.leftFreeTime) {
      text = 'Free ' + this.state.leftFreeTime;
      isDisable = false;
    } else if (!this.state.isFree && this.state.price) {
      text = price + 'ETH';
      isDisable = false;
    }

    return (
      <button
        className="btnM"
        disabled={isDisable}
        // パラメータ設定済の場合は占い実施、未設定の場合は警告表示
        onClick={
          this.state.canFortune
            ? this.fortune
            : e => {
                this.fortuneClick(e);
              }
        }
      >
        <i className="fas fa-yin-yang" />{' '}
        <FormattedHTMLMessage id="fortune.fortune.button" />{' '}
        <br className="sp" />( {text} )
      </button>
    );
  }

  // renderYearPullDown() {
  //   // 設定する値一覧
  //   var valueList = [];
  //   for (let index = 1920; index <= 2050; index++) {
  //     valueList.push(index.toString());
  //   }

  //   // inputのオプション名
  //   const inputName = 'option_year';
  //   // タイプ
  //   const type = 'y';
  //   // タイプのstate
  //   const typeState = this.state.year;
  //   // 高さ判定用のstate
  //   const heightState = this.state.yearHeight;
  //   // 高さ判定用のstate名
  //   const heightStateName = 'yearHeight';

  //   var renderElement = [];
  //   for (let index = 0; index < valueList.length; index++) {
  //     const value = valueList[index];

  //     renderElement.push(
  //       this.renderInputLabelList(
  //         inputName,
  //         value,
  //         type,
  //         typeState,
  //         index,
  //         heightStateName
  //       )
  //     );
  //   }

  //   return (
  //     <div id="year" className="cp_ipselect07">
  //       <input
  //         type="radio"
  //         name={inputName}
  //         onClick={() => {
  //           this.state.birthdayLoad &&
  //             this.setState({
  //               [heightStateName]: '400px',
  //               year: ''
  //             });
  //         }}
  //       />
  //       <i className="toggle cp_sl07_arrowdown" />
  //       <i className="toggle cp_sl07_arrowup" />
  //       <span className="cp_sl07_selectlabel">Year</span>
  //       <div
  //         style={{
  //           overflow: 'scroll',
  //           overflowX: 'hidden',
  //           maxHeight: heightState
  //         }}
  //       >
  //         {renderElement}
  //       </div>
  //     </div>
  //   );
  // }

  // renderMonthPullDown() {
  //   // 設定する値一覧
  //   var valueList = [];
  //   for (let index = 1; index <= 12; index++) {
  //     valueList.push(index.toString());
  //   }
  //   // inputのオプション名
  //   const inputName = 'option_month';
  //   // タイプ
  //   const type = 'm';
  //   // タイプのstate
  //   const typeState = this.state.month;
  //   // 高さ判定用のstate
  //   const heightState = this.state.monthHeight;
  //   // 高さ判定用のstate名
  //   const heightStateName = 'monthHeight';

  //   var renderElement = [];
  //   for (let index = 0; index < valueList.length; index++) {
  //     const value = valueList[index];

  //     renderElement.push(
  //       this.renderInputLabelList(
  //         inputName,
  //         value,
  //         type,
  //         typeState,
  //         index,
  //         heightStateName
  //       )
  //     );
  //   }

  //   return (
  //     <div id="month" className="cp_ipselect07">
  //       <input
  //         type="radio"
  //         name={inputName}
  //         onClick={() => {
  //           this.state.birthdayLoad &&
  //             this.setState({
  //               [heightStateName]: '400px',
  //               month: ''
  //             });
  //         }}
  //       />
  //       <i className="toggle cp_sl07_arrowdown" />
  //       <i className="toggle cp_sl07_arrowup" />
  //       <span className="cp_sl07_selectlabel">
  //         Mo<span className="sp">.</span>
  //         <span className="pc">nth</span>
  //       </span>
  //       <div
  //         style={{
  //           overflow: 'scroll',
  //           overflowX: 'hidden',
  //           maxHeight: heightState
  //         }}
  //       >
  //         {renderElement}
  //       </div>
  //     </div>
  //   );
  // }

  // renderDayPullDown() {
  //   // 設定する値一覧
  //   var valueList = [];
  //   for (let index = 1; index <= 31; index++) {
  //     valueList.push(index.toString());
  //   }
  //   // inputのオプション名
  //   const inputName = 'option_day';
  //   // タイプ
  //   const type = 'd';
  //   // タイプのstate
  //   const typeState = this.state.day;
  //   // 高さ判定用のstate
  //   const heightState = this.state.dayHeight;
  //   // 高さ判定用のstate名
  //   const heightStateName = 'dayHeight';

  //   var renderElement = [];
  //   for (let index = 0; index < valueList.length; index++) {
  //     const value = valueList[index];

  //     renderElement.push(
  //       this.renderInputLabelList(
  //         inputName,
  //         value,
  //         type,
  //         typeState,
  //         index,
  //         heightStateName
  //       )
  //     );
  //   }

  //   return (
  //     <div id="day" className="cp_ipselect07">
  //       <input
  //         type="radio"
  //         name={inputName}
  //         onClick={() => {
  //           this.state.birthdayLoad &&
  //             this.setState({
  //               [heightStateName]: '400px',
  //               day: ''
  //             });
  //         }}
  //       />
  //       <i className="toggle cp_sl07_arrowdown" />
  //       <i className="toggle cp_sl07_arrowup" />
  //       <span className="cp_sl07_selectlabel">Day</span>
  //       <div
  //         style={{
  //           overflow: 'scroll',
  //           overflowX: 'hidden',
  //           maxHeight: heightState
  //         }}
  //       >
  //         {renderElement}
  //       </div>
  //     </div>
  //   );
  // }

  // renderGenderPullDown() {
  //   // 設定する値一覧
  //   var valueList = ['male', 'female', 'other'];
  //   // inputのオプション名
  //   const inputName = 'option_gender';
  //   // タイプ
  //   const type = 'g';
  //   // タイプのstate
  //   const typeState = this.state.gender;
  //   // 高さ判定用のstate
  //   const heightState = this.state.genderHeight;
  //   // 高さ判定用のstate名
  //   const heightStateName = 'genderHeight';

  //   var renderElement = [];
  //   for (let index = 0; index < valueList.length; index++) {
  //     const value = valueList[index];

  //     renderElement.push(
  //       this.renderInputLabelList(
  //         inputName,
  //         value,
  //         type,
  //         typeState,
  //         index,
  //         heightStateName
  //       )
  //     );
  //   }

  //   return (
  //     <div id="gender" className="cp_ipselect07">
  //       <input
  //         type="radio"
  //         name={inputName}
  //         onClick={() => {
  //           this.state.birthdayLoad &&
  //             this.setState({
  //               [heightStateName]: '400px',
  //               gender: ''
  //             });
  //         }}
  //       />
  //       <i className="toggle cp_sl07_arrowdown" />
  //       <i className="toggle cp_sl07_arrowup" />
  //       <span className="cp_sl07_selectlabel">
  //         Gen<span className="sp">.</span>
  //         <span className="pc">der</span>
  //       </span>
  //       <div
  //         style={{
  //           // overflow: 'scroll',
  //           // overflowX: 'hidden',
  //           maxHeight: heightState
  //         }}
  //       >
  //         {renderElement}
  //       </div>
  //     </div>
  //   );
  // }

  // renderInputLabelList(
  //   _inputName,
  //   _value,
  //   _type,
  //   _state,
  //   _index,
  //   _heightStateName
  // ) {
  //   return (
  //     <label className="option" key={_index}>
  //       <input
  //         type="radio"
  //         name={_inputName}
  //         value={_value}
  //         onChange={e => {
  //           this.setBirthDayGender(_type, e.target.value);
  //           this.setState({ [_heightStateName]: '0' });
  //         }}
  //         checked={_state === _value}
  //       />
  //       <span className="cp_sl07_title">{_value}</span>
  //     </label>
  //   );
  // }

  fortuneClick(event) {
    this.setState({ fortuneAlert: true, anchorEl: event.currentTarget });
  }

  fortunePopperClose() {
    this.setState({ fortuneAlert: false, anchorEl: null });
  }

  renderParam() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <FormControl className="cp_ipselect07" id="year">
          <InputLabel htmlFor="year" style={{ color: '#333333' }}>
            Year
          </InputLabel>
          <NativeSelect
            value={this.state.year}
            onChange={e => this.setBirthDayGender('y', e.target.value)}
            style={{ borderRadius: 4, background: '#333333' }}
            input={<BootstrapInput />}
            classes={{
              icon: classes.icon
            }}
          >
            {this.renderYear()}
          </NativeSelect>
        </FormControl>
        <FormControl className="cp_ipselect07" id="month">
          <InputLabel htmlFor="month" style={{ color: '#333333' }}>
            Mo.
          </InputLabel>
          <NativeSelect
            value={this.state.month}
            onChange={e => this.setBirthDayGender('m', e.target.value)}
            style={{ borderRadius: 4, background: '#333333' }}
            input={<BootstrapInput />}
            classes={{
              icon: classes.icon
            }}
          >
            {this.renderMonth()}
          </NativeSelect>
        </FormControl>
        <FormControl className="cp_ipselect07" id="day">
          <InputLabel htmlFor="day" style={{ color: '#333333' }}>
            Day
          </InputLabel>
          <NativeSelect
            value={this.state.day}
            onChange={e => this.setBirthDayGender('d', e.target.value)}
            style={{ borderRadius: 4, background: '#333333' }}
            input={<BootstrapInput />}
            classes={{
              icon: classes.icon
            }}
          >
            {this.renderDay()}
          </NativeSelect>
        </FormControl>
        {/* <FormControl className="cp_ipselect07" id="gender">
          <InputLabel htmlFor="gender" style={{ color: '#333333' }}>
            Gen.
          </InputLabel>
          <NativeSelect
            value={this.state.gender}
            onChange={e => this.setBirthDayGender('g', e.target.value)}
            style={{ borderRadius: 4, background: '#333333' }}
            input={<BootstrapInput />}
            classes={{
              icon: classes.icon
            }}
          >
            <option style={{ backgroundColor: '#333333' }} value={'male'}>
              male
            </option>
            <option style={{ backgroundColor: '#333333' }} value={'female'}>
              female
            </option>
            <option style={{ backgroundColor: '#333333' }} value={'other'}>
              other
            </option>
          </NativeSelect>
        </FormControl> */}
      </React.Fragment>
    );
  }
  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div className="fortuneWrap">
            <section className="fortuneSection">
              <div className="fortuneCatch">
                <img src="/img/fortune-catch.svg" alt="fortune" />
              </div>
              <div id="birthday">
                {/* {this.renderYearPullDown()}
                {this.renderMonthPullDown()}
                {this.renderDayPullDown()}
                {this.renderGenderPullDown()} */}
                {this.renderParam()}
              </div>
              <br />
              <br />
              <br />

              {this.renderButton()}
            </section>
          </div>
          <div id="contentsWrap">
            <section>
              <h1>
                <FormattedHTMLMessage id="fortune.fortune.title" />
              </h1>
              <ul className="topCol12">
                <li>
                  <FormattedHTMLMessage id="fortune.fortune.text.1" />
                </li>
                <li>
                  <img
                    src="./img/top/fortune.jpg"
                    className="imgDropshadow"
                    alt=""
                  />
                </li>
              </ul>
              <ul className="topCol13">
                <li>
                  <img src="./img/top/SixStarFortuneTheory.png" alt="" />
                </li>
                <li>
                  <FormattedHTMLMessage id="fortune.fortune.text.2" />
                </li>
              </ul>
            </section>

            <FooterAD />
          </div>
        </div>
        <Popover
          // id={id}
          open={this.state.fortuneAlert}
          anchorEl={this.state.anchorEl}
          onClose={this.fortunePopperClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
        >
          <div>
            <p
              style={{ padding: '18px', fontSize: '20px', textAlign: 'center' }}
            >
              <FormattedHTMLMessage id="fortune.param.error" />
            </p>
          </div>
        </Popover>
        <WaitDialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        />
      </Layout>
    );
  }
}

// export default Fortune;
export default withStyles(styles)(Fortune);
