import React, { Component } from 'react';
import * as token_util from '../utils/token';
import * as web3_util from '../utils/web3';
import * as common_util from '../utils/common';
import * as config from '../config/config';
import * as constant from '../config/constant';
import axios from 'axios';

class AssetCard extends Component {
  state = {
    tokenDetailInfo: void 0,
    contractInfo: '',
    isDataAcquired: false,
    isSale: true,
    saleStatus: 0
  };

  erc721Contract;
  checkSaleTimer;
  // 描写タイプ 販売、保有、バナー
  drawType = constant.ASSET_CARD_TYPE_SALE_CARD;

  constructor(props) {
    super(props);

    if (this.props.drawType !== void 0) this.drawType = this.props.drawType;
  }

  componentDidMount = async () => {
    this._isMounted = true;

    try {
      // トークン保持アカウントアドレス
      const accountAddress = this.props.tokenIndexInfo.accountAddress;

      // トークン保持コントラクトアドレス
      const contractAddress = this.props.tokenIndexInfo.contractAddress;

      // コントラクトインスタンス作成
      this.erc721Contract = await web3_util.tryConnectERC721Contract(
        this.props.wallet.web3,
        contractAddress
      );

      // アセット販売しているアカウント情報より、トークンのステータスを取得
      const accountInfo = this.props.common.poolAccountInfoList.filter(
        account => account.Address === accountAddress
      );

      // 表示するトークンのステータスを設定(保有アカウント依存)
      if (accountInfo[0] !== void 0 && this._isMounted)
        this.setState({ saleStatus: accountInfo[0].Status });

      // コントラクト(dApps)の基礎情報設定
      const contractInfo = this.props.common.erc721ContractInfoList.filter(
        checkContractInfo =>
          checkContractInfo.Address.toString() === contractAddress
      );

      // 取得できた場合のみ設定
      if (contractInfo.length > 0) {
        if (this._isMounted) {
          this.setState({ contractInfo: contractInfo[0] });
        }
      }

      // トークンID取得
      let tokenId;
      if (this.props.tokenIndexLatestBlock) {
        tokenId = await this.erc721Contract.methods
          .tokenOfOwnerByIndex(
            this.props.tokenIndexInfo.accountAddress,
            this.props.tokenIndexInfo.index
          )
          .call();
      } else {
        tokenId = await this.erc721Contract.methods
          .tokenOfOwnerByIndex(
            this.props.tokenIndexInfo.accountAddress,
            this.props.tokenIndexInfo.index
          )
          .call({}, this.props.common.baseBlockNumber);
      }

      if (tokenId === void 0) throw new Error('can not get tokenId');

      const tokenInfo = await this.getTokenDetailInfo(
        accountAddress,
        tokenId.toString(),
        contractAddress
      );

      if (tokenInfo === void 0) throw new Error('can not get tokenId');

      if (this._isMounted) {
        this.setState({ tokenDetailInfo: tokenInfo, isDataAcquired: true });
        // 全て正常に完了できた場合は販売状況を監視
        this.monitorSellPossible();
      }
    } catch (error) {
      console.log(error);
      // common_util.sendError(error);
      if (this._isMounted) {
        // エラーの場合は取得失敗であることを表示する
        this.setState({ tokenDetailInfo: token_util.createErrTokenInfo() });
      }
    }
  };

  async getTokenDetailInfo(accountAddress, tokenId, contractAddress) {
    // トークン詳細情報保持しているか確認
    const tokenDetailInfo = await token_util.searchTokenDetailInfo(
      this.props.token.tokenDetailInfoList,
      tokenId,
      contractAddress
    );

    // 取得済の場合はStateへ設定
    if (tokenDetailInfo.length > 0) {
      return tokenDetailInfo[0];
    }

    // 参照先取得
    const tokenURI = await this.erc721Contract.methods.tokenURI(tokenId).call();

    // 参照先よりプロパティ情報取得
    return await axios
      .post(
        process.env.REACT_APP_C_FUNCTION_URL + config.C_FUNCTION_GET_TOKEN_INFO,
        {
          // eslint-disable-next-line
          url: tokenURI.replace(/[\x00-\x09\x0b-\x1f\x7f-\x9f]/g, '')
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        return res.data;
      })
      .then(
        json => {
          const tokenDetailInfo = token_util.createTokenInfo(
            accountAddress,
            contractAddress,
            tokenId,
            json
          );

          this.props.setTokenDetailInfo(tokenDetailInfo);

          return tokenDetailInfo;
        },
        error => {
          console.log(error);
          return void 0;
        }
      );
  }

  componentWillUnmount() {
    this._isMounted = false;
    // タイマーが稼働している場合は停止
    if (this.checkSaleTimer !== 'undefined') {
      clearInterval(this.checkSaleTimer);
    }
  }

  monitorSellPossible() {
    // 販売要否監視
    this.checkSaleTimer = setInterval(async () => {
      await web3_util
        .checkApprove(
          this.props.wallet.web3,
          this.erc721Contract,
          this.state.tokenDetailInfo.accountAddress,
          this.state.tokenDetailInfo.tokenId
        )
        .then(res => {
          // 購入できない
          if (!res) {
            this.setState({ isSale: res });
            clearInterval(this.checkSaleTimer);
          }
        });
    }, 30000);
  }

  async clickCard() {
    try {
      // tokenURIデータ取得完了済みか判定
      if (!this.state.isDataAcquired) {
        return;
      }
      // 表示するトークン情報を設定(親コンポーネント、モーダル表示用)
      await this.props.setWatchTokenDetail(this.state.tokenDetailInfo);

      // 取得できた場合のみ設定
      if (this.state.contractInfo !== void 0) {
        this.props.setWatchContractInfo(this.state.contractInfo);
      }

      // 販売可能か判定
      const isSale = await web3_util.checkApprove(
        this.props.wallet.web3,
        this.erc721Contract,
        this.state.tokenDetailInfo.accountAddress,
        this.state.tokenDetailInfo.tokenId.toString()
      );

      await this.props.setWatchTokenSaleStatus(isSale);

      return this.props.openDetailFunc();
    } catch (error) {
      common_util.sendError(error);
    }
  }

  renderStatus() {
    if (this.drawType !== constant.ASSET_CARD_TYPE_SALE_CARD) {
      return 'assetImage';
    }

    if (!this.state.isSale) {
      return 'assetImage soldout';
    }

    switch (this.state.saleStatus) {
      case 10:
        return 'assetImage hotitem';

      case 20:
        return 'assetImage newitem';

      default:
        return 'assetImage ';
    }
  }

  render() {
    if (this.drawType === constant.ASSET_CARD_TYPE_BANNER) {
      const img =
        this.state.tokenDetailInfo !== void 0
          ? this.state.tokenDetailInfo.image
          : config.LOADING_TOKEN_IMAGE;
      return (
        <div
          style={{
            backgroundImage: 'url(' + img + ')'
          }}
        ></div>
      );
    } else {
      return (
        <li
          onClick={() => {
            this.clickCard();
          }}
        >
          <label>
            <div className="assetCategory">
              <img
                src={this.state.contractInfo.Img}
                alt={this.state.contractInfo.Name}
              />
              {this.state.contractInfo
                ? this.state.contractInfo.Name
                : 'UnKnown'}
            </div>
            <div className={this.renderStatus()}>
              <img
                src={
                  this.state.tokenDetailInfo
                    ? this.state.tokenDetailInfo.image
                    : config.LOADING_TOKEN_IMAGE
                }
                alt={
                  this.state.tokenDetailInfo && this.state.tokenDetailInfo.name
                }
              />
            </div>
            <div className="assetName">
              {this.state.tokenDetailInfo && this.state.tokenDetailInfo.name}
            </div>
            <div className="assetData">
              <p style={{ wordBreak: 'break-all' }}>
                {this.state.tokenDetailInfo
                  ? this.state.tokenDetailInfo.description
                  : 'Loading...'}
              </p>
            </div>
          </label>
        </li>
      );
    }
  }
}

export default AssetCard;
