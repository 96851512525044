import { connect } from 'react-redux';
import Auth from '../components/Auth';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

export default connect(mapStateToProps)(Auth);
