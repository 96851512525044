import { connect } from 'react-redux';
import * as common from '../actions/common';
import * as wallet from '../actions/wallet';
import * as fortune from '../actions/fortune';
import * as transaction from '../actions/transaction';
import Fortune from '../views/Fortune';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // common
    initBaseLogicOfDidMount: (_props, _account) =>
      dispatch(common.initBaseLogicOfDidMount(_props, _account)),
    // wallet
    connectWallet: _tryActivate => dispatch(wallet.connectWallet(_tryActivate)),
    // fortune
    setRefFortuneResult: _bool => dispatch(fortune.setRefFortuneResult(_bool)),
    // transaction
    fortune: (_promise, _props) =>
      dispatch(transaction.fortune(_promise, _props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fortune);
