import { connect } from 'react-redux';
import * as common from '../actions/common';
import * as wallet from '../actions/wallet';
import Top from '../views/Top';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // common
    initBaseLogicOfDidMount: (_props, _account) =>
      dispatch(common.initBaseLogicOfDidMount(_props, _account)),
    // wallet
    connectWallet: _tryActivate =>
      dispatch(wallet.connectWallet((_tryActivate = false)))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Top);
