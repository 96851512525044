import * as web3_util from './web3.js';
import * as common_util from './common.js';
import * as config from '../config/config';
import TokenIndexInfo from './classes/TokenIndexInfo';
import TokenDetailInfo from './classes/TokenDetailInfo';
// import MableCardsFormat from './classes/dAppsFormat/MableCardsFormat';

// const specialFormatList = [new MableCardsFormat()];
const specialFormatList = [];

/**
 * アカウントの保持しているトークンのインデックスリストをランダムに作成
 * アカウント情報に保持しているコントラクトアドレス毎にトークンイデックス情報を取得し、
 * トークンインデックス番号がランダムな配列をアカウント毎に作成する
 * @param {*} _accountList
 */
export async function createTokenRandomListMapByAccount(
  _web3,
  _accountInfoList,
  _blockNum
) {
  var tokenIndexRandomListMapByAccount = new Map();
  await Promise.all(
    // アカウント情報毎に稼働
    _accountInfoList.map(async account => {
      let allTokenIndexList = [];

      await Promise.all(
        // アカウントの保有しているERC721コントラクト毎に稼働
        account.ContractAddressList.map(async contractAddress => {
          try {
            const contract = web3_util.tryConnectERC721Contract(
              _web3,
              contractAddress
            );

            // Ethreumより、アカウントが所有しているトークン数を取得
            const tokenCnt = await contract.methods
              .balanceOf(account.Address)
              .call({}, _blockNum);

            // コントラクトアドレスとインデックス番号を保持した一覧を作成
            // (以降の処理でランダムにするための前準備)
            const tokenIndexList = createTokenIndexList(
              account.Address,
              contractAddress,
              tokenCnt
            );

            // 保持リストへ格納(全てのERC721コントラクトのトークン情報を一つの配列にまとめる)
            allTokenIndexList = allTokenIndexList.concat(tokenIndexList);
          } catch (error) {
            // エラーが発生した場合は追加しない
          }
        })
      );

      // トークンIndex情報をランダムに組み換えてMapへ格納
      tokenIndexRandomListMapByAccount.set(
        account.Address,
        common_util.arrShuffle(allTokenIndexList)
      );
    })
  );

  return tokenIndexRandomListMapByAccount;
}

/**
 * 保有トークン一覧作成
 * dApps神社で利用しているERC721コントラクト(DB保持)を参照し、
 * アカウントがトークンを保持している場合、コントラクトIDとインデックス番号をセットで一覧へ格納する
 */
export async function createMyTokenList(
  _userAddress,
  _web3,
  _contractInfoList
) {
  let tokenIndexListMapByContract = new Map();

  await Promise.all(
    // コントラクト情報毎に稼働
    _contractInfoList.map(async contractInfo => {
      try {
        // コントラクトのインスタンス作成
        const contract = web3_util.tryConnectERC721Contract(
          _web3,
          contractInfo.Address
        );

        // Ethreumより、アカウントが所有しているトークン数を取得
        const tokenCnt = await contract.methods.balanceOf(_userAddress).call();

        // コントラクトアドレスとインデックス番号を保持した一覧を作成
        const tokenIndexList = createTokenIndexList(
          _userAddress,
          contractInfo.Address,
          tokenCnt
        );

        // 保持Mapへ格納
        tokenIndexListMapByContract.set(contractInfo.Address, tokenIndexList);
      } catch (error) {
        // コントラクト情報を取得できない場合は、追加しない
      }
    })
  );

  return tokenIndexListMapByContract;
}

/**
 * トークンインデックス一覧作成
 * _cntで指定された値分、連番のインデックス情報(TokenIndexInfo)を作成する
 * ※アセット販売用にランダムで配列を組み替えるための前準備
 * @param {*} _accountAddress トークン保有アカウントのアドレス
 * @param {*} _contractAddress トークンを発行しているERC721コントラクト
 * @param {*} _cnt 最大インデックス番号
 */
export function createTokenIndexList(_accountAddress, _contractAddress, _cnt) {
  let tokenIndexList = [];

  for (let index = 0; index < _cnt; index++) {
    tokenIndexList.push(
      new TokenIndexInfo(_accountAddress, _contractAddress, index)
    );
  }

  return tokenIndexList;
}

/**
 * 画面表示用のトークン情報作成
 * ERC721トークンプロパティ(JSON)は、dApps毎に形式が異なり、
 * 本処理にて画面表示できるように形式を整える
 * プロパティが存在しないものに関しては、デフォルト値を設定して返却する
 * @param {*} _accountAddress
 * @param {*} _contractAddress
 * @param {*} _tokenId
 * @param {*} _json
 */
export function createTokenInfo(
  _accountAddress,
  _contractAddress,
  _tokenId,
  _json
) {
  // 特殊フォーマット判定 & 結果取得
  const specialFormat = getSpecialFormat(_contractAddress, _json);
  if (specialFormat !== void 0) {
    return new TokenDetailInfo(
      _accountAddress,
      _contractAddress,
      _tokenId,
      specialFormat.name,
      specialFormat.image,
      specialFormat.description,
      specialFormat.propertyMap,
      _json
    );
  }

  // 以降デフォルトフォーマットで判定
  let name = config.DEFAULT_TOKEN_NAME;
  let image = config.DEFAULT_TOKEN_IMAGE;
  let description = config.DEFAULT_TOKEN_DESCRIPTION;
  let propertyMap = new Map();

  // name取得
  if (_json['name']) {
    name = _json['name'];
  }

  // image取得
  if (_json['image']) {
    image = _json['image'];
  } else if (_json['image_url']) {
    image = _json['image_url'];
  }

  // description取得
  if (_json['description']) description = _json['description'];

  // 表示プロパティ取得
  if (_json['attributes']) {
    const attributes = _json['attributes'];

    // ERC721トークンによってプロパティの持ち方が異なるため、
    // 保有形式によってプロパティ作成方法を変更
    try {
      for (let key in attributes) {
        // OpenSea標準形式
        if (isOpenSeaFormat(attributes[key])) {
          const setKey = attributes[key]['trait_type'];
          const setValue =
            attributes[key]['value'] !== null ? attributes[key]['value'] : '';
          propertyMap.set(setKey, setValue);
        } else {
          // その他
          const setValue = attributes[key] !== null ? attributes[key] : '';

          // 配列、Object以外の場合のみ設定する
          if (!(setValue instanceof Object)) {
            propertyMap.set(key, setValue);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return new TokenDetailInfo(
    _accountAddress,
    _contractAddress,
    _tokenId,
    name,
    image,
    description,
    propertyMap,
    _json
  );
}

function getSpecialFormat(address, json) {
  for (let index = 0; index < specialFormatList.length; index++) {
    const specialFormat = specialFormatList[index];

    if (specialFormat.isOwnAddress(address)) {
      specialFormat.parse(json);
      return specialFormat;
    }
  }

  return void 0;
}

/**
 * JSONがOpenSea規格か判定
 * @param {*} attribute
 */
function isOpenSeaFormat(attribute) {
  // null or 要素が存在しないか判定
  if (attribute !== null && attribute['trait_type'] && attribute['value']) {
    return true;
  }

  return false;
}

/**
 * トークン詳細情報一覧より、指定したコントラクトとトークンIDのデータを取得する
 * @param {*} _tokenDetailInfoList // トークン詳細一覧
 * @param {*} _tokenId // 抽出するトークンID
 * @param {*} _contractAddress // 抽出するERC721コントラクトアドレス
 */
export async function searchTokenDetailInfo(
  _tokenDetailInfoList,
  _tokenId,
  _contractAddress
) {
  // 一覧存在チェック
  if (_tokenDetailInfoList === void 0) {
    return [];
  }

  // コントラクトアドレスとトークンIDが一致するデータを返却
  return _tokenDetailInfoList.filter(tokenDetailInfo => {
    if (
      tokenDetailInfo.tokenId.toString() === _tokenId.toString() &&
      tokenDetailInfo.contractAddress === _contractAddress
    ) {
      return true;
    }
    return false;
  });
}

/**
 * トークン取得に失敗した際のトークン情報を生成する
 */
export function createErrTokenInfo() {
  return new TokenDetailInfo(
    '',
    '',
    '',
    config.ERR_TOKEN_NAME,
    config.ERR_TOKEN_IMAGE,
    config.ERR_TOKEN_DESCRIPTION,
    new Map(),
    ''
  );
}
