/**
 * トークン販売価格
 */
export function tokenPrice(tokenPriceByWei, tokenPriceByEth) {
  return {
    type: 'TOKEN_PRICE',
    tokenPriceByWei,
    tokenPriceByEth
  };
}

/**
 * トークン販売価格設定
 */
export function setTokenPrice(_priceByWei, _priceByEth) {
  return dispatch => {
    return dispatch(tokenPrice(_priceByWei, _priceByEth));
  };
}

/**
 * トークン詳細情報
 */
export function tokenDetailInfo(tokenDetailInfo) {
  return {
    type: 'TOKEN_DETAIL_INFO',
    tokenDetailInfo
  };
}

/**
 * トークン詳細情報設定
 */
export function setTokenDetailInfo(_tokenDetailInfo) {
  return dispatch => {
    return dispatch(tokenDetailInfo(_tokenDetailInfo));
  };
}

/**
 * 画面表示トークン詳細情報
 * @param {*} watchTokenDetail
 */
export function watchTokenDetail(watchTokenDetail) {
  return {
    type: 'WATCH_TOKEN_DETAIL',
    watchTokenDetail
  };
}

/**
 * 画面表示トークン詳細情報設定
 */
export function setWatchTokenDetail(_tokenDetailInfo) {
  return dispatch => {
    return dispatch(watchTokenDetail(_tokenDetailInfo));
  };
}

export function watchContractInfo(watchContractInfo) {
  return {
    type: 'WATCH_TOKEN_CONTRACT_INFO',
    watchContractInfo
  };
}

export function setWatchContractInfo(_watchContractInfo) {
  return dispatch => {
    return dispatch(watchContractInfo(_watchContractInfo));
  };
}

export function isSaleWatchToken(isSaleWatchToken) {
  return {
    type: 'WATCH_TOKEN_IS_SALE',
    isSaleWatchToken
  };
}

export function setWatchTokenSaleStatus(_isSale) {
  return dispatch => {
    return dispatch(isSaleWatchToken(_isSale));
  };
}
