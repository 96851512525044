import { connect } from 'react-redux';
import * as token from '../actions/token';
import AssetCard from '../components/AssetCard';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // token
    setTokenDetailInfo: _tokenDetailInfo =>
      dispatch(token.setTokenDetailInfo(_tokenDetailInfo)),
    setWatchTokenDetail: _tokenDetailInfo =>
      dispatch(token.setWatchTokenDetail(_tokenDetailInfo)),
    setWatchContractInfo: _contractInfo =>
      dispatch(token.setWatchContractInfo(_contractInfo)),
    setWatchTokenSaleStatus: _isSale =>
      dispatch(token.setWatchTokenSaleStatus(_isSale))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetCard);
