import React, { Component } from 'react';
import Lang from '../containers/Lang';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import * as config from '../config/config';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const styles = theme => ({
  // デザインに合わせて制御
  dialogRoot: {
    zIndex: '9999 !important'
  }
});

class Header extends Component {
  state = { top: false, time: '' };
  componentDidMount = () => {
    this.ticketTimer = setInterval(() => {
      if (this.props.common.ticketInfo !== void 0) {
        const time = this.props.common.ticketInfo.getAvailableTicketTime();
        if (time !== void 0) {
          this.setState({
            time: this.props.common.ticketInfo.getAvailableTicketTime()
          });
        } else {
          this.setState({
            time: void 0
          });
        }
      }
    }, 1000);
  };

  componentWillUnmount() {
    // タイマーが稼働している場合は停止
    if (this.ticketTimer !== 'undefined') {
      clearInterval(this.ticketTimer);
    }
  }
  /**
   * アカウント描写
   */
  renderAccountText() {
    return (
      <div>
        Account :{' '}
        <span>
          {this.props.wallet.account.slice(0, 5) +
            '...' +
            this.props.wallet.account.slice(-3)}
        </span>
      </div>
    );
  }

  /**
   * チケット枚数、有効期限描写
   */
  renderTicketText() {
    // 利用できるチケット枚数
    let cnt = 0;
    if (
      this.props.common.ticketInfo !== void 0 &&
      this.props.common.ticketInfo.hasFreeTicket()
    ) {
      cnt = this.props.common.ticketInfo.ticketCnt.toString();
    }

    // 複数判定
    const isMultiple = cnt > 1 ? true : false;

    // 日時複数判定
    let isMultipleTime = false;
    if (this.state.time !== void 0) {
      isMultipleTime = this.state.time.time > 1;
    }

    // 日時部分文字
    const dateType =
      this.state.time === void 0
        ? ''
        : this.getTicketTimeTypeText(
            this.props.common.lang,
            this.state.time.type,
            isMultipleTime
          );

    // 英語と日本語で表記変更
    if (this.props.common.lang === config.LANG_JAPANESE) {
      if (cnt > 0 && this.state.time && this.state.time.time) {
        return (
          <div>
            チケット <span>{cnt}</span> 枚 ( 有効期限{' '}
            <span>{this.state.time.time}</span> {dateType} )
          </div>
        );
      } else {
        return (
          <div>
            チケット <span>{cnt}</span> 枚
          </div>
        );
      }
    } else {
      const ticket = isMultiple ? 'tickets' : 'ticket';

      if (cnt > 0 && this.state.time && this.state.time.time) {
        return (
          <div>
            <span>{cnt}</span> {ticket} ( valid for{' '}
            <span>{this.state.time.time}</span> {dateType} )
          </div>
        );
      } else {
        return (
          <div>
            <span>{cnt}</span> {ticket}
          </div>
        );
      }
    }
  }

  /**
   * 有効期限のタイプに合わせた文言を取得する
   */
  getTicketTimeTypeText(_lang, _type, _isMultiple) {
    let strTime = '';
    if (_lang === config.LANG_JAPANESE) {
      switch (_type) {
        case 'd':
          strTime = '日';
          break;
        case 'h':
          strTime = '時間';
          break;
        case 'm':
          strTime = '分';
          break;
        case 's':
          strTime = '秒';
          break;
        default:
          strTime = '';
      }
    } else {
      switch (_type) {
        case 'd':
          strTime = 'day';
          break;
        case 'h':
          strTime = 'hour';
          break;
        case 'm':
          strTime = 'minute';
          break;
        case 's':
          strTime = 'second';
          break;
        default:
          strTime = '';
      }

      if (_isMultiple) {
        strTime += 's';
      }
    }
    return strTime;
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ top: !state.top }));
  };

  headerStyle = () => {
    // if (this.props.wallet.networkId === 5777) {
    //   return { background: 'mediumseagreen' };
    // }

    // if (this.props.wallet.networkId === 4) {
    //   return { background: 'crimson' };
    // }

    return {};
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div id="header" style={this.headerStyle()}>
          <div id="menu">
            <Link to="/top">
              <img src="/img/logo.svg" alt="logo" />
            </Link>
            {/* topメニューボタン */}
            {/* <Link
              to={this.props.wallet.isLinked ? '/fortune' : '/unlock'}
              style={{ marginLeft: '10px' }}
            >
              <img src="/img/menu1.svg" alt="fortune" />
            </Link> */}
            {/* <Link
              to={this.props.wallet.isLinked ? '/asset-sales' : '/unlock'}
              style={{ marginLeft: '10px' }}
            >
              <img src="/img/menu2.svg" alt="asset-sales" />
            </Link> */}
            {/* <Link
              to={this.props.wallet.isLinked ? 'my-asset' : '/unlock'}
              style={{ marginLeft: '10px' }}
            >
              <img src="/img/menu3.svg" alt="my-asset" />
            </Link> */}
            {/* <a href="#">
              <i className="fas fa-bars" onClick={this.handleDrawerToggle} />
            </a> */}
          </div>
          <div id="stateus">
            {this.props.wallet.isLinked && this.props.wallet.account ? (
              <React.Fragment>
                <div id="account">{this.renderAccountText()}</div>
                <div id="ticket">{this.renderTicketText()}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div id="account" />
                <div id="ticket" />
              </React.Fragment>
            )}
            <Lang type="pc" />
          </div>
        </div>

        <MediaQuery query="(max-width: 999px)">
          <Drawer
            anchor="right"
            open={this.state.top}
            onClose={this.handleDrawerToggle}
            classes={{
              root: classes.dialogRoot
            }}
          >
            <div id="sp-header">
              <div id="sp-menu">
                <Link to="/top" onClick={this.handleDrawerToggle}>
                  <img src="/img/logo.svg" alt="logo" />
                </Link>
                {/* アコーディオンメニューボタン */}
                {/* <Link
                  to={this.props.wallet.isLinked ? '/fortune' : '/unlock'}
                  onClick={this.handleDrawerToggle}
                >
                  <img src="/img/menu1.svg" alt="fortune" />
                </Link>
                <Link
                  to={this.props.wallet.isLinked ? '/asset-sales' : '/unlock'}
                  onClick={this.handleDrawerToggle}
                >
                  <img src="/img/menu2.svg" alt="asset-sales" />
                </Link>
                <Link
                  to={this.props.wallet.isLinked ? 'my-asset' : '/unlock'}
                  onClick={this.handleDrawerToggle}
                >
                  <img src="/img/menu3.svg" alt="my-asset" />
                </Link> */}
              </div>
              <div id="sp-stateus">
                {this.props.wallet.isLinked && this.props.wallet.account && (
                  <React.Fragment>
                    <div id="sp-account">{this.renderAccountText()}</div>
                    <div id="sp-ticket">{this.renderTicketText()}</div>
                  </React.Fragment>
                )}
                <Lang type="sp" handleDrawerToggle={this.handleDrawerToggle} />
              </div>
            </div>
          </Drawer>
        </MediaQuery>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Header);
