import React, { Component } from 'react';
import Layout from '../containers/Layout';
import WaitDialog from '../components/WaitDialog';
import * as common_util from '../utils/common';

class Unlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.connectWallet = this.connectWallet.bind(this);
  }

  connectWallet() {
    try {
      this.props.connectWallet();
      this.setState({ open: true });
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // ログインできた場合は占い画面へ
    if (nextProps.wallet.isLinked) {
      nextProps.history.push('/fortune');
    }
    // ログインをキャンセルした場合はwaitingダイアログを閉じる
    if (!nextProps.wallet.connectWait) {
      return { open: false };
    }

    return null;
  }

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            <section className="error">
              <img src="img/error.svg" alt="jinja" />
              <h2 style={{ fontSize: '1.8em' }}>
                Your wallet isn't accessible.
                <br />
                ウォレットにアクセス出来ません。
              </h2>
              <button className="btnM" onClick={this.connectWallet}>
                <i className="fas fa-lock-open" /> UNLOCK YOUR WALLET
              </button>
              {!this.props.wallet.isExistWallet && (
                <h3>
                  wallet not found. please get{' '}
                  <a href="https://metamask.io/">metamask</a>{' '}
                </h3>
              )}
              <br />
              <p style={{ fontSize: '1.0em' }}>
                Please install Ethereum wallet extension, such as METAMASK.
                <br />
                METAMASKなどのETHウォレットをインストールしてください。
                <br />
                <a
                  href="https://metamask.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./img/top/metamask-fox-wordmark-stacked.svg"
                    alt="metamask-fox"
                  />
                </a>
              </p>
            </section>
          </div>
        </div>
        <WaitDialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        />
      </Layout>
    );
  }
}

export default Unlock;
