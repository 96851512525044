import { connect } from 'react-redux';
import * as common from '../actions/common';
import * as wallet from '../actions/wallet';
import * as fortune from '../actions/fortune';
import * as token from '../actions/token';
import Transaction from '../views/Transaction';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // common
    initBaseLogicOfDidMount: (_props, _account) =>
      dispatch(common.initBaseLogicOfDidMount(_props, _account)),
    setAssetListCreator: _assetListCreator =>
      dispatch(common.setAssetListCreator(_assetListCreator)),
    // wallet
    connectWallet: _tryActivate =>
      dispatch(wallet.connectWallet((_tryActivate = true))),
    // fortune
    setRefFortuneResult: _bool => dispatch(fortune.setRefFortuneResult(_bool)),
    // token
    setTokenPrice: (_priceByWei, _priceByEth) => {
      dispatch(token.setTokenPrice(_priceByWei, _priceByEth));
    },
    // token
    setWatchTokenDetail: _tokenDetailInfo =>
      dispatch(token.setWatchTokenDetail(_tokenDetailInfo)),
    setWatchContractInfo: _contractInfo =>
      dispatch(token.setWatchContractInfo(_contractInfo)),
    setWatchTokenSaleStatus: _isSale =>
      dispatch(token.setWatchTokenSaleStatus(_isSale))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transaction);
