import * as constant from '../../config/constant';
/**
 * 引換券情報
 */
export default class TicketInfo {
  /** 所有枚数 */
  ticketCnt;
  /** 利用可能開始日 */
  availableStartTime;
  /** 有効期限 */
  availablePeriod;
  constructor(_ticketCnt, _availableStartTime, _availablePeriod) {
    this.ticketCnt = _ticketCnt;
    this.availableStartTime = _availableStartTime;
    this.availablePeriod = _availablePeriod;
  }

  /**
   * 有効期限内チケット判定
   */
  hasFreeTicket() {
    // 枚数判定
    if (this.ticketCnt <= 0) return false;

    // 有効期限判定
    const diff = this.availablePeriod - new Date();
    if (diff <= 0) return false;

    return true;
  }

  /**
   * チケット利用開始判定
   */
  isUseFreeTicket() {
    // 有効期限判定
    const diff = this.availableStartTime - new Date();
    if (diff > 0) return false;

    return true;
  }

  /**
   * 利用可能までの期間(day)
   */
  getUseTicketDay() {
    // 有効期限判定
    const diff = this.availableStartTime - new Date();
    if (diff >= constant.UNIX_TIME_ONE_DAY) {
      return Math.floor(diff / constant.UNIX_TIME_ONE_DAY).toString();
    } else if (diff > 0) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * 有効期限取得(日時分)
   */
  getAvailableTicketTime() {
    const diff = this.availablePeriod - new Date();

    // 日
    if (diff >= constant.UNIX_TIME_ONE_DAY) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_DAY).toString(),
        type: 'd'
      };
      // 時
    } else if (
      constant.UNIX_TIME_ONE_DAY > diff &&
      diff >= constant.UNIX_TIME_ONE_HOUR
    ) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_HOUR).toString(),
        type: 'h'
      };
      // 分
    } else if (
      constant.UNIX_TIME_ONE_HOUR > diff &&
      diff >= constant.UNIX_TIME_ONE_MINUIT
    ) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_MINUIT).toString(),
        type: 'm'
      };
      // 秒
    } else if (constant.UNIX_TIME_ONE_MINUIT > diff && diff >= 0) {
      return {
        time: Math.floor(diff / constant.UNIX_TIME_ONE_SEC).toString(),
        type: 's'
      };
      // 期限切れ
    } else if (diff <= 0) {
      return void 0;
    }
  }
}
