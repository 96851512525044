/**
 * コントラクト情報
 */
export default class ContractInfo {
  /** アドレス */
  Address;
  /** コントラクトイメージ画像URL */
  Img;
  /** コントラクト名 */
  Name;
  /** コントラクト情報参照先 */
  Url;
  /** 説明文 */
  DescriptionJP;
  DescriptionEN;

  constructor(_address, _img, _name, _url, _descriptionJP, _descriptionEN) {
    this.Address = _address;
    this.Img = _img;
    this.Name = _name;
    this.Url = _url;
    this.DescriptionJP = _descriptionJP;
    this.DescriptionEN = _descriptionEN;
  }
}
