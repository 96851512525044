export default class Transaction {
  hash;
  status;
  from;

  constructor(_hash, _status, _from) {
    this.hash = _hash;
    this.status = _status;
    this.from = _from;
  }
}
