import firebase from 'firebase/app';
import 'firebase/firestore';
import PoolAccount from './classes/PoolAccount';
import ContractInfo from './classes/ContractInfo';

const config = {
  // apiKey: '',
  // authDomain: '',
  databaseURL: process.env.REACT_APP_FIRESTORE,
  projectId: process.env.REACT_APP_PROJECT_ID
  // storageBucket: '',
  // messagingSenderId: '',
  // appId: ''
};

/**
 * Firestore情報作成
 * 既に作成済の場合はReduxより取得し返却する
 */
export function createFirestore() {
  let firestore;
  if (!firebase.apps.length) {
    firestore = firebase.initializeApp(config).firestore();
  } else {
    firestore = firebase.firestore();
  }

  return firestore;
}

/**
 * プールアカウント情報取得
 * Active中のアカウントを優先度降順にて取得
 * 掲載期間外のデータは除去
 */
export async function getPoolAccountInfoList() {
  const firestore = createFirestore();

  const now = Date.now();

  var poolAccountInfoList = [];
  await firestore
    .collection('PoolAccount')
    .where('Active', '==', true)
    .orderBy('Priority', 'desc')
    .get()
    .then(snapShot => {
      snapShot.forEach(doc => {
        const poolAccount = new PoolAccount(doc);

        // 掲載期間判定
        if (poolAccount.StartDate <= now && now <= poolAccount.EndDate) {
          poolAccountInfoList.push(poolAccount);
        }
      });
    });

  return poolAccountInfoList;
}

/**
 * コントラクト情報取得
 */
export async function getContractInfoList() {
  const firestore = createFirestore();

  const contractInfoList = [];
  await firestore
    .collection('ContractInfoMaster')
    .get()
    .then(snapShot => {
      snapShot.forEach(doc => {
        doc.data().ContractInfoList.forEach(contractInfo => {
          const info = new ContractInfo(
            contractInfo.Address,
            contractInfo.Img,
            contractInfo.Name,
            contractInfo.Url,
            contractInfo.DescriptionJP,
            contractInfo.DescriptionEN
          );
          contractInfoList.push(info);
        });
      });
    });

  return contractInfoList;
}
