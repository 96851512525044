import React, { Component } from 'react';
import { withRouter } from 'react-router';
import WaitDialog from '../components/WaitDialog';
import * as constant from '../config/constant';
import * as config from '../config/config';
import * as common_util from '../utils/common';
import * as web3_util from '../utils/web3';
import JinjaToken from '../contracts/JinjaToken.json';

class AssetDetail extends Component {
  // 描写タイプ 販売、保有
  drawType = constant.ASSET_DETAIL_TYPE_SALE;

  constructor(props) {
    super(props);

    if (this.props.drawType !== void 0) this.drawType = this.props.drawType;

    this.buyEthHandle = this.buyEthHandle.bind(this);
    this.getFreeHandle = this.getFreeHandle.bind(this);

    const isAvailableForSale =
      this.props.token.watchTokenDetail.contractAddress ===
      JinjaToken.networks[constant.NET_WORK_ID_MAIN_NET].address;

    this.state = {
      open: false,
      showPropertiesCnt: config.ASSET_DETAIL_MORE_VIEW_CNT,
      isAvailableForSale: isAvailableForSale
    };
  }

  async buyEthHandle() {
    try {
      // トランザクション待機ダイアログ表示
      this.setState({ open: true });

      // dApps神社コントラクト情報取得
      const contract = await web3_util.tryConnectDAppsJinjaContract(
        this.props.wallet.web3
      );

      var promise = contract.methods
        .buyAssetEth(
          this.props.token.watchTokenDetail.contractAddress,
          this.props.token.watchTokenDetail.accountAddress,
          this.props.token.watchTokenDetail.tokenId
        )
        .send({
          from: this.props.wallet.account,
          value: this.props.wallet.web3.utils.toHex(
            this.props.token.tokenPriceByWei
          )
        });

      this.props.buyEth(promise);
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  }

  async getFreeHandle() {
    try {
      // トランザクション待機ダイアログ表示
      this.setState({ open: true });

      // dApps神社コントラクト情報取得
      const contract = await web3_util.tryConnectDAppsJinjaContract(
        this.props.wallet.web3
      );

      // dApps神社コントラクトより引換券購入
      var promise = contract.methods
        .buyAssetTicket(
          this.props.token.watchTokenDetail.contractAddress,
          this.props.token.watchTokenDetail.accountAddress,
          this.props.token.watchTokenDetail.tokenId
        )
        .send({
          from: this.props.wallet.account
        });

      this.props.getFree(promise, this.props);
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.info('getDerivedStateFromProps', nextProps);
    if (nextProps.transaction.nowTransaction.status === 'start') {
      // transaction画面にてtransactionへ遷移する場合、
      //「Maximum update depth exceeded」でエラーとなるため、リロードリダイレクトを使用
      // nextProps.history.push('/transaction');
      common_util.reloadRedirect(nextProps, '/transaction');
    } else if (nextProps.transaction.nowTransaction.status === 'alertError') {
      return { open: false };
    }

    return null;
  }

  /**
   * 引換券利用ボタン
   */
  renderFreeButton() {
    if (
      this.props.common.ticketInfo !== void 0 &&
      this.props.common.ticketInfo.hasFreeTicket()
    ) {
      if (this.props.common.ticketInfo.isUseFreeTicket()) {
        // 利用可能
        return (
          <button className="btnS" onClick={this.getFreeHandle}>
            Get Free<span>(Use Coupon)</span>
          </button>
        );
      } else {
        // 利用可能まで待機
        return (
          <button
            className="btnS disabled"
            onClick={this.getFreeHandle}
            disabled
          >
            Get Free
            <span>
              (wait {this.props.common.ticketInfo.getUseTicketDay()} day )
            </span>
          </button>
        );
      }
    } else {
      // 利用できない
      return (
        <button className="btnS disabled" onClick={this.getFreeHandle} disabled>
          Get Free<span>(Not Have Coupon)</span>
        </button>
      );
    }
  }

  renderBuyAndGet() {
    return (
      <React.Fragment>
        {this.props.token.isSaleWatchToken ? (
          <div className="buy-and-get">
            {this.renderFreeButton()}
            {this.state.isAvailableForSale && (
              <button className="btnS" onClick={this.buyEthHandle}>
                Buy ETH<span>({this.props.token.tokenPriceByEth} ETH)</span>
              </button>
            )}
          </div>
        ) : (
          <div className="soldoutWord">
            Sorry, this asset has already been sold.
          </div>
        )}
      </React.Fragment>
    );
  }

  renderProperties() {
    var properties = [];

    // プロパティリスト作成
    var propList = this.createPropertyList();
    // テーブルで囲む
    properties.push(
      <table key="p-table">
        <tbody>{propList}</tbody>
      </table>
    );

    // 規定数よりプロパティが多い場合はmoreボタンを作成
    if (
      Array.from(this.props.token.watchTokenDetail.propertyMap).length >
      this.state.showPropertiesCnt
    ) {
      properties.push(
        <button
          className="btnS"
          key="prop-button"
          onClick={() => {
            this.setState({ showPropertiesCnt: 99 });
          }}
        >
          <i className="im im-reset" /> More
        </button>
      );
    }

    return properties;
  }

  createPropertyList() {
    var properties = [];
    let cnt = 0;

    this.props.token.watchTokenDetail.propertyMap.forEach((value, key) => {
      // プロパティ分ループ
      if (cnt < this.state.showPropertiesCnt) {
        properties.push(
          <tr key={'tr-' + ++cnt}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        );
      }
    });

    // プロパティが存在しない場合
    if (cnt === 0) {
      properties.push(
        <tr key="noPrp">
          <td>no properties</td>
        </tr>
      );
    }

    return properties;
  }

  render() {
    return (
      <section style={{ margin: 0, position: 'initial' }}>
        <WaitDialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        />
        <label className="close_button" onClick={this.props.onClose}>
          <span role="img" aria-label="batu">
            ✖️
          </span>
        </label>
        {/* <h2 style={{ paddingBottom: '10px' }}>
          {this.props.token.watchContractInfo
            ? this.props.token.watchContractInfo.Name + '-'
            : ''}
          {this.props.token.watchTokenDetail.name}
        </h2> */}
        <div className="assetModalPart">
          <div className="assetImage">
            <img
              src={this.props.token.watchTokenDetail.image}
              alt={this.props.token.watchTokenDetail.name}
            />
          </div>
          <div className="assetCategory">
            <img
              src={this.props.token.watchContractInfo.Img}
              alt={this.props.token.watchContractInfo.Name}
            />
            {this.props.token.watchContractInfo.Name}
          </div>
          <div className="assetName">
            {this.props.token.watchTokenDetail.name}
          </div>
          <div className="assetData">
            <p style={{ whiteSpace: 'pre-line' }}>
              {this.props.token.watchTokenDetail.description}
            </p>
          </div>
          {this.drawType === constant.ASSET_DETAIL_TYPE_SALE &&
            this.renderBuyAndGet()}
        </div>
        <br />
        <div className="assetModalPart">
          <h3>Properties</h3>
          {this.renderProperties()}
        </div>
        <br />
        {this.props.token.watchContractInfo && (
          <div className="assetModalPart">
            {/* <h3>Vender? Maker? Supplier?</h3> */}
            <img
              src={this.props.token.watchContractInfo.Img}
              className="infoIcon"
              alt="infoIcon"
            />
            <p className="clearfix">
              <strong>{this.props.token.watchContractInfo.Name}</strong>
              <br />
              <a
                href={this.props.token.watchContractInfo.Url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.token.watchContractInfo.Url}
              </a>
              <br />
              {this.props.common.lang === config.LANG_ENGLISH
                ? this.props.token.watchContractInfo.DescriptionEN
                : this.props.token.watchContractInfo.DescriptionJP}
            </p>
          </div>
        )}
      </section>
    );
  }
}

export default withRouter(AssetDetail);
