import React, { Component } from 'react';
import Layout from '../containers/Layout';
import { FormattedHTMLMessage } from 'react-intl';
import * as constant from '../config/constant';

class Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainNet: true
    };
  }

  componentDidMount = async () => {
    // 初期遷移
    // wallet接続未実施の場合は接続
    try {
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet();
      }

      if (this.props.wallet.isLinked) {
        if (process.env.REACT_APP_DEBUG !== '1') {
          // メインネットか判定
          if (this.props.wallet.networkId !== constant.NET_WORK_ID_MAIN_NET) {
            this.setState({ mainNet: false });
          }
        }

        await this.props.initBaseLogicOfDidMount(
          this.props,
          this.props.wallet.account
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            <section className="error">
              <img src="/img/error.svg" alt="error" />
              {this.state.mainNet ? (
                <React.Fragment>
                  <h2>Unexpected Error</h2>
                  <p>
                    <FormattedHTMLMessage id="error.main" />
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2>Switch MainNetwork</h2>
                  <p>
                    <FormattedHTMLMessage id="error.notMain" />
                  </p>
                </React.Fragment>
              )}
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Error;
