/**
 * 占い結果画面参照可能設定
 */
export function refFortuneResult(_bool) {
  return {
    type: 'SET_REFER_FORTUNE_RESULT',
    isReferFortuneResult: _bool
  };
}

export function setRefFortuneResult(_bool) {
  return dispatch => {
    return dispatch(refFortuneResult(_bool));
  };
}
