import * as cf from '../utils/common';

const initialState = {
  isFortuneFree: false
};

function fortuneReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_REFER_FORTUNE_RESULT':
      return Object.assign({}, state, {
        isReferFortuneResult: true
      });

    default:
      const SessionState = cf.getSession('fortune');

      if (SessionState) {
        // ストレージにデータがあったらそのまま引き継ぐ
        return Object.assign({}, state, SessionState);
      }

      return state;
  }
}
export default fortuneReducer;
