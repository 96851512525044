import * as token_util from '../token';
import * as config from '../../config/config';

class MyAssetListCreator {
  /** プロパティ(this.props.common) */
  prop = null;
  /** コントラクトアドレス毎の保有トークンリストMap */
  tokenIndexListMapByContract = new Map();
  /** 表示対象のトークン情報リスト */
  myTokenList = [];
  /** 作成済トークン情報インデックス */
  moreNextMyTokenIndex = 0;
  /** 全てトークン情報を返却済か */
  isMoreMax = false;

  constructor(prop) {
    this.prop = prop;
  }

  async init() {
    // コントラクト毎の保有アセットリスト作成
    await this.createMyAssetList();

    // 保有トークンリスト作成(DB登録されているコントラクト順)
    this.createMyTokenAllList();

    // more用の作成済インデック初期化
    this.moreNextMyTokenIndex = 0;

    // more用 最大返却済判定を初期化
    this.isMoreMax = false;
  }

  getMoreMyTokenList(_cnt = config.DEFAULT_DRAW_MY_TOKEN_CNT) {
    // 保持チェック
    if (this.myTokenList.length === 0) {
      this.isMoreMax = true;
      return [];
    }

    // 全て呼び出し済み判定
    if (this.moreNextMyTokenIndex >= this.myTokenList.length) return [];

    let end = this.moreNextMyTokenIndex + _cnt;

    if (end >= this.myTokenList.length) {
      end = this.myTokenList.length;
      this.isMoreMax = true;
    }

    // リスト作成
    const tokenIndexList = this.myTokenList.slice(
      this.moreNextMyTokenIndex,
      end
    );

    // 次回用にインデックス更新
    this.moreNextMyTokenIndex = end;

    return tokenIndexList;
  }

  /**
   * more情報初期化
   */
  resetMoreTokenListCreatedIndex() {
    this.moreNextMyTokenIndex = 0;
    this.isMoreMax = false;
  }

  /**
   * 保有アセットリスト作成
   */
  async createMyAssetList() {
    this.tokenIndexListMapByContract = await token_util.createMyTokenList(
      this.prop.wallet.account,
      this.prop.wallet.web3,
      this.prop.common.erc721ContractInfoList
    );
  }

  /**
   * 保有トークンリスト作成(DB登録されているコントラクト順)
   */
  createMyTokenAllList() {
    let drawTokenIndexList = [];

    // コントラクト毎ループ
    for (
      let index = 0;
      index < this.prop.common.erc721ContractInfoList.length;
      index++
    ) {
      // コンラクト情報取得
      const contractInfo = this.prop.common.erc721ContractInfoList[index];
      // コンラクト紐づくインデックスの一覧取得
      const tokenIndexList = this.tokenIndexListMapByContract.get(
        contractInfo.Address
      );
      // 配列にまとめる
      drawTokenIndexList = drawTokenIndexList.concat(tokenIndexList);
    }

    this.myTokenList = drawTokenIndexList;
    // more用 最大返却済判定を初期化
    this.resetMoreTokenListCreatedIndex();
  }

  /**
   * 保有トークンリスト作成(DB登録されているコントラクト順)
   */
  createMyTokenOneContractList(_contractAddress) {
    // コンラクト紐づくインデックスの一覧取得
    const tokenIndexList = this.tokenIndexListMapByContract.get(
      _contractAddress
    );

    // 処理中のリストに設定
    this.myTokenList = tokenIndexList !== void 0 ? tokenIndexList : [];
    // more用 最大返却済判定を初期化
    this.resetMoreTokenListCreatedIndex();
  }
}

export default MyAssetListCreator;
