import { connect } from 'react-redux';
import * as wallet from '../actions/wallet';
import Unlock from '../views/Unlock';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // wallet
    connectWallet: _tryActivate =>
      dispatch(wallet.connectWallet((_tryActivate = true)))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unlock);
