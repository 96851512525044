import React, { Component } from 'react';
// import GoogleAds from 'react-google-ads';

class FooterAD extends Component {
  constructor(props) {
    super(props);
    const onlyPc = this.props.onlyPc !== void 0 ? this.props.onlyPc : false;
    this.state = { onlyPc: onlyPc };
  }
  render() {
    return (
      <section className={this.state.onlyPc ? 'footerAD pc' : 'footerAD'}>
        {/* <GoogleAds
          client="ca-pub-1029313550685146"
          slot="XXXXXXX"
          className="adsbygoogle"
          format="auto"
          style={{ display: 'block' }}
        /> */}
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=300x250;setID=402414"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=300x250;setID=402414;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=300x250;setID=402415"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=300x250;setID=402415;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=300x250;setID=402416"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=300x250;setID=402416;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
      </section>
    );
  }
}

export default FooterAD;
