import React, { Component } from 'react';
import Header from '../containers/Header';
import Footer from '../components/Footer';
import AlertDialog from '../containers/AlertDialog';

// 言語
import { IntlProvider, addLocaleData } from 'react-intl';
import jaLocaleData from 'react-intl/locale-data/ja';
import enLocaleData from 'react-intl/locale-data/en';
import en_US from '../config/lang/en_US';
import ja_JP from '../config/lang/ja_JP';

// css読み込み
import '../css/base.css';
import '../css/spmenu-sample.css';
import '../css/css-set-load.css';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.4)'
      }
    }
  }
});

// 言語
addLocaleData([...enLocaleData, ...jaLocaleData]);

class Layout extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      notification: null,
      alertOpen: false
    };
  }

  componentDidMount = async () => {
    // 表示ブラウザ確認判定
    if (this.props.common.checkedApproveIE) return;

    let userAgent = window.navigator.userAgent.toLowerCase();

    if (
      userAgent.indexOf('msie') !== -1 ||
      userAgent.indexOf('trident') !== -1
    ) {
      this.setState({ alertOpen: true });
    } else {
      this.props.approveIE();
    }
  };

  chooseLocale = locale => {
    switch (locale) {
      case 'en':
        return en_US;
      case 'ja':
        return ja_JP;
      default:
        return en_US;
    }
  };

  render() {
    return (
      <IntlProvider
        locale={this.props.common.lang}
        messages={this.chooseLocale(this.props.common.lang)}
      >
        <ThemeProvider theme={theme}>
          <div>
            <Header />
            {this.props.children}
            <Footer />
            <AlertDialog
              open={this.state.alertOpen}
              onClose={() => this.setState({ alertOpen: false })}
            ></AlertDialog>
          </div>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default Layout;
