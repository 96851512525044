import React, { Component } from 'react';
import Layout from '../containers/Layout';
import FooterAD from '../components/FooterAD';
import AssetCard from '../containers/AssetCard';
import DetailDialog from '../components/DetailDialog';
import AssetListCreator from '../utils/classes/AssetListCreator';
import TokenIndexInfo from '../utils/classes/TokenIndexInfo';
import { FormattedHTMLMessage } from 'react-intl';

import * as web3_util from '../utils/web3';
import * as common_util from '../utils/common';
import * as config from '../config/config';
import * as constant from '../config/constant';

class Transaction extends Component {
  // リトライ用
  watchTokenDetail;
  watchContractInfo;
  tokenPriceByEth;
  isSaleWatchToken;

  constructor(props) {
    super(props);

    this.state = {
      drawLeftList: [],
      drawRightList: [],
      openDetail: false,
      // 画面遷移時に処理していた情報を設定
      nowTransaction: this.props.transaction.nowTransaction
    };

    // トークン情報
    this.watchTokenDetail = this.props.token.watchTokenDetail;
    this.watchContractInfo = this.props.token.watchContractInfo;
    this.isSaleWatchToken = this.props.token.isSaleWatchToken;

    this.retry = this.retry.bind(this);

    // トランザクションを処理中でない場合はTopへリダイレクト
    if (!this.isExistTransaction()) {
      this.props.history.push('/top');
      return;
    }
  }

  componentDidMount = async () => {
    try {
      // トランザクションを処理中でない場合は処理終了(constructorでリダイレクト済)
      if (!this.isExistTransaction()) {
        return;
      }

      // 初期遷移
      // wallet接続未実施の場合は接続
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet();
      }
      // web3接続できない場合はtopへ
      if (!this.props.wallet.isLinked) {
        this.props.history.push('/top');
        return;
      }

      // Base処理 各種コントラクト、引換券情報更新
      await this.props.initBaseLogicOfDidMount(
        this.props,
        this.props.wallet.account
      );

      if (this.state.nowTransaction.from === 'fortune') {
        // 占いから遷移
        this.renderAd();
      } else if (this.state.nowTransaction.from === 'asset_sale') {
        // トークン販売価格取得
        const tokenPrice = await web3_util
          .tryConnectDAppsJinjaContract(this.props.wallet.web3)
          .then(async contract => {
            return await web3_util.getTokenValue(contract);
          });

        // トークン販売価格最新化(WaiとEth両方設定)
        // Waiは処理用、Ethは表示用
        this.props.setTokenPrice(
          tokenPrice.toString(),
          this.props.wallet.web3.utils.fromWei(tokenPrice.toString(), 'ether')
        );

        // アセットクリエイターがReduxへ登録されていない場合は作成
        if (this.props.common.assetListCreator === void 0) {
          var assetListCreator = new AssetListCreator(this.props);
          await assetListCreator.init();

          // Reduxへ保存
          this.props.setAssetListCreator(assetListCreator);
        }

        const tokenInfo = new TokenIndexInfo(
          this.watchTokenDetail.accountAddress,
          this.watchTokenDetail.contractAddress,
          this.watchTokenDetail.tokenId
        );

        // トークン情報画面描写
        this.drawRecommendTokenList(
          await this.props.common.assetListCreator.getRecommendRandomTokenList(
            4,
            tokenInfo
          )
        );
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    console.info('getDerivedStateFromProps', nextProps);

    const checkTransaction = nextProps.transaction.transactionMap.get(
      prevState.nowTransaction.hash
    );

    if (checkTransaction === void 0) return null;

    if (checkTransaction.status === 'complete') {
      if (checkTransaction.from === 'fortune') {
        nextProps.setRefFortuneResult(true);
        nextProps.history.push('/fortune-result');
      }
    }

    return { nowTransaction: checkTransaction };
  }

  renderAd() {
    let _drawLeftList = [];
    let _drawRightList = [];

    // 左

    _drawLeftList.push(
      <li className="banner" key="left-1">
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=250x250;setID=402404"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=250x250;setID=402404;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
      </li>
    );
    _drawLeftList.push(
      <li className="banner" key="left-2">
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=250x250;setID=402405"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=250x250;setID=402405;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
      </li>
    );

    // 右
    _drawRightList.push(
      <li className="banner" key="right-1">
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=250x250;setID=402406"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=250x250;setID=402406;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
      </li>
    );

    _drawRightList.push(
      <li className="banner" key="right-2">
        <a
          href="https://servedbyadbutler.com/go2/;ID=177511;size=250x250;setID=402407"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://servedbyadbutler.com/adserve/;ID=177511;size=250x250;setID=402407;type=img;click=CLICK_MACRO_PLACEHOLDER"
            alt=""
          />
        </a>
      </li>
    );

    // state更新
    this.setState({
      drawLeftList: _drawLeftList,
      drawRightList: _drawRightList
    });
  }

  /**
   * トランザクション処理中か判定
   */
  isExistTransaction() {
    if (
      this.state.nowTransaction !== void 0 &&
      (this.state.nowTransaction.from === 'fortune' ||
        this.state.nowTransaction.from === 'asset_sale')
    ) {
      return true;
    }
    return false;
  }

  /**
   * おすすめトークンリスト描写
   */
  drawRecommendTokenList(_drawTokenIndexList) {
    let _drawLeftTokenList = [];
    let _drawRightTokenList = [];

    if (_drawTokenIndexList === null) {
      return;
    }

    // 描写場所に合わせて格納先のstateを変更
    for (let i = 0; i < _drawTokenIndexList.length; i++) {
      if (i % 2 === 0) {
        _drawLeftTokenList.push(this.drawToken(_drawTokenIndexList[i], i));
      } else {
        _drawRightTokenList.push(this.drawToken(_drawTokenIndexList[i], i));
      }
    }

    // state更新
    this.setState({
      drawLeftList: _drawLeftTokenList,
      drawRightList: _drawRightTokenList
    });
  }

  /**
   * トークンカード描画
   * @param {*} _tokenIndexInfo
   */
  drawToken(_tokenIndexInfo, index) {
    const renderItem = (
      <AssetCard
        key={index}
        tokenIndexInfo={_tokenIndexInfo}
        tokenIndexLatestBlock={false}
        openDetailFunc={() => {
          this.openDetailFunc();
        }}
        drawType={constant.ASSET_CARD_TYPE_SALE_CARD}
      />
    );
    return renderItem;
  }

  /**
   * リトライ実行
   * 遷移時のトランザクションに合わせて処理を変更
   */
  async retry() {
    if (this.state.nowTransaction.from === 'fortune') {
      // リトライ画面へ遷移
      this.props.history.push('/fortune');
    } else if (this.state.nowTransaction.from === 'asset_sale') {
      // 表示するトークン情報を設定(親コンポーネント、モーダル表示用)
      await this.props.setWatchTokenDetail(this.watchTokenDetail);
      await this.props.setWatchContractInfo(this.watchContractInfo);

      // コントラクトインスタンス
      const contract = await web3_util.tryConnectERC721Contract(
        this.props.wallet.web3,
        this.watchTokenDetail.contractAddress
      );

      // 販売可能か判定
      const isSale = await web3_util.checkApprove(
        this.props.wallet.web3,
        contract,
        this.watchTokenDetail.accountAddress,
        this.watchTokenDetail.tokenId.toString()
      );

      await this.props.setWatchTokenSaleStatus(isSale);
      this.setState({ openDetail: true });
    }
  }

  /**
   * トークン詳細モーダル表示
   */
  openDetailFunc() {
    this.setState({ openDetail: true });
  }

  renderEthScan() {
    return (
      <div className="transactionLogo">
        <a
          href={config.ETH_SCAN_URL + this.state.nowTransaction.hash}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="./img/etherscan-logo.svg" alt="" />
          <br />
          View on Etherscan
        </a>
      </div>
    );
  }

  renderText() {
    switch (this.state.nowTransaction.status) {
      case 'start':
      case 'waiting':
        return (
          <div className="colmn3center">
            <p>
              <FormattedHTMLMessage id="transaction.waiting.text" />
            </p>
            <div className="transactionStatus">
              <i className="fas fa-link" />
              <p>
                <FormattedHTMLMessage id="transaction.waiting.status" />
              </p>
            </div>
            {this.renderEthScan()}
          </div>
        );
      case 'complete':
        return (
          <div className="colmn3center">
            <p>
              <FormattedHTMLMessage id="transaction.complete.text" />
            </p>
            <div className="transactionStatus">
              <i className="fas fa-check" />
              <p>
                <FormattedHTMLMessage id="transaction.complete.status" />
              </p>
            </div>
            {this.renderEthScan()}
          </div>
        );
      case 'error':
        return (
          <div className="colmn3center">
            <p>
              <FormattedHTMLMessage id="transaction.failed.text" />
            </p>
            <div className="transactionStatus">
              <i className="fas fa-times" />
              <p>
                <FormattedHTMLMessage id="transaction.failed.status" />
              </p>
            </div>
            {this.renderEthScan()}
            <div className="tryagain">
              <button className="btnM" onClick={this.retry}>
                <i className="fas fa-redo-alt"></i> Try again
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            <section>
              <div className="colmn3master">
                <div className="colmn3left">
                  <ul className="assetBox">
                    {this.state.drawLeftList && this.state.drawLeftList}
                  </ul>
                </div>
                {this.renderText()}
                <div className="colmn3right">
                  <ul className="assetBox">
                    {this.state.drawRightList && this.state.drawRightList}
                  </ul>
                </div>
              </div>
            </section>
            <FooterAD onlyPc={true} />
          </div>
        </div>
        <DetailDialog
          open={this.state.openDetail}
          onClose={() => this.setState({ openDetail: false })}
          drawType={constant.ASSET_DETAIL_TYPE_SALE}
        />
      </Layout>
    );
  }
}

export default Transaction;
