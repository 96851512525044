import React, { Component } from 'react';
import Layout from '../containers/Layout';
import FooterAD from '../components/FooterAD';
import AssetCard from '../containers/AssetCard';
import DetailDialog from '../components/DetailDialog';
import WaitDialog from '../components/WaitDialog';
import MyAssetListCreator from '../utils/classes/MyAssetListCreator';
import * as constant from '../config/constant';
import * as common_util from '../utils/common';
import { FormattedHTMLMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dialogPaper: {
    // デザインより参照
    width: '60%',
    maxWidth: '640px',
    height: '80%',
    maxHeight: '960px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    background: '#fff',
    transition: '0.5s',

    // デザインに合わせて制御
    borderRadius: 'initial'
  },
  // デザインに合わせて制御
  dialogRoot: {
    zIndex: '9999 !important'
  },
  // デザインに合わせて制御
  dialogContent: {
    padding: '2em',
    overflowY: 'initial'
  }
});

const NO_FILTER_INDEX = 9999;
/**
 * 保有アセット一覧
 * アカウント情報に紐づくdApps神社で取り扱っているトークンの一覧を表示。
 * 画面表示毎に保有しているアセット情報の再取得を行い画面表示する。
 */
class MyAsset extends Component {
  // アカウント毎の画面描写済みのトークンIndexMap
  drawnIndexNumMap = new Map();
  // 描写するアセットカードのインデックス
  AssetItemKey = 0;
  // 保有アセットクリエイター
  myAssetListCreator;

  constructor(props) {
    super(props);

    this.state = {
      drawTokenList: [], // 表示するトークンの一覧
      loadingWait: true, // ローディング判定
      openDetail: false, // アセット詳細表示
      activeMore: false, // moreボタン判定
      filter: '' // フィルターインデックス番号
    };

    this.changeFilter = this.changeFilter.bind(this);
  }

  componentDidMount = async () => {
    // ローディング
    this.setState({ loadingWait: true });

    try {
      // wallet接続未実施の場合は接続
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet();
      }
      // web3接続できない場合はtopへ
      if (!this.props.wallet.isLinked) {
        this.props.history.push('/top');
        return;
      }

      // Base処理 各種コントラクト、引換券情報更新
      await this.props.initBaseLogicOfDidMount(
        this.props,
        this.props.wallet.account
      );

      // 画面表示用に保有アカウントのトークンのリスト情報作成(毎回作り直す)
      this.myAssetListCreator = new MyAssetListCreator(this.props);
      await this.myAssetListCreator.init();

      // トークン情報画面描写
      this.drawTokenList(this.myAssetListCreator.getMoreMyTokenList());

      // 保有アセットすべてを表示している場合、moreボタンを非活性に変更
      this.setState({ activeMore: !this.myAssetListCreator.isMoreMax });
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }

    // ローディング終了
    this.setState({ loadingWait: false });
  };

  /**
   * トークン一覧描写
   * 保有しているトークンを描写する
   * AssetSalesのdrawTokenList処理を流用
   *
   * @param {*} _accountAddress
   * @param {*} _tokenList
   */
  drawTokenList(_tokenList, _newTokenListFlg = false) {
    let renderItemList = [];
    for (let index = 0; index < _tokenList.length; index++) {
      const renderItem = (
        <AssetCard
          key={this.AssetItemKey++}
          tokenIndexInfo={_tokenList[index]}
          tokenIndexLatestBlock={true}
          openDetailFunc={() => {
            this.openDetailFunc();
          }}
          drawType={constant.ASSET_CARD_TYPE_MY_CARD}
        />
      );

      renderItemList.push(renderItem);
    }

    // 画面表示
    if (_newTokenListFlg) {
      this.setState({
        drawTokenList: renderItemList
      });
    } else {
      this.setState({
        drawTokenList: this.state.drawTokenList.concat(renderItemList)
      });
    }
  }

  /**
   * トークン詳細モーダル表示
   * 本Functionはアセットカードコンポーネントへ受け渡し、アセットカードより稼働する
   */
  openDetailFunc() {
    this.setState({ openDetail: true });
  }

  /**
   * moreボタンクリック
   * 一覧画面に表示するトークン情報をstateへ追加する
   */
  moreClick() {
    // トークン情報画面描写
    this.drawTokenList(this.myAssetListCreator.getMoreMyTokenList());

    // 保有アセットすべてを表示している場合、moreボタンを非活性に変更
    this.setState({ activeMore: !this.myAssetListCreator.isMoreMax });
  }

  /**
   * フィルター変更
   * @param {*} _index
   */
  changeFilter(_index) {
    this.setState({ filter: _index });
    // ALL or 指定フィルター
    if (_index === NO_FILTER_INDEX) {
      this.myAssetListCreator.createMyTokenAllList();
    } else {
      // コントラクトアドレス取得
      const contractAddress = this.props.common.erc721ContractInfoList[_index]
        .Address;

      // 表示対象のリストを再設定
      this.myAssetListCreator.createMyTokenOneContractList(contractAddress);
    }

    // トークン一覧の初期化と再描写
    var newList = this.myAssetListCreator.getMoreMyTokenList();
    this.drawTokenList(newList, true);

    this.setState({ activeMore: !this.myAssetListCreator.isMoreMax });
  }

  renderFilter() {
    let filterIndex = 0;
    var renderElement = [];

    // ALL用の設定追加
    renderElement.push(
      <label className="option" key={NO_FILTER_INDEX}>
        <input
          type="radio"
          name="option_category"
          checked={this.state.filter === NO_FILTER_INDEX}
          onChange={this.changeFilter.bind(this, NO_FILTER_INDEX)}
        />
        <span className="cp_sl07wh_title">
          {/* <img src="" /> */}
          ALL
        </span>
      </label>
    );

    for (let i = 0; i < this.props.common.erc721ContractInfoList.length; i++) {
      const info = this.props.common.erc721ContractInfoList[i];

      renderElement.push(
        <label className="option" key={filterIndex}>
          <input
            type="radio"
            name="option_category"
            checked={this.state.filter === filterIndex}
            onChange={this.changeFilter.bind(this, filterIndex)}
          />
          <span className="cp_sl07wh_title">
            <img src={info.Img} alt="icon" />
            {info.Name}
          </span>
        </label>
      );
      // 加算
      filterIndex++;
    }

    return (
      <div id="category">
        <div className="cp_ipselect07wh">
          <input
            type="radio"
            name="option_category"
            onChange={e => this.setState({ filter: '' })}
          />
          <i className="toggle cp_sl07wh_arrowdown" />
          <i className="toggle cp_sl07wh_arrowup" />
          <span className="cp_sl07wh_selectlabel">Category</span>
          {renderElement}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            <section>
              <h1>
                <FormattedHTMLMessage id="myAsset.header" />
              </h1>
              {this.renderFilter()}
              <ul className="assetBox">{this.state.drawTokenList}</ul>
              {this.state.activeMore && (
                <button className="btnM" onClick={this.moreClick.bind(this)}>
                  <i className="fas fa-sync-alt" /> More
                </button>
              )}
            </section>

            <FooterAD />
          </div>
        </div>
        <DetailDialog
          open={this.state.openDetail}
          onClose={() => this.setState({ openDetail: false })}
          drawType={constant.ASSET_DETAIL_TYPE_OWNED}
        />
        <WaitDialog open={this.state.loadingWait} text="Loading..." />
      </Layout>
    );
  }
}
export default withStyles(styles)(MyAsset);
