import * as config from '../config/config';
import * as common_util from '../utils/common';
import moment from 'moment';

import axios from 'axios';

// 共通関数

/**
 * 配列内の要素をシャッフル
 * @param {*} arr
 */
export function arrShuffle(arr) {
  var len = arr.length;
  while (len > 0) {
    var rnd = Math.floor(Math.random() * len);
    var tmp = arr[len - 1];
    arr[len - 1] = arr[rnd];
    arr[rnd] = tmp;
    len -= 1;
  }

  return arr;
}

/**
 * セッション書き込み
 */
export function setSession(key, value) {
  // base
  delete value.baseBlockNumber;
  delete value.isBaseInit;
  delete value.assetListCreator;

  // wallet
  delete value.web3;
  delete value.connectWait;
  // delete value.isExistWallet;
  // delete value.isLinked;
  delete value.ticketInfo;
  delete value.account;
  delete value.networkId;

  // token
  delete value.isSaleWatchToken;
  delete value.tokenPriceByEth;
  delete value.tokenPriceByWei;
  delete value.watchContractInfo;
  delete value.watchTokenDetail;

  // 有効期限を追加
  value['expiration'] = moment()
    .add(config.STORAGE_EXPIRATION, 'hours')
    .format();

  sessionStorage.removeItem(key);
  sessionStorage.setItem(key, JSON.stringify(value));
}

/**
 * セッション読み込み
 */
export function getSession(key) {
  var data = sessionStorage.getItem(key);

  // データが引けないならnull返す
  if (data === null) {
    return null;
  }

  data = JSON.parse(data);

  // 有効期限切れならnull返す
  var now = new Date();
  var d = new Date(data.expiration);

  if (d < now) {
    return null;
  }

  return data;
}

/**
 * セッションストレージクリア
 * @param {*} key
 */
export function clearSession(key) {
  sessionStorage.removeItem(key);
}

/**
 * ローカルストレージより言語設定を取得
 */
export function getStorageLang() {
  return common_util.getStrage('language') !== null
    ? common_util.getStrage('language')
    : config.DEFAULT_LANG;
}

/**
 * ローカルストレージ書き込み
 */
export function setStrage(key, value) {
  if (value === void 0 || value === null) {
    return;
  }

  let json = JSON.stringify(value);

  localStorage.setItem(key, json);
}

/**
 * ローカルストレージ読み込み
 */
export function getStrage(key) {
  var json = localStorage.getItem(key);

  // データが引けないならnull返す
  if (json === null || json === void 0) {
    return null;
  }

  var data = JSON.parse(json);
  return data;
}

/**
 * リロードを挟んでリダイレクト
 **/
export function reloadRedirect(_props, _to) {
  _props.history.replace(`/reload`);
  setTimeout(() => {
    _props.history.replace(_to);
  });
}

/**
 * エラー画面遷移 & エラー情報送信
 **/
export function errRedirect(_props, _error) {
  // 画面遷移
  _props.history.push('/error');
  // エラー送信
  try {
    sendError(_error);
  } catch (error) {
    // 無視する
  }
}

export function sendError(_error) {
  if (process.env.REACT_APP_DEBUG !== 1) {
    axios
      .get(
        process.env.REACT_APP_C_FUNCTION_URL + config.C_FUNCTION_ERR_DATA_STOCK,
        {
          params: {
            error: _error.stack
          }
        }
      )
      .then(response => {
        console.info('send error');
      })
      .catch(err => {
        console.error(err);
      });
  }
}

/**
 * 有効期限のタイプに合わせた文言を取得する
 */
export function getTimeTypeText(_lang, _type, _isMultiple) {
  let strTime = '';
  if (_lang === config.LANG_JAPANESE) {
    switch (_type) {
      case 'd':
        strTime = '日';
        break;
      case 'h':
        strTime = '時間';
        break;
      case 'm':
        strTime = '分';
        break;
      case 's':
        strTime = '秒';
        break;
      default:
        strTime = '';
    }
  } else {
    switch (_type) {
      case 'd':
        strTime = 'day';
        break;
      case 'h':
        strTime = 'hour';
        break;
      case 'm':
        strTime = 'minute';
        break;
      case 's':
        strTime = 'second';
        break;
      default:
        strTime = '';
    }

    if (_isMultiple) {
      strTime += 's';
    }
  }
  return strTime;
}
