import * as cf from '../utils/common';

const initialState = {
  tokenPriceByEth: 0,
  tokenPriceByWei: 0,
  tokenDetailInfoList: [],
  watchTokenDetail: null,
  watchContractInfo: null,
  isSaleWatchToken: null
};

function tokenReducer(state = initialState, action) {
  var newState = null;

  switch (action.type) {
    case 'TOKEN_PRICE':
      return Object.assign({}, state, {
        tokenPriceByEth: action.tokenPriceByEth,
        tokenPriceByWei: action.tokenPriceByWei
      });

    case 'TOKEN_DETAIL_INFO':
      let newTokenDetailInfoList = state.tokenDetailInfoList;

      // トークン情報追加
      if (newTokenDetailInfoList === void 0) newTokenDetailInfoList = [];

      newTokenDetailInfoList.push(action.tokenDetailInfo);

      newState = Object.assign({}, state, {
        tokenDetailInfoList: newTokenDetailInfoList
      });

      return newState;

    case 'WATCH_TOKEN_DETAIL':
      return Object.assign({}, state, {
        watchTokenDetail: action.watchTokenDetail
      });

    case 'WATCH_TOKEN_CONTRACT_INFO':
      return Object.assign({}, state, {
        watchContractInfo: action.watchContractInfo
      });

    case 'WATCH_TOKEN_IS_SALE':
      return Object.assign({}, state, {
        isSaleWatchToken: action.isSaleWatchToken
      });

    default:
      const SessionState = cf.getSession('token');

      if (SessionState) {
        // ストレージにデータがあったらそのまま引き継ぐ
        return Object.assign({}, state, SessionState);
      }

      return state;
  }
}
export default tokenReducer;
