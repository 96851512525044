import { connect } from 'react-redux';
import AssetDetail from '../components/AssetDetail';
import * as transaction from '../actions/transaction';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    buyEth: _promise => dispatch(transaction.buyEth(_promise)),
    getFree: (_promise, _props) =>
      dispatch(transaction.getFree(_promise, _props))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDetail);
