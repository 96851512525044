import React, { Component } from 'react';
import Layout from '../containers/Layout';
import FooterAD from '../components/FooterAD';
import * as common_util from '../utils/common';
import * as config from '../config/config';

class TermsOfService extends Component {
  componentDidMount = async () => {
    try {
      // 初期遷移
      // wallet接続未実施の場合は接続
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet();
      }

      if (this.props.wallet.isLinked) {
        await this.props.initBaseLogicOfDidMount(
          this.props,
          this.props.wallet.account
        );
      }
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  };

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            {this.switchLang()}
           <FooterAD />
          </div>
        </div>
      </Layout>
    );
  }

  switchLang(){
    switch(this.props.common.lang) {
      case config.LANG_ENGLISH:
        return this.renderEN();
      case config.LANG_JAPANESE:
        return this.renderJP();
      default:
        return this.renderEN();
    }
  }

  renderEN() {
    return (
    <section className="termofservice">
      <h1>Terms of service</h1>

      <h5>These Terms of Service (hereinafter referred to as the "TOS") set forth the terms and conditions that apply when the customer (hereinafter referred to as the "User") uses the dAppsJinja (hereinafter referred to as the "Service") offered by CryptoLab CO., LTD. ("CryptoLab").
      </h5>
      <h2>Section 1. Terms of Service</h2>
      1.	By using the Service, the User agrees to be bound by all terms and conditions set forth in the TOS.<br />
      <br />
      2.	The Service is delivered via a web browser, and it may also be delivered through other smartphone apps or in other formats. The User's consent to the TOS in any of these delivery formats for the Service is valid when the Service is used through any other delivery format.<br />
      <br />
      <h2>Section 2. Use by Minors</h2>
      1.	When a minor uses the Service, the consent of a parent or legal guardian (hereinafter referred to as the "Parent") is required for any and all conduct (including giving consent to the TOS) related to use of the Service, including the purchase of items for a fee, transactions requiring the payment of a fee, and any other services provided for a fee (hereinafter collectively referred to as the "Paid Services").<br />
      <br />

      2.	CryptoLab may set a maximum monthly monetary usage amount for minors using the Service. If we do so, the minor may purchase the Paid Services only within this limit.<br />
      <br />

      3.	If a User who was a minor uses the Service after reaching the age of adulthood, the User will be deemed to have affirmed all conduct associated with the use of the Service while that user was a minor.<br />
      <br />

      <h2>Section 3. Wallet</h2>
      1.	When the User uses the Service via a web browser, they shall use the browser designated by CryptoLab and the feature for keeping virtual currency (including, but not limited to, virtual currency as defined in the Payment Services Act) (hereinafter referred to as the "Wallet").<br />
      <br />
      2.	When the User uses the Service via the smartphone app, they shall use the Wallet feature in the app or some other Wallet designated by CryptoLab.<br />
      <br />

      3.	The virtual currency in the Wallet shall be administered by using storage space on the User's own device. CryptoLab does not offer any means for online administration.<br />
      <br />

      4.	Deposits into the Wallet and withdrawals from the Wallet are made at times and using methods prescribed by CryptoLab.<br />
      <br />

      <h2>Section 4. User Data Control</h2>
      1.	The User shall not cause a third party to use their device, Account, private key, or password (hereinafter collectively referred to as the "User Data"), nor share these with a third party. CryptoLab does not control the User's device or private key.<br />
      <br />

      2.	In using the Service, the User shall be responsible for controlling their own User Data, and the User shall be solely liable for any acts committed while using their User Data.<br />
      <br />

      3.	CryptoLab shall deem any acts committed while using the User's Account or private key to be acts committed by (i) the User to whom that Account has been given or (ii) the User who controls the private key, regardless of who was actually the individual using these at the time.<br />
      <br />

      4.	Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for any damages (including the loss of or inability to use the virtual currency in the User's Wallet) suffered by the User in the event of the malfunction or loss of device used by the User; a third party malware, spyware, virus, hacking/cracking attack; inadequate control or erroneous use of the User Data; or use of the User Data by a third party.<br />
      <br />

      5.	When there is a risk that the User's User Data may be improperly used by a third party, the User shall, along with promptly notifying CryptoLab, take necessary measures to avert such improper use.<br />
      <br />

      6.	CryptoLab shall not be liable for any harm to the User that arises when the User has lost the User Data or has forgotten the User Data information (including the loss of virtual currency in the Wallet or any other state attained in the Service (hereinafter collectively referred to as the "Usage Data") and the loss of blockchain data associated with the Service).<br />
      <br />

      7.	The User acknowledges that it will be impossible for the User to use the Wallet if they are missing either their device, the private key, or password, and that it will be impossible for CryptoLab to render any remedy with respect to that Wallet.<br />
      <br />

      <h2>Section 5. Costs</h2>
      1.	The User shall be responsible for the cost of purchasing, installing, and maintaining any equipment or software, telecom charges (including data usage charges), electricity costs, or any other expenses necessary for using the Service.<br />
      <br />

      2.	CryptoLab may indicate a recommended user environment for the Service, but is not responsible for the setup or costs of the environment.<br />
      <br />

      <h2>Section 6. Ownership in Intellectual Property Rights</h2>
      1.	All copyrights, patents, trademarks, or other intellectual property rights associated with the Service (hereinafter referred to as the "Intellectual Property Rights") shall be owned by CryptoLab or third parties that have lawful rights to the same.<br />
      <br />

      2.	The User shall not, without CryptoLab's consent, reproduce, adapt, or publicly transmit any of the data provided by CryptoLab through the Service, or use the Service in a way that infringes on CryptoLab's Intellectual Property Rights; provided, however, that this does not apply when the purpose is for personal use.<br />
      <br />

      <h2>Section 7. Usage Data</h2>
      1.	The User shall have no ownership rights, Intellectual Property Rights, or any other rights in any sense with respect to the Usage Data, and shall be entitled to use the Service only to the extent permitted under the TOS.<br />
      <br />

      2.	CryptoLab may delete, move, or otherwise modify some or all of the Usage Data anytime without prior notice to the User if:<br />
      (1)	the content of the Usage Data violates the TOS;<br />
      (2)	it has become difficult for CryptoLab to retain the Usage Data because the size of the Usage Data may exceed the limit separately specified by CryptoLab or for some other technical reason;<br />
      (3)	CryptoLab has determined that such action is necessary in order to provide, maintain or manage the Service;<br />
      (4)	CryptoLab has determined that it will interfere with the seamless delivery of the Service; or<br />
      (5)	CryptoLab has otherwise determined that such action is necessary.<br />
      <br />

      <h2>Section 8. Asset</h2>
      1. In the Service, "Asset" refers to information that the User can hold on the Service.<br />
      <br />

      2. The methods and conditions to be met in order for the User to obtain an Asset are established through the Service.<br />
      <br />

      3. The User may assign an Asset to another user using the method prescribed by CryptoLab. We confirm that the copyright and other intellectual property rights are not assigned to other users.<br />
      <br />

      <h2>Section 9. Obtaining and Using Data</h2>
      1.	CryptoLab may obtain the types of data listed below (hereinafter referred to as the "User Data") through the User's use of the Service.<br />
      (1) Nickname, avatar, age, gender, area of residence, e-mail address;<br />
      (2) Device data (e.g. device ID such as UDID hash value, device used, MAC address, OS, device language settings, country of access);<br />
      (3) Wallet address and other data necessary for blockchain transactions;<br />
      (4) Service usage information (e.g. Service version, location data, usage history);<br />
      (5) Information on campaigns that were used; and<br />
      (6) Information about inquiries and troubleshooting, and information for research regarding the User.<br />
      <br />

      2.	When the Uploaded Data and the User Data (hereinafter collectively referred to as the "Data") are considered personal information as defined in the Act on the Protection of Personal Information, such personal information will be handled properly in accordance with the TOS.<br />
      <br />

      3.	CryptoLab uses the Data for the purposes listed below:<br />
      (1)	To verify identity and to send notices (in addition to notices about the Service, this includes providing information about other services, including those offered by CryptoLab);<br />
      (2)	For after-sales service and inquiries;<br />
      (3)	To provide ads for third-party products and services;<br />
      (4)	To conduct surveys, competitions, and campaigns;<br />
      (5)	To analyze user tendencies and to provide suitable services, ads, and other information;<br />
      (6)	To prepare statistical data on Service usage, and to publish this on the Service or on CryptoLab's website or provide it to third parties; and<br />
      (7)	To investigate when there is a suspected violation of the applicable laws and regulations or the TOS, or to otherwise conduct investigations for the purpose of securing CryptoLab's interests.<br />
      <br />

      4.	Except as permitted by the Act on the Protection of Personal Information or other applicable laws and regulations, CryptoLab shall not disclose the Data to any third party without User consent.<br />
      <br />

      5.	CryptoLab may, at its own discretion, delete all of the Data when CryptoLab has deleted the User's Account or has stopped offering the Service.<br />
      <br />

      <h2>Section 10. Use Cookies</h2>
      This service may use cookies for the purpose of ensuring security and providing appropriate information to the user when the user accesses the service.<br />
      In addition, users can refuse to accept cookies by changing the settings of their browser, but if refused, some services of this service may not be available. Please note.<br />
      <br />

      <h2>Section 11. Prohibited Conduct</h2>
      The User shall not use the Service to engage in the following acts, or any other act that may possibly be categorized as such acts:<br />

      (1) Acts that violate any applicable laws and regulations, public policy, or the TOS;<br />
      (2) Criminal acts, conduct that leads to criminal acts, or conduct that promotes such acts;<br />
      (3) Posting information that is harmful to adolescents (as defined in Article 2, Paragraph 3 of the Act on Development of an Environment that Provides Safe and Secure Internet Use for Young People), or luring children to become a partner in sexual acts;<br />
      (4) Sending or storing text or images with child pornography or other content that would make other users uncomfortable;<br />
      (5) Engaging in any conduct for the purpose of having intercourse with others or an obscene act or encounter;<br />
      (6) Sending or storing data about dating with unknown others;<br />
      (7) Using false information to register as a user, or otherwise providing or disseminating untruthful information to CryptoLab or third parties through the Service;<br />
      (8) Using or selling or purchasing drugs, especially contraband such as stimulants or narcotics, or recommending or encouraging minors to drink, smoke, or gamble;<br />
      (9) Encouraging or recommending suicide or self-harm;<br />
      (10) Posting content that libels, defames, or insults a third party or content that harms the reputation or credibility of a third party, or other harassing conduct;<br />
      (11) Posting expressions that lead to discrimination based on race, ethnicity, gender, creed, social status, place of residence, physical characteristics, medical history, education, or wealth;<br />
      (12) Commercial activities outside of the Service, or preparing for such activities by using the Service or data provided through the Service (including selling, or offering to sell, asset or private keys outside the Service, irrespective of the type of consideration);<br />
      (13) Encouraging involvement in political groups or religious organizations, or otherwise engaging in political or religious activity;<br />
      (14) Improperly collecting or using other users' personal information;<br />
      (15) Infringing on the Intellectual Property Rights and Moral Rights of CryptoLab or a third party;<br />
      (16) Creating, distributing, or using an outside program such as a bot or cheat tool (including, but not limited to, outside tools to manipulate the results from using the Service in ways not intended by CryptoLab);<br />
      (17) Using the results obtained by a third party from using the type of outside program described above for oneself;<br />
      (18) Improperly manipulating the results obtained from using the Service by oneself or in collaboration with another user, or by taking advantage of another user's actions;<br />
      (19) Putting a significant burden on the servers or networks used to run the Service;<br />
      (20) Engaging in any unauthorized access;<br />
      (21) Deliberately providing or disclosing information about deficiencies in the Service to a third party other than CryptoLab;<br />
      (22) Posing as CryptoLab, another user, or another individual or organization associated with the Service, or otherwise somehow misleading a third party about one's association with the Service;<br />
      (23) Distributing computer viruses;<br />
      (24) Modifying, damaging, disassembling, decompiling, or reverse engineering the program used in the Service;<br />
      (25) Modifying, damaging, disassembling, decompiling, or reverse engineering the OS installed on the device (including rooting or jailbreaking a device);<br />
      (26) Harming or otherwise somehow inconveniencing CryptoLab or a third party;<br />
      (27) Interfering with the normal delivery of the Service;<br />
      (28) Damaging the credibility of CryptoLab or the Service; or<br />
      (29) Any other act that CryptoLab determines to be improper;<br />
      <br />

      <h2>Section 12. Restriction, Suspension, or Cancellation</h2>
      CryptoLab may refuse to allow the User to begin using the Service, suspend or restrict the User's use of the Service, or delete the User's Usage Data or Account if:<br />

      (1) the User has violated the TOS, including engaging in the prohibited conduct listed in the preceding section, or CryptoLab determines there is a likelihood of such situation;<br />
      (2) the User has engaged in conduct (including acts or omissions outside of the Service) that has interrupted or interfered with the provision of the Service, or CryptoLab determines there is a likelihood of such situation;<br />
      (3) the User has used the Service in a way other than that specified by CryptoLab, or some other improper way;<br />
      (4) the User has been sanctioned by CryptoLab in the past under this section; or<br />
      (5) CryptoLab otherwise determines that the User would be inappropriate as a user of the Service.<br />
      <br />

      <h2>Section 13. Changing, Suspending, or Terminating the Service</h2>
      1.	CryptoLab may, without prior notice to the User and at its discretion and for any reason, in whole or in part, change, suspend or terminate provision of the Service at any time.<br />
      <br />

      2.	CryptoLab may, without prior notice to the User, suspend or terminate delivery of the Service temporarily or for an extended period if:<br />
      <br />
      (1) it becomes impossible to provide the Service due to an earthquake, tsunami, typhoon, lightning, heavy rains, flooding or other natural disaster; or due to a fire, power outage or other unforeseen event; or due to war, unrest, riots, civil disturbance, or a labor dispute;<br />
      (2) it becomes impossible to provide the Service due to scheduled or emergency maintenance on the systems necessary to deliver the Service; due to network congestion; or due to a disruption at a service provider; or<br />
      (3) other than as listed above, CryptoLab determines that it is necessary to suspend or terminate provision of the Service for business or technical reasons.<br />
      <br />

      3.	CryptoLab shall not be liable for any damages suffered by the User as a result of any change, suspension or termination of the Service pursuant to this section.<br />
      <br />

      <h2>Section 14. Limitation of Liability</h2>
      1.	The User acknowledges that the service and information provided in the Service are by nature updated on a daily basis. CryptoLab does not warrant that the existence and content of such service and information are perpetual.<br />
      <br />

      2.	Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for any damage suffered by the User or any third party as a result of the use of or inability to use the Service (including the extinction of virtual currency in the Wallet, or any information necessary for the use of the virtual currency).<br />
      <br />

      3.	CryptoLab does not warrant the completeness, accuracy, adequacy, usefulness, timeliness, legality of the Service or the information provided by the Service, or the conformity of the same with the use purpose and environment of the User. Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for any damage arising from the User's use of the Service or such information, or inability by the User to use the same.<br />
      <br />

      4.	As to any and all websites of third parties that may be accessed through the link from the Service, CryptoLab does not warrant the the legality, integrity, safety, or accuracy of the contents, etc. of such websites, or that such contents, etc. are not offensive to public policy. Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for any loss or damage arising from the User's use of such websites, or products or services provided through such use.<br />
      <br />

      5.	CryptoLab does not warrant that the Service does not infringe the rights of any third party.<br />
      <br />

      6.	Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be responsible for any dispute between the Users or between the User and third party that arises from the use of the Service.<br />
      <br />

      7.	Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for any damage suffered by the User arising from any unauthorized access to the Service, invasion of computer viruses, or any conduct of third parties.<br />
      <br />

      8.	Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for any damage suffered by the User arising from telecommunication providers, electric power suppliers or other service providers.<br />
      <br />

      9.	Except in cases of willful misconduct or gross negligence on the part of CryptoLab, CryptoLab shall not be liable for the deletion or loss of information relating to the Service, loss of data due to the use of the Service, any trouble or damage in or to any equipment or other damage suffered by the User in connection with the Service.<br />
      <br />

      <h2>Section 15. Liability for Damages</h2>
      1.	In the event that the User inflicts any damage to CryptoLab as a result of violation of any provision set forth in the TOS, or otherwise due to reasons attributable to the User, the User shall be liable for the damages suffered by CryptoLab (including reasonable attorney's fees).<br />
      <br />

      2.	Even in the event that CryptoLab is held liable for any damage suffered by the User pursuant to the Consumer Contract Act, or other applicable laws and regulations, the provision that limits the liability of CryptoLab notwithstanding, CryptoLab's liability shall be limited to the the amount of the price (or other economical value) actually received from the relevant User during the period of one month immediately preceding the occurrence of the event that directly caused the damage. The above limitation does not apply if there is any willful misconduct or gross negligence on the part of CryptoLab. However, even in such case, CryptoLab's liability for damages shall be limited to the damages directly suffered by the User, and such damages shall not include consequential damages, incidental damages or indirect damages such as loss of profit, regardless of whether or not that was actually foreseen by CryptoLab or was foreseeable.<br />
      <br />

      <h2>Section 16. No Transfer of Rights and Obligations</h2>
      The User may not assign, change the name, pledge, or offer as collateral to a third party the status or rights and obligations that they have as a user under the TOS, in whole or in part, or otherwise dispose thereof.<br />
      <br />

      <h2>Section 17. Amending the TOS</h2>
      1.	CryptoLab may, at its own discretion, amend the TOS at any time without prior notice to the User.<br />
      <br />

      2.	Except when otherwise specified by CryptoLab, the TOS amended pursuant to the preceding paragraph shall become valid at the time that CryptoLab provides notice of the revisions via the Service.<br />
      <br />

      <h2>Section 18. Validity of the TOS</h2>
      1.	Even if any of the provisions of the TOS are deemed invalid pursuant to law/regulation, this will have no effect on the validity of the other provisions.<br />
      <br />

      2.	Even if any of the provisions of the TOS are deemed invalid or canceled in relation to a particular User, this will have no effect on the validity of the provisions in relation to other Users.<br />
      <br />

      <h2>Section 19. Governing Law and Jurisdiction</h2>
      1.	The TOS shall be governed by and interpreted in accordance with the laws of Japan.<br />
      <br />

      2.	The Tokyo District Court shall have exclusive jurisdiction as the court of first instance with respect to any dispute pertaining to the Service.<br />
      <br />

      Oct 1st, 2019<br />
      CryptoLab CO., LTD<br />

      <br />
    </section>
    )
  }

  renderJP() {
    return (
      <section className="termofservice">
      <h1>利用規約</h1>

      <h5>この利用規約（以下「本利用規約」といいます。）は、株式会社CryptoLab（以下「当社」といいます。）が提供するdApps神社（以下「本サービス」といいます。）をお客さま（以下「ユーザー」といいます。）が利用する際の条件を定めるものです。</h5>
      <h2>第1条（利用条件）</h2>
      1.	ユーザーは、本利用規約の全ての内容に同意することを条件として、本サービスを利用するものとします。
      <br /><br />2.	本サービスは、ウェブブラウザ経由で提供されるほか、スマートフォンアプリその他の形態により提供されることがあります。本サービスの提供形態のうち一つについてユーザーが行った本利用規約への同意は、他の提供形態を通じた本サービスの利用についても有効であるものとします。

      <h2>第2条（未成年者による利用）</h2>
      1.	未成年者が本サービスを利用する場合、有料で販売されるアイテム、手数料を要する取引その他有償で提供される一切のサービス（以下「有償サービス」と総称します。）の購入及び支払いを含む、本サービスの利用に係る一切の行為（本規約への同意を含みます。）について、親権者その他の法定代理人（以下「親権者等」といいます。）の同意を要するものとします。
      <br /><br />2.	当社は、未成年者による本サービスの利用について、月額利用上限金額を定めることがあります。この場合、未成年者はこの範囲内でのみ、有償サービスを購入できるものとします。
      <br /><br />3.	未成年者であったユーザーが、成年に達した後に本サービスを利用した場合、当該ユーザーが未成年者であった間になした本サービスの利用に係る一切の行為を追認したものとみなされます。
      <br />

      <h2>第3条（ウォレット）</h2>
      1.	ユーザーは、本サービスをウェブブラウザ経由で利用する場合、当社の指定するブラウザ及び暗号資産（資金決済法上の仮想通貨を含みますが、これに限りません。）の保管機能（以下「ウォレット」といいます。）及び、アカウント情報を利用するものとします。
      <br /><br />2.	ユーザーは、本サービスをスマートフォンアプリ経由で利用する場合、同アプリ中のウォレット機能及び、アカウント情報を利用するものとします。
      <br /><br />3.	ウォレットにおける仮想通貨の管理は、ユーザー自身の端末機器上の記憶領域を利用して行われます。当社は、オンライン上での管理手段を提供しません。
      <br /><br />4.	ウォレットへの入金及びウォレットからの出金は、当社所定の時期及び方法により行われるものとします。<br />

      <h2>第4条（ユーザー情報等の管理）</h2>
      1.	ユーザーは、自身の端末機器、アカウント、秘密鍵及びパスワード（以下「ユーザー情報等」と総称します。）を第三者に使用させ、又は第三者と共有してはならないものとします。当社は、ユーザーの端末機器及び秘密鍵を管理しません。<br />

      <br />2.	ユーザーは、本サービスの利用にあたり、自身のユーザー情報等を自らの責任において管理するものとし、自身のユーザー情報等を使用してなされた行為について、一切の責任を負うものとします。<br />
      <br />

      3.	当社は、ユーザー自身のアカウント又は秘密鍵を使用してなされた行為について、その実際の利用者が誰であるかを問わず、すべて、当該アカウントが付与され又は当該秘密鍵を管理するユーザーによる行為とみなします。<br />
      <br />

      4.	当社は、ユーザーの使用する端末機器の故障、紛失、第三者からのマルウェア、スパイウェア、ウィルス、ハッキング・クラッキング等による攻撃、ユーザー情報等の管理不備及び使用上の過誤、又は第三者によるユーザー情報等の使用等により、ユーザーがウォレット内の仮想通貨の喪失、使用不能を含む損害を蒙った場合であっても、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      5.	ユーザーは、自身のユーザー情報等が第三者により不正に利用されるおそれがある場合、速やかに当社に通知するとともに、当該不正利用を回避するため必要な措置をとるものとします。<br />
      <br />

      6.	当社は、ユーザーが自身のユーザー情報等を紛失又は失念したことにより、ユーザーに生じうる不利益（ウォレット内の仮想通貨その他本サービスにおいて実現される一切の状態（以下「利用データ等」と総称します。）及び本サービスに関連するブロックチェーン上の情報が失われることを含みます。）について、一切の責任を負いません。<br />
      <br />

      7.	ユーザーは、ユーザーの端末機器、秘密鍵及びパスワードの一つでも欠けた場合、ウォレットを使用することが不可能となること、並びに、当社が当該ウォレットに関して何らかの救済を行うことが不可能であることを予め了解するものとします。
      <br />

      <h2>第5条（費用の負担）</h2>
      1.	本サービスの利用にあたって必要な機器・ソフトウェアの購入・導入・維持の費用、データ利用料金等の通信料金、電気料金その他一切の費用は、ユーザーが自己の責任において支払うものとします。<br />
      <br />

      2.	当社は、本サービスの利用環境に関し推奨環境を提示することはありますが、当該環境の整備及び費用を一切負担しません。<br />
      <br />

      <h2>第6条（知的財産権の帰属）</h2>
      1.	本サービスに関する一切の著作権、特許権、商標権その他の財産的権利（以下「知的財産権等」といいます。）は、当社又はこれについて正当な権限を有する第三者に帰属します。<br />
      <br />

      2.	ユーザーは、当社が本サービスにおいて提供した一切の情報について、当社の同意なく、複製し、改変し、公衆送信し、その他当社の知的財産権等を侵害することとなる利用をしてはならないものとします。ただし、私的使用の目的による場合は、この限りではありません。
      <br />

      <h2>第7条（利用データ等の取扱い）</h2>
      1.	ユーザーは、利用データ等について、所有権、知的財産権等その他いかなる意味においても権利を有しないものとし、本利用規約において認められる限りにおいて、本サービスを利用できるものとします<br />
      <br />

      2.	当社は、次の各号のいずれかに該当する場合、ユーザーに対し事前に通知することなく、いつでも、利用データ等の一部又は全部を削除し、移動しその他変更することができるものとします。
      <br />　(1)	利用データ等の内容が、本利用規約に違反するものである場合
      <br />　(2)	利用データ等のデータサイズが、当社が別途定める制限を超過するおそれがあることその他技術的理由により、当社において利用データ等の維持が困難となった場合
      <br />　(3)	当社が、本サービスの提供及びその保守管理上必要と判断した場合
      <br />　(4)	当社が、本サービスの円滑な提供の妨げになると判断した場合
      <br />　(5)	その他、当社が必要と判断した場合<br />
      <br />

      <h2>第8条（アセット）</h2>
      1. 本サービスにおいて「アセット」とは、ユーザーが本サービス上で保有可能な情報であって、本サービス上で定義されるものをいいます。<br />
      <br />

      2. ユーザーが、アセットを入手する方法及び条件は、本サービス上で定めます。<br />
      <br />

      3. ユーザーは、アセットを他のユーザーに対し、当社所定の方法により譲渡することができます。なお他のユーザーに著作権その他の知的財産権が譲渡されるものでないことを確認します。<br />
      <br />

      <h2>第9条（情報の取得及び利用）</h2>
      1.	当社は、ユーザーによる本サービスの利用を通して、以下に掲げる情報（以下「ユーザーデータ」といいます。）を取得することがあります。<br />
      　(1)	生年月日、性別、居住地域、メールアドレス<br />
      　(2)	端末情報（UDIDのハッシュ値等の端末ID、使用デバイス、MACアドレス、OS、端末言語設定、アクセス国など）<br />
      　(3)	ウォレットのアドレスその他ブロックチェーン上のトランザクションに必要な情報<br />
      　(4)	本サービスの利用状況（本サービスのバージョン、位置情報、利用履歴など）<br />
      　(5)	利用したキャンペーンに関する情報<br />
      　(6)	お問い合わせ、トラブルの原因究明、利用者データの調査等に関する情報<br />
      <br />

      2.	当社は、投稿データ及びユーザーデータ（以下「本データ」と総称します。）が個人情報保護法上の個人情報に該当する場合、本利用規約に基づき、当該個人情報を適切に取り扱うものとします。<br />
      <br />

      3.	当社は、本データを以下に掲げる目的で利用します。<br />
      　(1)	ご本人確認やお知らせ（本サービスに関するお知らせのほか、当社が提供するものを含む他のサービスに関する情報の提供を含みます。）等の配信<br />
      　(2)	アフターサービスや問合せ<br />
      　(3)	第三者の商品又はサービスに関する広告の提供<br />
      　(4)	アンケート、懸賞、キャンペーンの実施<br />
      　(5)	ユーザーの傾向等の分析及びそれに合わせたサービス及び広告その他の情報の提供<br />
      　(6)	本サービスの利用状況等に係る統計情報の作成、並びに本サービス又は当社ウェブサイト等における公表及び第三者への提供<br />
      　(7)	法令及び本利用規約への違反の疑いがある場合の調査、その他当社の権利を確保する目的のために行う調査<br />
      <br />

      4.	当社は、個人情報保護法その他関連法令に基づき認められる場合を除き、本データをユーザーの同意なく第三者に開示しません。<br />
      <br />

      5.	当社は、当社がユーザーのアカウントを削除し、又は当社が本サービスの提供を終了したときは、当社の判断により、本データを全て消去することがあります。<br />
      <br />

      <h2>第10条（Cookieの使用）</h2>
      本サービスでは、ユーザーが本サービスにアクセスする際に、セキュリティの確保やユーザーへ適切な情報を提供することを目的に、Cookieを使用することがあります。
      なお、ユーザーは、ご使用のブラウザの設定を変更することにより、Cookieの受取りを拒否することができますが、拒否された場合は、本サービスの一部サービスを利用できなくなる場合がありますので、あらかじめご了承ください。<br />
      <br />

      <h2>第11条（禁止事項）</h2>
      ユーザーは、以下の各号に掲げる行為、又はこれに該当するおそれのある行為をしてはならないものとします。<br />
      　(1)	法令、公序良俗又は本利用規約に違反する行為<br />
      　(2)	犯罪行為、犯罪に結びつく行為若しくはこれを助長する行為<br />
      　(3)	青少年有害情報（青少年が安全に安心してインターネットを利用できる環境の整備等に関する法律第2条第3項が定義するものをいいます。）を投稿する行為、児童を性交等の相手方となるように誘引する行為<br />
      　(4)	児童ポルノ等、その他のユーザーが不快に感じる文章又は画像等を送信し又は保存する行為<br />
      　(5)	性交、わいせつな行為若しくは出会い等を目的とする一切の行為<br />
      　(6)	面識のない者との交際に関する情報を送信し又は保存する行為<br />
      　(7)	虚偽の情報を申告してユーザー登録をする行為、その他本サービスにおいて当社又は第三者に対して事実に反する情報を提供し又は流布する行為<br />
      　(8)	覚せい剤、麻薬等の禁制品を始めとする薬物等の使用若しくは売買、未成年者に対する飲酒、喫煙、賭博等を推奨・勧誘する行為<br />
      　(9)	自殺又は自傷を勧誘・推奨する行為<br />
      　(10)	第三者を誹謗、中傷若しくは侮辱する内容、又は第三者の名誉若しくは信用を傷つける内容を掲載する行為その他の嫌がらせ行為<br />
      　(11)	人種、民族、性別、信条、社会的身分、居住地、身体的特徴、病歴、教育、財産等の差別につながる表現を掲載する行為<br />
      　(12)	本サービス又は本サービス上で提供される情報を用いた、本サービス外での営利行為又はその準備行為（本サービス外での、対価の種類を問わない、アセット又は秘密鍵の販売又は販売申出を含みます。）<br />
      　(13)	政治団体若しくは宗教団体への勧誘行為その他政治的又は宗教的な行為<br />
      　(14)	他のユーザーの個人情報を不当に収集し利用する行為<br />
      　(15)	当社又は第三者の知的財産権及び人格権等を侵害する行為<br />
      　(16)	BOT、チートツール等の外部プログラム（本サービスの利用により得られる結果を、当社の意図しない形で操作する外部ツールを含みますが、それに限られません。）を作成し、頒布し、利用する行為<br />
      　(17)	前号の外部プログラムの利用により第三者が得た結果を、自らのために利用する行為<br />
      　(18)	単独又は他のユーザーと共謀して、又は他のユーザーの行為を利用することにより、本サービスの利用により得られる結果を不正に操作する行為<br />
      　(19)	本サービスを運営するためのサーバー又はネットワークに著しく負荷をかける行為<br />
      　(20)	不正アクセス行為<br />
      　(21)	本サービスの不具合に関する情報を、当社以外の第三者に対して故意に提供又は開示する行為<br />
      　(22)	当社、他のユーザー、又は本サービスに関連する人物若しくは団体等であるように振舞い、その他本サービスとの関連性について第三者に何らかの誤解を生ぜしめる行為<br />
      　(23)	コンピューターウイルスを頒布する行為<br />
      　(24)	本サービスに用いられるプログラムを改変し、毀損し、又は逆アセンブルし、逆コンパイルし、リバースエンジニアリングする行為<br />
      　(25)	端末機器に搭載のOSを改変し、毀損し、又は逆アセンブルし、逆コンパイルし、リバースエンジニアリングする（いわゆる「root化」及び「ジェイルブレイク」を含みます。）行為<br />
      　(26)	当社又は第三者に対して損害を与え、その他何らかの迷惑を生ぜしめる行為<br />
      　(27)	本サービスの正常な提供を妨げる行為<br />
      　(28)	当社又は本サービスの信用を損なう行為<br />
      　(29)	その他、当社が不適切と判断する行為<br />
      <br />

      <h2>第12条（利用の制限・停止又は削除）</h2>
      当社は、ユーザーの行為が次の各号のいずれかに該当する場合、当該ユーザーによる本サービスの利用開始を拒否し、当該ユーザーによる本サービスの利用を停止若しくは制限し、又は当該ユーザーの利用データ等若しくはアカウントを削除できるものとします。<br />
      　(1)	前条の禁止事項を含み、本利用規約に違反した場合、又はそのおそれがあると当社が判断した場合<br />
      　(2)	ユーザーの行為（本サービス外における作為又は不作為を含む。）によって、本サービスの提供に支障が生じ、若しくは妨害された場合、又はそのおそれがあると当社が判断した場合<br />
      　(3)	当社所定の方法によらない方法、その他不正な方法により本サービスを利用した場合<br />
      　(4)	過去に当社から本条に基づく措置を受けたユーザーである場合<br />
      　(5)	その他、当社が本サービスのユーザーとして不適切と判断した場合<br />
      <br />

      <h2>第13条（本サービスの変更、中止、終了）</h2>
      1.	当社は、ユーザーに対し事前に通知することなく、いつでも、任意の理由により、本サービスの全部又は一部について、その内容を変更し、又は本サービスの提供を中止し若しくは終了することができます。<br />
      <br />

      2.	当社は、以下のいずれかの事由が生じた場合、ユーザーに対し事前に通知することなく、一時的又は長期にわたって、本サービスの提供を中止し又は終了することがあります。<br />
      　(1)	地震、津波、台風、雷、大雨、洪水等の自然災害、火災、停電その他の不慮の事故、戦争、争議、動乱、暴動、騒乱、労働争議等により本サービスの提供ができなくなった場合。<br />
      　(2)	本サービスの提供のため必要なシステム等の定期的な又は緊急のメンテナンス、ネットワーク回線の混雑、プロバイダー等の障害により本サービスが提供できなくなった場合。<br />
      　(3)	前号に掲げるほか、事業上又は技術上の理由により、当社が本サービスの提供の中止又は終了が必要であると判断した場合。<br />
      <br />

      3.	当社は、本条に基づく本サービスの変更、中止又は終了により、ユーザーに損害が生じた場合であっても、一切の責任を負わないものとします。<br />

      <h2>第14条（免責事項）</h2>
      1.	ユーザーは、本サービスにおいて提供されるサービス及び情報は、日々更新されうる性質のものであることを了解するものとします。当社は、そのサービス及び情報の存在及び内容の永続性を保証しません。<br />
      <br />

      2.	当社は、本サービスの利用又は利用不能によってユーザー又は第三者に生じるあらゆる損害（ウォレット中の仮想通貨又は仮想通貨を使用するために必要な情報の消失を含みます。）について、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      3.	当社は、本サービス及び本サービスにより提供される情報の完全性、確実性、妥当性、有用性、最新性、適法性又はユーザーの利用目的及び環境との適合性を保証しません。当社は、ユーザーが本サービス及び当該情報を利用し、又は利用できなかったことによって生じる損害について、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      4.	当社は、本サービスからのリンクにより遷移可能な一切の第三者ウェブサイトについて、その内容等の適法性、整合性、安全性、正確性、公序良俗に反しないことその他一切の保証をしません。当社は、ユーザーによる当該ウェブサイトの利用、及び当該利用を通して提供された商品又はサービス等に起因する損害や損失について、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      5.	当社は、本サービスがいかなる第三者の権利も侵害していないことを保証しません。<br />
      <br />

      6.	当社は、本サービスの利用に起因して生じたユーザー間又はユーザーと第三者との間の紛争に関し、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      7.	当社は、本サービスに対する不正アクセス、コンピューターウイルスの侵入等、第三者の行為に起因してユーザーに生じる損害について、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      8.	当社は、電気通信事業者、電気事業者その他の事業者に起因してユーザーに生じる損害について、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      9.	当社は、本サービスに関連する情報の削除又は消失、本サービスの利用によるデータの消失、機器の故障又は損傷その他本サービスに関連してユーザーが被った損害につき、当社に故意又は重過失ある場合を除き、一切の責任を負いません。<br />
      <br />

      <h2>第15条（損害賠償）</h2>
      1.	ユーザーは、本利用規約に定める条項に違反し、又は自己の責めに帰すべき事由により当社に損害を与えた場合、当社に対し、当社が蒙った損害（合理的な弁護士費用を含みます。）を賠償するものとします。
      <br /><br />2.	当社を免責する規定にも拘わらず、消費者契約法その他の適用法令に基づき当社がユーザーに対して損害賠償責任を負う場合であっても、当社の責任は、損害の原因となった事由が生じた時点から遡って1か月の間に当該ユーザーから現実に受領した代金その他経済的価値の総額を上限とします。当社に故意又は重過失がある場合はこの限りではありませんが、その場合も、当社の損害賠償責任は当該ユーザーが直接被った損害に限られるものとし、結果的損害、付随的損害、逸失利益等の間接損害は、当社の予見または予見可能性の有無にかかわらず一切含まないものとします。
      <br />
      <h2>第16条（権利義務の移転禁止）</h2>
      ユーザーは、本利用規約に関して有するユーザーとしての地位、又は権利若しくは義務の全部若しくは一部について、第三者に対して譲渡し、名義を変更し、質入れし、担保に供しその他一切の処分をしてはならないものとします。<br />
      <br />

      <h2>第17条（本利用規約の改定）</h2>
      1.	当社は、ユーザーに対し事前に通知することなく、いつでも、任意の理由により、本利用規約を改定できるものとします。<br />
      <br />

      2.	前項に基づき改定された本利用規約は、当社が別途定める場合を除き、当社がその内容を本サービス上で告知した時点より、その効力を生じるものとします。<br />
      <br />

      <h2>第18条（本利用規約の有効性）</h2>
      1.	本利用規約のうち、一部の規定が法令等に基づいて無効と判断された場合であっても、その他の規定の有効性には影響を及ぼさないものとします。<br />
      <br />

      2.	本利用規約のうち、一部の規定があるユーザーとの関係で無効と判断され、又は取り消された場合であっても、その他のユーザーとの間の有効性には影響を及ぼさないものとします。<br />
      <br />

      <h2>第19条（準拠法及び裁判管轄）</h2>
      1.	本利用規約は、日本法に準拠し、日本法に従って解釈されるものとします。<br />
      <br />

      2.	本サービスに関する一切の紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br />
      <br />

      <br />2019年10月1日 制定
      <br />株式会社CryptoLab
      <br />

      <br />
    </section>
    )
  }
}

export default TermsOfService;
