const en_US = {
  // header
  'header.ticket.text': 'Ticket',
  // AssetSales
  'assetSales.header': 'Asset List',
  'assetSales.button': 'More',
  // Fortune
  'fortune.param.error':
    'The date and gender settings are incorrect. Please check again.',
  'fortune.fortune.button': "Today's Fortune",
  'fortune.fortune.title': 'Fortune',
  'fortune.fortune.text.1':
    'This fortune-telling based on The Ommyo-gogyo-setsu theory.<br />The Ommyo-gogyo-setsu is based on the theory that combines the theory of Ommyo (the Yin and Yang), which considers that the universe has been derived from the Yin and Yang, and the theory of Gogyo-shiso, which considers that the universe consists of the five elements of wood, fire, earth, metal, and water.<br /><br /></p>',
  'fortune.fortune.text.2':
    '<p>12 fortune stars are based on your birthday<br>You can make 60 combinations by putting the 十干 and 十二支, which combine the five-line theory and the Yin-Yang theory, into the traditional calendar in order.And these 60 combinations are divided into 6 groups and 12 stars. <br><br><strong>土星人+(Saturn+)、土星人-(Saturn-)、金星人+(Venus+)、<br class="pc">金星人-(Venus-)、火星人+(Mars+)、火星人-(Mars-)、<br class="pc">準星人+(Moon+)、準星人-(Moon-)、木星人+(Jupiter+)、<br class="pc">木星人-(Jupiter-)、水星人+(Mercury+)、水星人-(Mercury-)</strong><br><br>There is a rhythm in fortune power. The rhythm circulates in 12 elements.<br><br><strong>結実(Fruit)、禄財(Wealth)、収穫(Harvest)、<br class="pc">危険(Risk)、沖殺(Inactive)、忍耐(Patience)、<br class="pc">実行(Execution)、成長(Growth)、決断(Decision)、<br class="pc">疲労(Fatigue)、制覇(Conquest)、油断(Carelessness)</strong><br></p>',
  // FortuneResult
  'fortuneResult.unmai.star.title': '12 fortune stars',
  'fortuneResult.unmai.star.text':
    '12 fortune stars are based on your birthday',
  'fortuneResult.unmai.star.graph':
    '<table class="fortuneTable1"><tr><td>土星人+</td><td>Saturn+</td><td>Space, Stimulation, Mystery, Position, Ideal, Attack, Ambition, Rational, Loneliness, Radio wave</td></tr><tr><td>土星人-</td><td>Saturn-</td><td>Delicate, Inquiring mind, Endurance, Sensitivity, Self-esteem, Relentlessness, Earnest, Dispassionate, Education, Intelligence</td></tr><tr><td>金星人+</td><td>Venus-</td><td>Pioneer, Hope, New, Rush, Reconstruction, Curiosity, Reality, Beliefs, Dawn, Fighting spirit</td></tr><tr><td>金星人-</td><td>Venus-</td><td>Forward, Peace, Freedom, Equality, My pace, Monotony, Information, Indifferent, Liberation, Nimble</td></tr><tr><td>火星人+</td><td>Mars+</td><td>Cleanliness, Nervousness, Braking, Simple, Persist, Vigilance, Past, Analysis, Reason, Family</td></tr><tr><td>火星人-</td><td>Mars-</td><td>Infinite, Record, Stubborn, Responsible, Bold, Slow Starter, Later life, Training, Inheritance</td></tr><tr><td>準星人+</td><td>Moon+</td><td>Understanding, Fulfillment, Tolerance, Honesty, Faithfulness, Humanity, Indecision, Gentle, Unselfish, Horizontal society</td></tr><tr><td>準星人-</td><td>Moon-</td><td>Worry, Kindness, Anxiety, Sympathy, Temper, Panic, Welfare, Humanity, Cooperation, Complaint</td></tr><tr><td>木星人+</td><td>Jupiter+</td><td>Transformation, Regeneration, Imitation, Contemplation, Dexterity, Schemer, Honesty, Plainness, Adviser, Wisdom</td></tr><tr><td>木星人-</td><td>Jupiter-</td><td>Planning, Tough, Varied interests, Brand, Steady, Effort, Sense, Garrulity, Negative, Persevering</td></tr><tr><td>水星人+</td><td>Mercury+</td><td>Cheerful, Active, Competitive, Hospitality, Open minded, Passionate, Frivolous, Gorgeous, Wasted, Irresponsible</td></tr><tr><td>水星人-</td><td>Mercury-</td><td>Smile, Realistic, Mediation, Laziness, Fuzzy, Fictional, Social, Flexible, Enjoyment, Comfortable</td></tr></table>',
  'fortuneResult.unmai.power.title': 'Fortune power',
  'fortuneResult.unmai.power.text':
    'There is a rhythm in fortune power. The rhythm circulates in 12 elements.',
  'fortuneResult.unmai.power.graph':
    "<table class='fortuneTable2'><tr><td>結実</td><td>Fruit</td><td>The time of achievement. If you don't pile up until now, there will be no results.</td></tr><tr><td>禄財</td><td>Wealth</td><td>The best luck period. Things will be fulfilled and you will be blessed with good fortune.</td></tr><tr><td>収穫</td><td>Harvest</td><td>When the peak period has passed and we are preparing for the coming the worst period. </td></tr><tr><td>危険</td><td>Risk</td><td>Unexpected things are likely to happen. The beginning of the worst period.</td></tr><tr><td>沖殺</td><td>Inauspicious</td><td>The worst period. Stay passive and act carefully.</td></tr><tr><td>忍耐</td><td>Patience</td><td>Even if recovery of fortune is seen, it is not time to move yet. Don't rush.</td></tr><tr><td>実行</td><td>Action</td><td>Time for sowing. When to act on your beliefs.</td></tr><tr><td>成長</td><td>Growth</td><td>Time to spread greatly. You should act positively.</td></tr><tr><td>決断</td><td>Decision</td><td>Decision and execution. The decision here will affect the future fate.</td></tr><tr><td>疲労</td><td>Fatigue</td><td>Time for review. Don't bend what you decided in the decision period.</td></tr><tr><td>制覇</td><td>Dominate</td><td>When it is recognized by the surroundings and everything goes well. Full of energy.</td></tr><tr><td>油断</td><td>Carelessness</td><td>Maintaining the status quo is key. Even if you are doing well, don't be alarmed.</td></tr></table>",

  'fortuneResult.fortune.header': 'Fortune',
  'fortuneResult.fortune.symbol': 'Symbol of 12 fortune stars',
  'fortuneResult.fortune.cycle': 'Fortune power',
  'fortuneResult.unmai.star.description': 'Description',
  'fortuneResult.unmai.star.close': 'Close',
  'fortuneResult.asset.message': 'Get your favorite assets!',
  'fortuneResult.no.asset.message':
    'Please wait for a while while preparing assets.',
  'fortuneResult.asset.button': 'Get It !',
  // MyAsset
  'myAsset.header': 'My Asset',
  'myAsset.button': 'More',
  // TermsOfService
  // Top
  // 終了予告
  'service.end.notice':
    '<span style=\"color: blue\">\n<strong>Notice of service termination</strong><br>\nWe regret to inform you that we will terminate the service of \"dApps Jinja\" on the following date and time.<br>\n<strong>Service end date and time 2023/9/1 12:00 UTC+9</strong><br><br>\n◆Notice<br>\nWith the termination of the service, access to the fortune-telling content and purchases of NFTs will no longer be available. Although NFTs already distributed may continue to be owned, the display of images associated with them may be removed.<br>\nPlease note that we cannot provide compensation regarding this matter, so your understanding in advance is greatly appreciated.<br>\nWe apologize for any inconvenience caused to our active users and appreciate your understanding.<br>\n<br>\n</span>',
  'service.end.guidance':
    '<ul class="topCol11ul"><li>\n2023年9月1日を持ちまして「dApps神社」は全サービスを終了いたしました。<br>ご利用頂き誠にありがとうございました。<br><br>◆注意点<br></li>すでに配布されたNFTは引き続き所有することが可能ですが、それに関連付けられた画像の表示が削除される可能性があります。<br>また、本件につきまして如何なる補償も致しかねますので、ご了承のほどよろしくお願いいたします。<br><br><li>As of Sep 1st, 2023, "dApps Shrine" has terminated all its services.<br>Thank you very much for using our services<br><br>◆Points to Note<br>You can continue to own any already distributed NFTs; however, there is a possibility that the images associated with them may be removed.\nAlso, please understand that we are unable to offer any compensation in regard to this matter.</li></ul>',
  'top.get.fortune':
    ' Get Fortune',
  'top.what.dapps.jinja.summary':
    '<ul class="topCol11ul"><li>It is a service that tells your fortune at <strike>0.02ETH</strike>.<br><span style="color: red;font-size: 0.8em">（※ETH = Cryptocurrency Ethereum, Please cover the service fee [ETH Gas] ）</span></li><li>You can know the power of fortune everyday for 30days by free.</li><li>Fortune-telling results are different every day.</li><li>You can get tickets that can be exchanged for assets.</li></ul>',
  'top.what.digital.asset.summary':
    '<p>Purchase a fortune result and get a ticket that can be exchanged for a asset. There are assets that can be used in other dApps games and original assets of dApps Jinja.</p>',
  'top.what.digital.asset.text1':
    "<p>The assets that can be exchanged with tickets are Ethereum's ERC721 tokens.<br />Ownership is yours and you can freely distribute and trade at external asset exchanges such as <a href='https://opensea.io' target='_blank' rel='noopener noreferrer'>OpenSea.io</a>.</p>",
  'top.what.digital.asset.text2':
    '<p class="topCol23">Digital assets that can be exchanged with tickets can be either dApps Jinja original assets or assets of other dApps games.<br />Detailed information on assets can be found in the [MyAsset] menu.</p>',
  'top.what.digital.asset.text3':
    '<strong>dApps Jinja original asset</strong><br />You are the owner of this original asset.<br />Use it freely unless it is for commercial use.<br />Example) Printing on T-shirts and mugs<br />',
  'top.what.digital.asset.text4':
    '<p><strong>Assets for other dApps games</strong><br />Navigate to the distribution site from the asset details page. Enjoy the dApps game !<br /></p>',
  'top.what.start.summary':
    '<p>The virtual currency Ethereum ( ETH ) is required to fortune-telling.</p><p><img src="/img/top/wakaba.svg" class="wakaba">If you want to know more about METAMASK that is Ethreum wallet, please refer to the following URL (external site)</p><p><a href="https://metamask.io" target="_blank" rel="noopener noreferrer" class="textLink">METAMASK.io</a></p>',
  'top.what.start.ready':
    '<p><strong style="font-size:1.2em;">Getting Started</strong><br />You will need a crypto-wallet to manage both your Ethereum (ETH) and the asset (which are ERC 721 tokens).<br />MetaMask Extension is that most used wallet for Ethereum dapps with lots of features and flexibility. <br />MetaMask Extension is compatible with Chrome browser.<br /></p>',
  'top.what.start.way':
    '<p>Please install MetaMask Extension extension in Chrome browser.<br /><strong>▼</strong><br />Please create a ETH Account with a MetaMask Extension.<br /><strong>▼</strong><br />You can purchase Ethereum (ETH) on a cryptocurrency exchange.<br /><strong>▼</strong><br />Please send Ethereum (ETH) to the wallet address (ETH Account).<br /><i class="fas fa-asterisk" style="color:#E17726;"></i> For more details on how to install METAMASK, please refer to the following URL (external site)<br /><a href="https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-Started-With-MetaMask-Part-1-" target="_blank" rel="noopener noreferrer" class="textLink">Getting Started With MetaMask (Part 1)</a><br /><a href="https://metamask.zendesk.com/hc/en-us/articles/360015489391-Getting-Started-With-MetaMask-Part-2-" target="_blank" rel="noopener noreferrer" class="textLink">Getting Started With MetaMask (Part 2)</a><br /><br /></p>',
  // Transaction
  'transaction.waiting.text':
    '<p class="assetModalPart">The Ethereum network is processing your transaction.<br />あなたの要求はイーサリアムネットワークで処理中です。<br />Please wait a moment.<br />少々おまちください。</p>',
  'transaction.waiting.status': 'Waiting',
  'transaction.complete.text':
    '<p class="assetModalPart">The Ethereum network is processing your transaction.<br />あなたの要求はイーサリアムネットワークで処理中です。<br />Please wait a moment.<br />少々おまちください。</p>',
  'transaction.complete.status': 'Completion',
  'transaction.failed.text':
    '<p class="assetModalPart">The Ethereum network is processing your transaction.<br />あなたの要求はイーサリアムネットワークで処理中です。<br />Please wait a moment.<br />少々おまちください。</p>',
  'transaction.failed.status': 'Failed',
  // Unlock
  // Footer
  'footer.caution':
    'dApps JINJA has confirmed the display and operation in the Google Chrome browser.<br />Please enable JavaScript and cookies to use the service correctly.',
  // Error
  'error.main': 'An unexpected error has occurred on this server.',
  'error.notMain': 'please switch to the main Ethereum network'
};
export default en_US;
