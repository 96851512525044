import * as token_util from '../token';
import * as common_util from '../common';
// import * as firestore_util from '../firestore';

/**
 * Assetリスト作成
 */
class AssetListCreator {
  /** プロパティ(this.props.common) */
  prop = null;
  /** アカウント毎のランダムなトークンインデックス配列作成 */
  tokenRandomListMapByAccount = null;
  /** more用 アカウント毎作成済トークン情報インデックスMap */
  moreTokenListIndexNumMapByAccount = new Map();

  constructor(prop) {
    this.prop = prop;
  }

  /**
   * 初期化
   */
  async init() {
    try {
      // アカウント毎のランダムなトークンインデックス配列作成
      this.tokenRandomListMapByAccount = await token_util.createTokenRandomListMapByAccount(
        this.prop.wallet.web3,
        this.prop.common.poolAccountInfoList,
        this.prop.common.baseBlockNumber
      );

      // mort用 アカウント毎作成済トークン情報インデックスMap初期化
      this.resetMoreTokenListCreatedIndex();
    } catch (ex) {
      throw ex;
    }
  }

  /**
   * アカウント毎のランダムトークンIndex配列を返却
   */
  getTokenRandomListMapByAccount() {
    return this.tokenRandomListMapByAccount;
  }

  /**
   * 指定数トークン一覧を取得
   * (キャッシュに保持している一覧先頭より参照)
   * @param {*} createNum
   */
  getRecommendTokenList(createNum = 6) {
    return this._createRecommendTokenList(createNum);
  }

  /**
   * 指定数ランダムなトークン一覧を取得
   * @param {*} createNum
   */
  getRecommendRandomTokenList(createNum = 6, tokenInfo) {
    return this._createRecommendTokenList(createNum, true, tokenInfo);
  }

  /**
   * more対応版 ランダムトークン一覧取得
   * プロパティ内のトークン情報をアカウント情報で指定されている個数分一覧にして返却
   * 返却したトークン情報をメモリに保持し、呼び出し毎に次番のトークン情報を返却
   * アカウントが保持しているトークン情報をすべて返却した際は、先頭から再度返却を開始する
   */
  getMoreTokenRandomList() {
    return this.createMoreTokenRandomList();
  }

  /**
   * アカウント毎のランダムトークンIndex配列
   */
  resetMoreTokenListCreatedIndex() {
    this.prop.common.poolAccountInfoList.forEach(poolAccountInfo => {
      this.moreTokenListIndexNumMapByAccount.set(poolAccountInfo.Address, 0);
    });
  }

  /**
   * おすすめアセット取得
   * ランダムトークンリストより、指定された数のトークン情報を取得する
   */
  _createRecommendTokenList(createNum, reRandomFlg, processTokenInfo = void 0) {
    let drawnIndexNumMap = new Map();
    let drawTokenIndexList = [];

    // ランダムトークンリスト取得(init時のランダム配列 or 再ランダム)
    const useTokenRandomListMapByAccount = this._getUseRandomListMapByAccount(
      reRandomFlg
    );

    while (drawTokenIndexList.length < createNum) {
      // アカウント毎に描写するトークン情報を生成
      this.prop.common.poolAccountInfoList.forEach(poolAccountInfo => {
        // 描写済みトークンIndexMapを参照し、Map未作成の場合初期値を設定
        if (drawnIndexNumMap.get(poolAccountInfo.Address) === void 0) {
          drawnIndexNumMap.set(poolAccountInfo.Address, 0);
        }

        // 表示アセット回数分ループ
        for (let cnt = 0; cnt < poolAccountInfo.ViewCount; cnt++) {
          // 表示件数分取得できている場合は一覧作成終了
          if (drawTokenIndexList.length === createNum) {
            break;
          }

          // アカウント別トークン一覧取得
          const tokenIndexList = useTokenRandomListMapByAccount.get(
            poolAccountInfo.Address
          );

          // アカウントが保持するトークンがない場合はスキップ
          if (tokenIndexList === void 0 || tokenIndexList.length === 0) {
            break;
          }

          // 表示対象のインデックスを取得
          let index = drawnIndexNumMap.get(poolAccountInfo.Address);

          // 最大数の場合は初期化(リストの初めを取得)
          if (index > tokenIndexList.length - 1) {
            index = 0;
          }

          let tokenInfo = tokenIndexList[index];

          // トランザクション発行中のトークンがある場合
          if (processTokenInfo !== void 0) {
            // 処理中トークン同一チェック
            if (
              tokenInfo.Address === processTokenInfo.Address &&
              tokenInfo.index === processTokenInfo.index
            ) {
              // トークン数が一つの場合はスキップ
              if (tokenIndexList.length === 1) {
                break;
              }

              // インデックスを加算
              index++;

              // 最大数を超過した場合は初期化
              if (index > tokenIndexList.length - 1) {
                index = 0;
              }

              // 次のインデックスのトークンを取得
              tokenInfo = tokenIndexList[index];
            }
          }

          // 表示一覧に追加
          drawTokenIndexList.push(tokenInfo);

          // 次回取得開始インデックスを設定
          drawnIndexNumMap.set(poolAccountInfo.Address, ++index);
        }
      });

      // トークンが1件も取得できていない場合(全てのプールアカウントがトークンを保有していない場合)
      // ループを終了する
      if (drawTokenIndexList.length === 0) {
        break;
      }
    }

    return drawTokenIndexList;
  }

  /**
   * アカウント別ランダムトークン一覧取得
   * reRandomFlgにて、プロパティの一覧取得 or 再度ランダム化した一覧を取得するか選択
   * @param {*} reRandomFlg
   */
  _getUseRandomListMapByAccount(reRandomFlg = false) {
    // 利用するリスト作成
    let useTokenRandomListMapByAccount = new Map();
    if (reRandomFlg) {
      this.tokenRandomListMapByAccount.forEach(function(
        tokenRandomList,
        account
      ) {
        useTokenRandomListMapByAccount.set(
          account,
          common_util.arrShuffle(tokenRandomList)
        );
      });
    } else {
      useTokenRandomListMapByAccount = this.tokenRandomListMapByAccount;
    }

    return useTokenRandomListMapByAccount;
  }

  /**
   * プロパティ内のトークン一覧より、表示するトークン情報を取得する
   * more用にクラス内で返却済のインデックス情報を保持
   */
  createMoreTokenRandomList() {
    let tokenIndexList = [];

    // アカウント毎に描写するトークン情報を生成
    this.prop.common.poolAccountInfoList.forEach(poolAccountInfo => {
      // 表示アセット回数分ループ
      for (let cnt = 0; cnt < poolAccountInfo.ViewCount; cnt++) {
        // アカウント別トークン一覧取得
        const tokenRandomList = this.tokenRandomListMapByAccount.get(
          poolAccountInfo.Address
        );

        // アカウントが保持するトークンがない場合はスキップ
        if (tokenRandomList === void 0 || tokenRandomList.length === 0) {
          break;
        }

        // 表示対象のインデックスを取得
        let index = this.moreTokenListIndexNumMapByAccount.get(
          poolAccountInfo.Address
        );

        // 最大数の場合は初期化(リストの初めを取得)
        if (index > tokenRandomList.length - 1) {
          index = 0;
        }

        let tokenInfo = tokenRandomList[index];

        // 表示一覧に追加
        tokenIndexList.push(tokenInfo);

        // 次回取得開始インデックスを設定
        this.moreTokenListIndexNumMapByAccount.set(
          poolAccountInfo.Address,
          ++index
        );
      }
    });

    return tokenIndexList;
  }
}

export default AssetListCreator;
