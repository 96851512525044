import * as web3_util from '../utils/web3';
import * as common_util from '../utils/common';

/**
 * Walletあり
 */
export function existWallet() {
  return {
    type: 'EXIST_WALLET'
  };
}
/**
 * Walletなし
 */
export function notExistWallet() {
  return {
    type: 'NOT_EXIST_WALLET'
  };
}
/**
 * Unlock
 */
export function unlocked(account, networkId, web3) {
  return {
    type: 'UNLOCK',
    isLinked: true,
    account,
    networkId,
    web3
  };
}

/**
 * メタマスク接続待機
 */
export function ConnectWait() {
  return {
    type: 'CONNECT_WAIT'
  };
}

/**
 * メタマスク接続拒否
 */
export function notConnected() {
  return {
    type: 'NOT_CONNECTED'
  };
}

/**
 * wallet接続
 * @param {} _tryActivate
 */
export function connectWallet(_tryActivate = true) {
  return dispatch => {
    dispatch(ConnectWait());
    return web3_util
      .getWeb3(_tryActivate)
      .then(web3 => {
        dispatch(existWallet());
        return web3.eth
          .getAccounts()
          .then(accounts => {
            return web3.eth.net.getId().then(networkId => {
              if (accounts[0] === void 0) {
                return dispatch(notConnected());
              }

              checkChangeAccountNetWork(web3, accounts[0], networkId);
              return dispatch(unlocked(accounts[0], networkId, web3));
            });
          })
          .catch(ex => {
            return dispatch(notConnected());
          });
      })
      .catch(ex => {
        // Wallet検知判定
        if (ex.name === 'NoProviderError') dispatch(notExistWallet());

        return dispatch(notConnected());
      });
  };
}

/**
 * アカウント接続先ネット変更検知
 */
export function checkChangeAccountNetWork(_web3, _account, _netWorkId) {
  let nowAccount = _account;

  setInterval(async () => {
    // 変更前後のアカウント情報を取得
    const newAccount = await _web3.eth.getAccounts();

    if (nowAccount !== newAccount[0]) {
      // セッションストレージクリア
      common_util.clearSession('wallet');
      // Web3情報初期化に伴いブラウザリロード
      window.location.reload();
    }
  }, 1000);
}
