import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import '../css/loading/loading1.css';

const styles = theme => ({
  dialogRoot: {
    zIndex: '10000 !important',
    minWidth: '0 !important'
  }
});

class AlertDialog extends Component {
  constructor(props) {
    super(props);

    this.clickClose = this.clickClose.bind(this);
  }

  clickClose() {
    this.props.onClose();
    this.props.approveIE();
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        style={{ textAlign: 'center', minHeight: '400px', minWidth: '400px' }}
        // onClose={this.props.onClose}
        classes={{
          root: classes.dialogRoot
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          It may not be displayed correctly in your environment. This service is
          recommended for use with Google Chrome browser. Also, please use
          JavaScript and Cookies.
          <br />
          <br />
          ご利用中の環境では正しく表示されない可能性があります。
          当サービスはGoogle Chromeブラウザでのご利用を推奨しております。
          また、JavaScript、Cookieが使用できる状態でご利用ください。
          <br />
          <button className="btnM" onClick={this.clickClose}>
            OK
          </button>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AlertDialog);
