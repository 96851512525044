import { connect } from 'react-redux';
import * as common from '../actions/common';
import AlertDialog from '../components/AlertDialog';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  // common
  return {
    approveIE: () => dispatch(common.approveIE())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDialog);
