import Web3 from 'web3';
import DAppsJinja from '../contracts/DAppsJinja.json';
import Erc721Abi from '../config/ERC721.js';
import TicketInfo from './classes/TicketInfo';

/**
 * dApps神社コントラクトのアドレス取得
 * @param {*} _web3
 */
export async function getDAppsJinjaAddress(_web3) {
  const networkId = await _web3.eth.net.getId();
  return await DAppsJinja.networks[networkId].address;
}

/**
 * アセット販売コントラクトインスタンスを作成
 */
export async function tryConnectDAppsJinjaContract(_web3) {
  const networkId = await _web3.eth.net.getId();
  const deployedNetwork = await DAppsJinja.networks[networkId];

  const contract = await _web3.eth.Contract(
    DAppsJinja.abi,
    deployedNetwork && deployedNetwork.address
  );

  return contract;
}

/**
 * ERC721 インスタンス作成
 */
export function tryConnectERC721Contract(_web3, _contractAddress) {
  return _web3.eth.Contract(Erc721Abi, _contractAddress);
}
/**
 * web3インスタンス取得
 * @param {*} _tryActivate
 */
export async function getWeb3(_tryActivate) {
  if (window.ethereum) {
    try {
      const web3 = new Web3(window.ethereum);
      if (_tryActivate === true) {
        await window.ethereum.enable();
      }

      return web3;
    } catch (error) {
      console.log('not approved connected app');
      throw new Error(error);
    }
  }

  if (window.web3) {
    console.log('window.web3');
    try {
      const web3 = window.web3;
      return web3;
    } catch (error) {
      console.log('Injected web3 detected.');
      throw new Error(error);
    }
  } else {
    try {
      const provider = await new Web3.providers.HttpProvider(
        'http://127.0.0.1:9545'
      );

      const web3 = await new Web3(provider);

      // プロバイダー接続できている場合はweb3返却
      if (web3.givenProvider !== null) return web3;
    } catch (error) {
      throw new Error('error');
    }

    // プロバイダーが見つからない場合は、notfoundErrorを返却する
    const e = new Error('not found provider');
    e.name = 'NoProviderError';
    throw e;
  }
}

/**
 * 引換券情報取得
 * @param {*} _dAppsJinjaContract
 * @param {*} _ownerAccount
 */
export async function getTicketInfo(_dAppsJinjaContract, _ownerAccount) {
  // 引換券情報取得
  const getFreeTicketInfo = await _dAppsJinjaContract.methods
    .freeTokenTicketMap(_ownerAccount)
    .call();

  // チケット数
  const ticketCnt = getFreeTicketInfo['count'];
  // 利用可能開始日
  const availableStartTime = new Date(
    getFreeTicketInfo['availableStartTime'] * 1000
  );
  // 全チケット有効期限
  const availablePeriod = new Date(getFreeTicketInfo['availablePeriod'] * 1000);

  return new TicketInfo(ticketCnt, availableStartTime, availablePeriod);
}

/**
 * 販売要否チェック
 */
export async function checkApprove(
  _web3,
  _contract,
  _tokenOwnerAddress,
  _tokenId
) {
  try {
    // const dAppsJinjaAddress = await getDAppsJinjaAddress(_web3);

    // 譲渡権判定
    // const isAppForAll = await _contract.methods
    //   .isApprovedForAll(_tokenOwnerAddress, dAppsJinjaAddress)
    //   .call();

    // if (isAppForAll !== null && !isAppForAll) return false;

    // const approvedAddress = await _contract.methods
    //   .getApproved(_tokenId)
    //   .call();

    // if (approvedAddress === dAppsJinjaAddress) {
    //   return true;
    // }

    // 所有者取得
    const ownerAddress = await _contract.methods.ownerOf(_tokenId).call();

    // 所有者判定
    if (
      ownerAddress !== null &&
      _tokenOwnerAddress.toLowerCase() !== ownerAddress.toLowerCase()
    )
      return false;
  } catch (error) {
    console.error(error);
    return false;
  }

  return true;
}

/**
 * トークン価格取得
 * @param {*} _dAppsJinjaContract
 */
export async function getTokenValue(_dAppsJinjaContract) {
  return await _dAppsJinjaContract.methods.tokenPrice().call();
}
