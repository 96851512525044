import React, { Component } from 'react';
import ReactGA from 'react-ga';
import DocumentTitle from 'react-document-title';
import { withRouter } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import TwitterConvTrkr from 'react-twitter-conversion-tracker';

import '../css/cookie-consent.css';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

class AppContainer extends Component {
  componentWillMount() {
    // 本番環境のみ
    if (process.env.REACT_APP_ENV !== 'prod') return;

    // GoogleAnalytics 初回表示時
    ReactGA.set({ page: this.props.history.location.pathname });
    ReactGA.pageview(this.props.history.location.pathname);

    TwitterConvTrkr.init('o31fk');
    TwitterConvTrkr.pageView();

    // GoogleAnalytics 画面遷移時用
    this.unListen = this.props.history.listen((location, action) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      TwitterConvTrkr.pageView();
    });
  }

  componentDidUpdate(prevProps) {
    // スクロールをTopへ
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    // 本番環境のみ
    if (process.env.REACT_APP_ENV !== 'prod') return;
    this.unListen();
  }

  render() {
    return (
      <React.Fragment>
        <DocumentTitle title={process.env.REACT_APP_TITLE}>
          {this.props.children}
        </DocumentTitle>
        <Helmet>
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="description"
            content="You can get your fortune every day with ETH. And you can get various dApps game characters. This service use japanese culture elements which is conscious of users all over the world who like Japanese culture.  毎日の運勢とdAppsゲームキャラクターがETHで入手できます"
          ></meta>
        </Helmet>
        {/* GDPR対策 */}
        <CookieConsent
          buttonText="Got it!"
          style={{
            backgroundColor: 'rgb(0, 0, 0)',
            color: 'rgb(255, 255, 255)',
            padding: '1em 1.8em',
            fontSize: '16px',
            fontFamily: 'Helvetica, Calibri, Arial, sans-serif',
            margin: '0',
            lineHeight: '1.5em'
          }}
          contentStyle={{
            margin: '0'
          }}
          buttonId="cookie-consent-button"
          buttonStyle={{
            color: 'rgb(0, 0, 0)',
            backgroundColor: 'rgb(241, 214, 0)',
            borderColor: 'transparent',
            fontSize: '.9em',
            fontWeight: '700',
            borderWidth: '2px',
            borderStyle: 'solid',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            margin: '0'
          }}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
          <a
            id="cookie-consent-more-link"
            href="https://www.cookiesandyou.com"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Learn more
          </a>
        </CookieConsent>
      </React.Fragment>
    );
  }
}

export default withRouter(AppContainer);
