import * as web3_util from '../utils/web3';
import * as firestore_util from '../utils/firestore';
import * as common_util from '../utils/common';

// デバッグ用
import TicketInfo from '../utils/classes/TicketInfo';

/**
 * 言語切替
 * @param {*} lang
 */
export function setLanguage(lang) {
  common_util.setStrage('language', lang);
  return {
    type: 'SET_LANGUAGE',
    lang: lang
  };
}

/**
 * ベース情報設定
 */
export function setBaseInfo(
  blockNum,
  erc721ContractInfoList,
  poolAccountInfoList,
  ticketInfo
) {
  return {
    type: 'BASE_INFO',
    baseBlockNumber: blockNum,
    erc721ContractInfoList: erc721ContractInfoList,
    poolAccountInfoList: poolAccountInfoList,
    ticketInfo: ticketInfo
  };
}

/**
 * componentDidMount内で実行する共通処理
 * ERC721コントラクトのインスタンス、アセット販売コントラクト関連の情報を取得する
 * 既に作成or取得済の場合は処理をスキップする。
 * 引換券情報については随時変更される可能性があるため、稼働毎に再取得&設定を行う
 * @param {*} _props
 * @param {*} _account
 */
export function initBaseLogicOfDidMount(_props, _account) {
  return async dispatch => {
    let blockNum, erc721ContractInfoList, poolAccountInfoList;

    // 本番環境の場合はネットワークIDをチェック
    if (process.env.REACT_APP_ENV === 'prod') {
      if (_props.wallet.networkId !== 1) {
        throw new Error('not main net');
      }
    }

    if (!_props.common.isBaseInit) {
      // ブロック番号取得
      blockNum = await _props.wallet.web3.eth.getBlockNumber();

      // プールアカウント情報取得
      poolAccountInfoList = await firestore_util.getPoolAccountInfoList();

      // ERC721コントラクト情報取得
      erc721ContractInfoList = await firestore_util.getContractInfoList();
    }

    // 引換券情報取得
    let ticketInfo;
    if (process.env.REACT_APP_DEBUG !== '1') {
      ticketInfo = await web3_util
        .tryConnectDAppsJinjaContract(_props.wallet.web3)
        .then(async contract => {
          return await web3_util.getTicketInfo(contract, _account);
        });
    } else {
      ticketInfo = new TicketInfo(0, 0, 0);
    }

    return dispatch(
      setBaseInfo(
        blockNum,
        erc721ContractInfoList,
        poolAccountInfoList,
        ticketInfo
      )
    );
  };
}

/**
 * アセット一覧クリエイター
 */
export function AssetListCreator(assetListCreator) {
  return {
    type: 'ASSET_LIST_CREATOR',
    assetListCreator
  };
}

/**
 * アセット一覧クリエイター設定
 */
export function setAssetListCreator(_assetListCreator) {
  return dispatch => {
    return dispatch(AssetListCreator(_assetListCreator));
  };
}

/**
 * 言語切替
 * @param {*} lang
 */
export function approveIE() {
  return dispatch => {
    return dispatch({
      type: 'APPROVE_IE'
    });
  };
}
