import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        <div id="footerWrap">
          <div id="footerLinkWrap">
            <ul id="footerLink">
              {/* <li>
                <Link to="/terms-of-service">
                  <i className="fas fa-caret-square-right"></i> Terms of Service
                </Link>
              </li> */}
              {/* <li>
                <a href="mailto:dj-contact@dappsjinja.jp">
                  <i className="fas fa-caret-square-right"></i> Contact us
                </a>
              </li> */}
              <li>
                <a
                  href="https://cryptolab.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-caret-square-right"></i> CryptoLab CO.,
                  LTD.
                </a>
              </li>
            </ul>
          </div>
          <div id="footerCaution">
            <FormattedHTMLMessage id="footer.caution" />
          </div>
          <div id="copy">Copyright © dApps jinja All Rights Reserved.</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
