import * as cf from '../utils/common';

const initialState = {
  isExistWallet: true,
  isLinked: false,
  connectWait: false
  //   account,
  //   networkId,
  //   web3
};

function walletReducer(state = initialState, action) {
  var newState = null;

  switch (action.type) {
    case 'EXIST_WALLET':
      // 参照できるWalletあり
      return Object.assign({}, state, {
        isExistWallet: true
      });
    case 'NOT_EXIST_WALLET':
      // Walletなし
      return Object.assign({}, state, {
        isExistWallet: false
      });

    case 'UNLOCK':
      newState = Object.assign({}, state, {
        isLinked: true,
        connectWait: false,
        account: action.account,
        networkId: action.networkId,
        web3: action.web3
      });

      // ディープコピーで渡す
      cf.setSession('wallet', Object.assign({}, newState));

      return newState;
    case 'CONNECT_WAIT':
      // メタマスクConnect待機
      return Object.assign({}, state, {
        connectWait: true
      });
    case 'NOT_CONNECTED':
      // メタマスク接続拒否
      return Object.assign({}, state, {
        isLinked: null,
        connectWait: false
      });

    default:
      const SessionState = cf.getSession('wallet');

      if (SessionState) {
        // ストレージにデータがあったらそのまま引き継ぐ
        return Object.assign({}, state, SessionState);
      }

      return state;
  }
}
export default walletReducer;
