import * as cf from '../utils/common';
import * as common_util from '../utils/common';

const initialState = {
  isBaseInit: false,
  lang: common_util.getStorageLang(),
  poolAccountInfoList: [],
  erc721ContractInfoList: [],
  checkedApproveIE: false
};

function commonReducer(state = initialState, action) {
  var newState = null;

  switch (action.type) {
    case 'SET_LANGUAGE':
      newState = Object.assign({}, state, {
        lang: action.lang
      });

      cf.setSession('common', Object.assign({}, newState));
      return newState;

    case 'BASE_INFO':
      // 初回
      if (action.baseBlockNumber !== void 0) {
        newState = Object.assign({}, state, {
          isBaseInit: true,
          erc721ContractInfoList: action.erc721ContractInfoList,
          poolAccountInfoList: action.poolAccountInfoList,
          baseBlockNumber: action.baseBlockNumber,
          ticketInfo: action.ticketInfo
        });
      } else {
        // 初回以降
        newState = Object.assign({}, state, {
          isBaseInit: true,
          ticketInfo: action.ticketInfo
        });
      }

      console.info('commonReducer BASE_INFO', newState);
      cf.setSession('common', Object.assign({}, newState));
      return newState;

    case 'ASSET_LIST_CREATOR':
      newState = Object.assign({}, state, {
        assetListCreator: action.assetListCreator
      });

      console.info('commonReducer ASSET_LIST_CREATOR', newState);
      cf.setSession('common', Object.assign({}, newState));
      return newState;

    case 'UPDATE_TICKET_INFO_TRN_COMP_TIMING':
      // 着火はtransaction action
      // 引き換え券情報更新
      newState = Object.assign({}, state, {
        ticketInfo: action.ticketInfo
      });
      console.info(
        'commonReducer UPDATE_TICKET_INFO_TRN_COMP_TIMING',
        newState
      );
      cf.setSession('common', Object.assign({}, newState));
      return newState;

    case 'APPROVE_IE':
      return Object.assign({}, state, {
        checkedApproveIE: true
      });

    default:
      const SessionState = cf.getSession('common');

      if (SessionState) {
        // ストレージにデータがあったらそのまま引き継ぐ
        return Object.assign({}, state, SessionState);
      }

      return state;
  }
}
export default commonReducer;
