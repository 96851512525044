/**
 * ネットワークID
 */
export const NET_WORK_ID_MAIN_NET = 1;
/**
 * アセットカード タイプ
 */
export const ASSET_CARD_TYPE_SALE_CARD = 'saleCard';
export const ASSET_CARD_TYPE_MY_CARD = 'myCard';
export const ASSET_CARD_TYPE_BANNER = 'banner';

/**
 * アセット詳細 タイプ
 */
export const ASSET_DETAIL_TYPE_SALE = 'sale';
export const ASSET_DETAIL_TYPE_OWNED = 'owned';

/**
 * エポックタイム換算
 */
export const UNIX_TIME_ONE_SEC = 1000;
export const UNIX_TIME_ONE_MINUIT = 60000;
export const UNIX_TIME_ONE_HOUR = 3600000;
export const UNIX_TIME_ONE_DAY = 86400000;
