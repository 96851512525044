import React, { Component } from 'react';
import Layout from '../containers/Layout';
import FooterAD from '../components/FooterAD';
import AssetListCreator from '../utils/classes/AssetListCreator';
import AssetCard from '../containers/AssetCard';
import { FormattedHTMLMessage } from 'react-intl';

import * as common_util from '../utils/common';
import * as constant from '../config/constant';
import * as config from '../config/config';

import moment from 'moment';
import axios from 'axios';

/**
 * 占い結果画面
 * 占いAPIを稼働させ、占い情報を取得する。
 * また、販売中のアセット情報を取得し販売中アセットバナーとして表示する
 */
class FortuneResult extends Component {
  isRedirect = true;

  constructor(props) {
    super(props);

    this.state = {
      symbol_image: null,
      cycle_image: null,
      fortune: null,
      assetList: [],

      openDetail: false
    };

    // 占いボタン遷移以外の場合はTopへ
    if (this.props.fortune.isReferFortuneResult) {
      this.props.setRefFortuneResult(false);
      this.isRedirect = false;
    } else {
      this.props.history.push('/');
      return;
    }
  }

  componentDidMount = async () => {
    if (this.isRedirect) return;

    try {
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet(false);
      }
      // web3接続できない場合はtopへ
      if (!this.props.wallet.isLinked) {
        this.props.history.push('/top');
        return;
      }

      await this.props.initBaseLogicOfDidMount(
        this.props,
        this.props.wallet.account
      );

      var today = moment();
      var storage = common_util.getStrage('fortune_param');

      // 占いAPI実行
      axios
        .get(config.FORTUNE_API_URL, {
          params: {
            menu: 0,
            bday:
              storage.birthday.year +
              '-' +
              storage.birthday.month +
              '-' +
              storage.birthday.day,
            today: today.format('YYYY-MM-DD'),
            ucode: this.createUCode(),
            gender: storage.gender
          }
        })
        .then(response => {
          this.setState({
            symbol_image: ('0' + response.data.result.unmeiId).slice(-2),
            cycle_image: ('0' + response.data.result.imageId).slice(-2),
            fortune: response.data.result.items
          });
        })
        .catch(err => {
          console.error(err);
          common_util.errRedirect(this.props, err);
        });

      // アセットクリエイター作成
      if (this.props.common.assetListCreator === void 0) {
        var assetListCreator = new AssetListCreator(this.props);
        await assetListCreator.init();

        this.props.setAssetListCreator(assetListCreator);
      }

      // アセット一覧取得(メモリ内ランダム一覧先頭)
      var assets = this.createAssetList(
        await this.props.common.assetListCreator.getRecommendTokenList(8)
      );

      this.setState({
        assetList: assets
      });
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  };

  /**
   * 運命コード作成
   * 公開鍵10進数先頭7桁取得
   */
  createUCode() {
    return (
      parseInt(this.props.wallet.account, 16)
        .toString()
        .substring(0, 11) * 1000000000
    )
      .toString()
      .substring(0, 7);
  }

  createAssetList(tokenList) {
    var renderTokenList = [];

    var key = 0;
    for (var item of tokenList) {
      renderTokenList.push(
        <li key={key}>
          <AssetCard
            key={key}
            tokenIndexInfo={item}
            tokenIndexLatestBlock={false}
            drawType={constant.ASSET_CARD_TYPE_BANNER}
            openDetailFunc={() => {
              this.openDetailFunc();
            }}
          />
        </li>
      );
      key++;
    }
    return renderTokenList;
  }

  /**
   * トークン詳細モーダル表示
   */
  openDetailFunc() {
    this.setState({ openDetail: true });
  }

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            <section>
              <h1>
                <FormattedHTMLMessage id="fortuneResult.fortune.header" />
              </h1>
              <div className="fortuneBox">
                <div className="fortuneBoxL">
                  <h2>
                    <FormattedHTMLMessage id="fortuneResult.fortune.symbol" />
                  </h2>
                  <img
                    src={'/fortune-name/' + this.state.symbol_image + '.svg'}
                    className="fortuneFortune"
                    alt="symbolImage"
                  />
                  <h2>
                    <FormattedHTMLMessage id="fortuneResult.fortune.cycle" />
                  </h2>
                  <img
                    src={'./fortune-bio/' + this.state.cycle_image + '.svg'}
                    className="fortuneBio"
                    alt="cycleImage"
                  />
                  <img
                    src={'./fortune-graph/' + this.state.cycle_image + '.svg'}
                    className="fortuneGraph"
                    alt="fortuneGraph"
                  />
                  <img
                    src="./fortune-graph/parts.svg"
                    className="fortuneGraphParts"
                    alt="fortuneGraphParts"
                  />
                </div>
                <div className="fortuneBoxR">
                  {this.state.fortune &&
                    (this.props.common.lang === config.LANG_ENGLISH ? (
                      <React.Fragment>
                        <h2>{this.state.fortune[1].title}</h2>
                        <p>{this.state.fortune[1].text}</p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <h2>{this.state.fortune[0].title}</h2>
                        <p>{this.state.fortune[0].text}</p>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </section>
            <div id="descriptionBox">
              <input type="checkbox" id="toggle" />
              <div id="col">
                <section>
                  <h1>
                    <FormattedHTMLMessage id="fortuneResult.unmai.star.title" />
                  </h1>

                  <p className="fortuneCol1">
                    <FormattedHTMLMessage id="fortuneResult.unmai.star.text" />
                  </p>
                  <FormattedHTMLMessage id="fortuneResult.unmai.star.graph" />
                </section>

                <section>
                  <h1>
                    <FormattedHTMLMessage id="fortuneResult.unmai.power.title" />
                  </h1>
                  <p className="fortuneCol2">
                    <FormattedHTMLMessage id="fortuneResult.unmai.power.text" />
                  </p>
                  <FormattedHTMLMessage id="fortuneResult.unmai.power.graph" />
                </section>
              </div>
              <label htmlFor="toggle">
                <i className="fas fa-chevron-circle-down"></i>{' '}
                <span>
                  <FormattedHTMLMessage id="fortuneResult.unmai.star.description" />
                </span>
                <span>
                  <FormattedHTMLMessage id="fortuneResult.unmai.star.close" />
                </span>
              </label>
            </div>

            {this.state.assetList.length > 0 ? (
              <section>
                <h2 style={{ textAlign: 'center' }}>
                  <FormattedHTMLMessage id="fortuneResult.asset.message" />
                </h2>
                <ul className="assetPreviewBox">{this.state.assetList}</ul>
                <div className="assetPreviewBoxBtn">
                  <button
                    className="btnLL"
                    onClick={() => {
                      this.props.history.push('/asset-sales');
                    }}
                  >
                    <i className="far fa-arrow-alt-circle-down" />
                    <FormattedHTMLMessage id="fortuneResult.asset.button" />
                  </button>
                </div>
              </section>
            ) : (
              <section>
                <h2 style={{ textAlign: 'center' }}>
                  <FormattedHTMLMessage id="fortuneResult.no.asset.message" />
                </h2>
              </section>
            )}

            <FooterAD />
          </div>
        </div>
      </Layout>
    );
  }
}

export default FortuneResult;
