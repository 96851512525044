import React, { Component } from 'react';
import Layout from '../containers/Layout';
// import FooterAD from '../components/FooterAD';
import { FormattedHTMLMessage } from 'react-intl';
import * as common_util from '../utils/common';

var RANDOM_PICT_IMG = [
  <img src="/img/top-pict/01.svg" alt="" />,
  <img src="/img/top-pict/02.svg" alt="" />,
  <img src="/img/top-pict/03.svg" alt="" />,
  <img src="/img/top-pict/04.svg" alt="" />,
  <img src="/img/top-pict/05.svg" alt="" />
];

var RANDOM_WORD_IMG = [
  <img src="/img/top-word/001.svg" alt="" />,
  <img src="/img/top-word/002.svg" alt="" />,
  <img src="/img/top-word/003.svg" alt="" />,
  <img src="/img/top-word/004.svg" alt="" />,
  <img src="/img/top-word/005.svg" alt="" />,
  <img src="/img/top-word/006.svg" alt="" />,
  <img src="/img/top-word/007.svg" alt="" />,
  <img src="/img/top-word/008.svg" alt="" />,
  <img src="/img/top-word/009.svg" alt="" />,
  <img src="/img/top-word/010.svg" alt="" />
];

class Top extends Component {
  constructor(props) {
    super(props);

    this.redirectUnlock = this.redirectUnlock.bind(this);
    this.redirectFortune = this.redirectFortune.bind(this);

    // 画面Topランダム画像表示用にStateへ設定
    this.state = {
      pictImg:
        RANDOM_PICT_IMG[Math.floor(Math.random() * RANDOM_PICT_IMG.length)],
      randomImg:
        RANDOM_WORD_IMG[Math.floor(Math.random() * RANDOM_WORD_IMG.length)]
    };
  }

  componentDidMount = async () => {
    try {
      // wallet接続していない場合はwallet接続
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet();
      }

      // wallet接続している場合は、基本情報を取得
      if (this.props.wallet.isLinked)
        await this.props.initBaseLogicOfDidMount(
          this.props,
          this.props.wallet.account
        );
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }
  };

  redirectUnlock = () => {
    this.props.history.push('/unlock');
  };

  redirectFortune = () => {
    // 未ログインの場合はアンロック画面へ遷移
    if (!this.props.wallet.isLinked) {
      this.props.history.push('/unlock');
    } else {
      this.props.history.push('/fortune');
    }
  };

  fortuneButton() {
    // return (
    //   <button className="btnM" onClick={this.redirectFortune}>
    //     <i className="fas fa-yin-yang"></i>
    //     <FormattedHTMLMessage id="top.get.fortune" />
    //   </button>
    // );
  }

  render() {
    return (
      <Layout>
        {/* <div id="topImageWrap">
          <div id="topImage1" />
          <div id="topAnimWrap">
            <div id="splashLogo">
              <img src="/img/top-anim/splash.svg" alt="" />
            </div>
            <div id="topImage2">
              <img src="/img/top-anim/anim2.svg" alt="" />
            </div>
            <div id="topImage3">
              <img src="/img/top-anim/anim3.svg" alt="" />
            </div>
            <div id="topImage4">
              <img src="/img/top-anim/anim4.svg" alt="" />
            </div>
            <div id="topImage5">
              <img src="/img/top-anim/anim5.svg" alt="" />
            </div>
            <div id="topImage6">{this.state.pictImg}</div>
            <div id="topImage7">{this.state.randomImg}</div>
            <div id="topImage8">
              <img src="/img/top-anim/anim8.svg" alt="" />
            </div>
          </div>
        </div> */}

        <div id="pageWrap">
          {/* ページ間の赤いひし形 */}
          {/* <img src="./img/top/tri.svg" id="topTri" alt="" /> */}
          <div id="topbg1">

            {/* サービス終了のお知らせ */}
            {/* <div className="contentsWrap">
              <section>
                <span>
                  <p>
                      <FormattedHTMLMessage id="service.end.notice" />
                  </p>
                </span>
              </section>
            </div> */}
            <div className="contentsWrap">
              <section>{this.fortuneButton()}</section>
            </div>
            <div className="contentsWrap">
              <section>
                <ul className="topCol11">
                  <li>
                    <img src="./img/top/cont1a.png" alt="" />
                  </li>
                  <li>
                    <img src="./img/top/cont1b.png" alt="" />
                    <FormattedHTMLMessage id="service.end.guidance" />
                  </li>
                </ul>
                {/* <input type="checkbox" id="toggle1" />
                <div id="col1">
                  <ul className="topCol12">
                    <li>
                      <FormattedHTMLMessage id="fortune.fortune.text.1" />
                    </li>
                    <li>
                      <img
                        src="./img/top/fortune.jpg"
                        className="imgDropshadow"
                        alt=""
                      />
                    </li>
                  </ul>
                  <ul className="topCol13">
                    <li>
                      <img src="./img/top/SixStarFortuneTheory.png" alt="" />
                    </li>
                    <li>
                      <FormattedHTMLMessage id="fortune.fortune.text.2" />
                    </li>
                  </ul>
                </div> */}
                {/* <label htmlFor="toggle1">
                  <em className="fas fa-sync-alt"></em> <span>More</span>
                  <span>Close</span>
                </label> */}
              </section>
            </div>
          </div>

          {/* <div id="topbg2">
            <div className="contentsWrap">
              <section>{this.fortuneButton()}</section>
            </div>
            <div className="contentsWrap">
              <section>
                <ul className="topCol21">
                  <li>
                    <img src="./img/top/cont2a.png" alt="" />
                    <FormattedHTMLMessage id="top.what.digital.asset.summary" />
                  </li>
                  <li>
                    <img src="./img/top/cont2b.png" alt="" />
                  </li>
                </ul>
                <input type="checkbox" id="toggle2" />
                <div id="col2">
                  <ul className="topCol22">
                    <li>
                      <FormattedHTMLMessage id="top.what.digital.asset.text1" />
                    </li>
                    <li>
                      <a
                        href="https://opensea.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="./img/top/opensea-logo-full-colored-blue-vertical.svg"
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                  <FormattedHTMLMessage id="top.what.digital.asset.text2" />
                  <ul className="topCol24">
                    <li>
                      <ul className="topCol25">
                        <li>
                          <img src="./img/top/001.svg" alt="" />
                        </li>
                        <li>
                          <FormattedHTMLMessage id="top.what.digital.asset.text3" />
                        </li>
                      </ul>
                      <ul className="topCol26">
                        <li>　</li>
                        <li>
                          <FormattedHTMLMessage id="top.what.digital.asset.text4" />
                        </li>
                      </ul>
                    </li>
                    <li>
                      <img
                        src="./img/top/tshirt-man.jpg"
                        className="imgDropshadow"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="./img/top/magcup.jpg"
                        className="imgDropshadow"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
                <label htmlFor="toggle2">
                  <em className="fas fa-sync-alt"></em> <span>More</span>
                  <span>Close</span>
                </label>
              </section>
            </div>
          </div> */}

          {/* コンテンツ3 */}
          {/* <div id="topbg3">
            <div className="contentsWrap">
              <section>{this.fortuneButton()}</section>
            </div>
            <div className="contentsWrap">
              <section>
                <ul className="topCol31">
                  <li>
                    <img src="./img/top/cont3a.png" alt="" />
                  </li>
                  <li>
                    <img src="./img/top/cont3b.png" alt="" />
                    <FormattedHTMLMessage id="top.what.start.summary" />
                  </li>
                </ul>
                <input type="checkbox" id="toggle3" />
                <div id="col3">
                  <ul className="topCol32">
                    <li>
                      <FormattedHTMLMessage id="top.what.start.ready" />
                    </li>
                    <li>
                      <a
                        href="https://metamask.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="./img/top/metamask-fox-wordmark-stacked.svg"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <FormattedHTMLMessage id="top.what.start.way" />
                    </li>
                  </ul>
                </div>
                <label htmlFor="toggle3">
                  <em className="fas fa-sync-alt"></em> <span>More</span>
                  <span>Close</span>
                </label>
              </section>
            </div>
          </div> */}

          {/* sign in ボタン */}
          {/* <div className="contentsWrap">
            <section>
              {this.props.wallet.isLinked ? (
                this.fortuneButton()
              ) : (
                <button className="btnM" onClick={this.redirectUnlock}>
                  <i className="fas fa-sign-in-alt"></i> Sign in
                </button>
              )}
            </section>
          </div> */}
          {/* 提携企業 */}
          {/* <div className="contentsWrap">
            <FooterAD />
          </div> */}
        </div>
      </Layout>
    );
  }
}

export default Top;
