import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// redux関連
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

// ルーティング関連
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import AppContainer from './components/AppContainer';

import client from 'axios';

// 画面定義
// トップ画面
import Top from './containers/Top';
// Unlock画面
import Unlock from './containers/Unlock';
// 認証チェック
import Auth from './containers/Auth';
// 占い
import Fortune from './containers/Fortune';
// 占い結果
import FortuneResult from './containers/FortuneResult';
// アセット販売
import AssetSales from './containers/AssetSales';
// 保有アセット
import MyAsset from './containers/MyAsset';
// 利用規約
import TermsOfService from './containers/TermsOfService';
// トランザクション
import Transaction from './containers/Transaction';
// エラー
import Error from './containers/Error';

// reducer定義
import CommonReducer from './reducers/CommonReducer';
import WalletReducer from './reducers/WalletReducer';
import TransactionReducer from './reducers/TransactionReducer';
import TokenReducer from './reducers/TokenReducer';
import FortuneReducer from './reducers/FortuneReducer';

// axiosをthunkの追加引数に加える
const history = require('history').createBrowserHistory();
const thunkWithClient = thunk.withExtraArgument(client);

const store = createStore(
  combineReducers({
    routing: routerReducer,
    common: CommonReducer,
    wallet: WalletReducer,
    transaction: TransactionReducer,
    token: TokenReducer,
    fortune: FortuneReducer
  }),
  applyMiddleware(routerMiddleware(history), thunkWithClient, logger)
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AppContainer>
        <Switch>
          <Route exact path="/" component={Top} />
          <Route exact path="/unlock" component={Unlock} />
          <Route exact path="/top" component={Top} />
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <Route exact path="/error" component={Error} />
          <Route exact path="/reload" component={null} key="reload" />
          <Auth>
            <Switch>
              <Route exact path="/fortune" component={Fortune} />
              <Route exact path="/fortune-result" component={FortuneResult} />

              <Route exact path="/asset-sales" component={AssetSales} />

              <Route exact path="/my-asset" component={MyAsset} />
              <Route exact path="/transaction" component={Transaction} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Auth>
          <Route render={() => <Redirect to="/" />} />
          {/* 未ログイン、ログイン問わずマッチしない場合はTopへリダイレクト */}
        </Switch>
      </AppContainer>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
