import React, { Component } from 'react';
import Layout from '../containers/Layout';
import FooterAD from '../components/FooterAD';
import AssetCard from '../containers/AssetCard';
import DetailDialog from '../components/DetailDialog';
import WaitDialog from '../components/WaitDialog';
import AssetListCreator from '../utils/classes/AssetListCreator';
import { FormattedHTMLMessage } from 'react-intl';
import * as web3_util from '../utils/web3';
import * as common_util from '../utils/common';
import * as constant from '../config/constant';

/**
 * アセット販売
 * プールアカウント(DB)を参照し、アカウントの保有しているトークンをランダムな順序で販売する
 * ランダムな順序については、ブラウザが閉じられるまで保持し続ける。
 * プールアカウントが保有しているトークンを全て表示しきった場合、順序初めから表示を繰り返す。
 */
class AssetSales extends Component {
  // アカウント毎の画面描写済みのトークンIndexMap
  drawnIndexNumMap = new Map();
  // 描写するアセットカードのインデックス
  AssetItemKey = 0;

  constructor(props) {
    super(props);

    this.state = {
      drawTokenList: [],
      loadingWait: true, // ローディング判定
      openDetail: false // アセット詳細表示
    };
  }

  componentDidMount = async () => {
    // ローディング判定
    this.setState({ loadingWait: true });

    try {
      // wallet接続未実施の場合は接続
      if (this.props.wallet.web3 === void 0) {
        await this.props.connectWallet();
      }

      // web3接続できない場合はtopへ
      if (!this.props.wallet.isLinked || this.props.wallet.isLinked === null) {
        this.props.history.push('/top');
        return;
      }

      // Base処理 各種コントラクト、引換券情報更新
      await this.props.initBaseLogicOfDidMount(
        this.props,
        this.props.wallet.account
      );

      // トークン販売価格取得
      const tokenPrice = await web3_util
        .tryConnectDAppsJinjaContract(this.props.wallet.web3)
        .then(async contract => {
          return await web3_util.getTokenValue(contract);
        });

      // トークン販売価格最新化(WaiとEth両方設定)
      // Waiは処理用、Ethは表示用
      this.props.setTokenPrice(
        tokenPrice.toString(),
        this.props.wallet.web3.utils.fromWei(tokenPrice.toString(), 'ether')
      );

      // アセットクリエイターがReduxへ登録されていない場合は作成
      if (this.props.common.assetListCreator === void 0) {
        var assetListCreator = new AssetListCreator(this.props);
        await assetListCreator.init();

        // Reduxへ保存
        this.props.setAssetListCreator(assetListCreator);
      }

      // more用の取得済インデックス配列初期化
      this.props.common.assetListCreator.resetMoreTokenListCreatedIndex();

      // トークン情報画面描写
      this.drawTokenList(
        this.props.common.assetListCreator.getMoreTokenRandomList()
      );
    } catch (error) {
      console.error(error);
      common_util.errRedirect(this.props, error);
    }

    // ローディング終了
    this.setState({ loadingWait: false });
  };

  /**
   * トークン一覧描写
   * @param {*} _tokenRandomList
   */
  drawTokenList(_tokenRandomList) {
    let renderItemList = [];
    for (let index = 0; index < _tokenRandomList.length; index++) {
      const renderItem = (
        <AssetCard
          key={this.AssetItemKey++}
          tokenIndexInfo={_tokenRandomList[index]}
          openDetailFunc={() => {
            this.openDetailFunc();
          }}
          tokenIndexLatestBlock={false}
          drawType={constant.ASSET_CARD_TYPE_SALE_CARD}
        />
      );

      renderItemList.push(renderItem);
    }

    // 画面表示
    this.setState({
      drawTokenList: this.state.drawTokenList.concat(renderItemList)
    });
  }

  /**
   * トークン詳細モーダル表示
   * 本Functionはアセットカードコンポーネントへ受け渡し、アセットカードより稼働する
   */
  openDetailFunc() {
    this.setState({ openDetail: true });
  }

  /**
   * moreボタンクリック
   * 一覧画面に表示するトークン情報をstateへ追加する
   */
  moreClick() {
    // トークン情報画面描写
    this.drawTokenList(
      this.props.common.assetListCreator.getMoreTokenRandomList()
    );
  }

  render() {
    return (
      <Layout>
        <div id="pageWrap">
          <div id="contentsWrap">
            <section>
              <h1>
                <FormattedHTMLMessage id="assetSales.header" />
              </h1>

              {/* <div id="category">
                <div className="cp_ipselect07wh">
                  <input type="radio" name="option" />
                  <i className="toggle cp_sl07wh_arrowdown" />
                  <i className="toggle cp_sl07wh_arrowup" />
                  <span className="cp_sl07wh_selectlabel">Category</span>
                  <label className="option">
                    <input type="radio" name="option" />
                    <span className="cp_sl07wh_title">
                      <img src="./asset-icon/dAppsJINJA.png" />
                      dAppsJINJA
                    </span>
                  </label>
                  <label className="option">
                    <input type="radio" name="option" />
                    <span className="cp_sl07wh_title">
                      <img src="./asset-icon/MyCryptoHeroes.png" />
                      MyCryptoHeroes
                    </span>
                  </label>
                </div>
              </div> */}

              <ul className="assetBox">{this.state.drawTokenList}</ul>
              {!this.state.loadingWait && (
                <button className="btnM" onClick={this.moreClick.bind(this)}>
                  <i className="fas fa-sync-alt" /> More
                </button>
              )}
            </section>

            <FooterAD />
          </div>
        </div>
        <DetailDialog
          open={this.state.openDetail}
          onClose={() => this.setState({ openDetail: false })}
          drawType={constant.ASSET_DETAIL_TYPE_SALE}
        />
        <WaitDialog open={this.state.loadingWait} text="Loading..." />
      </Layout>
    );
  }
}
export default AssetSales;
