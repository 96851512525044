import { connect } from 'react-redux';
import * as common from '../actions/common';
import Header from '../components/Header';

const mapStateToProps = state => {
  return {
    common: state.common,
    wallet: state.wallet,
    transaction: state.transaction,
    token: state.token,
    fortune: state.fortune
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // common
    setLanguage: lang => dispatch(common.setLanguage(lang))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
