import * as cf from '../utils/common';
import Transaction from '../utils/classes/Transaction';

const initialState = {
  transactionMap: new Map(),
  nowTransaction: new Transaction('', '', '')
};

function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_TRANSACTION':
      let newTransactionMap = state.transactionMap;
      // ハッシュをキーにトランザクションオブジェクト格納
      newTransactionMap.set(action.transaction.hash, action.transaction);

      return Object.assign({}, state, {
        transactionMap: newTransactionMap,
        nowTransaction: action.transaction
      });

    default:
      const SessionState = cf.getSession('transaction');

      if (SessionState) {
        // ストレージにデータがあったらそのまま引き継ぐ
        return Object.assign({}, state, SessionState);
      }

      return state;
  }
}
export default transactionReducer;
