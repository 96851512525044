/**
 * トークン詳細情報
 */
export default class TokenDetailInfo {
  /**  トークンを保有しているアカウント */
  accountAddress;
  /** トークン発行元のコントラクトアドレス */
  contractAddress;
  /** トークンID */
  tokenId;
  /** トークン名 */
  name;
  /** 画像参照先URL */
  image;
  /** 説明 */
  description;
  /** トークンのプロパティ */
  propertyMap;
  /** トークンURI JSON */
  json;

  constructor(
    _accountAddress,
    _contractAddress,
    _tokenId,
    _name,
    _image,
    _description,
    _propertyMap,
    _json
  ) {
    this.accountAddress = _accountAddress;
    this.contractAddress = _contractAddress;
    this.tokenId = _tokenId;
    this.name = _name;
    this.image = _image;
    this.description = _description;
    this.propertyMap = _propertyMap;
    this.json = _json;
  }
}
