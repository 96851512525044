import React, { Component } from 'react';
import * as config from '../config/config';
class Lang extends Component {
  constructor(props) {
    super(props);

    this.changeLang = this.changeLang.bind(this);
    this.state = { lang: this.props.common.lang };
  }

  changeLang(_lang) {
    this.props.setLanguage(_lang);
  }

  render() {
    if (this.props.type === 'pc') {
      return (
        <div id="lang" className="cp_ipselect07">
          <input
            type="radio"
            name="option_lang"
            onChange={e => this.setState({ lang: '' })}
          />
          <i className="toggle cp_sl07_arrowdown" />
          <i className="toggle cp_sl07_arrowup" />
          {/* <span className="cp_sl07_selectlabel">LN</span> */}
          <label className="option">
            <input
              type="radio"
              name="option_lang"
              value={config.LANG_ENGLISH}
              checked={this.state.lang === config.LANG_ENGLISH}
              onChange={e => {
                this.setState({ lang: e.target.value });
                this.changeLang(e.target.value);
              }}
            />
            {/* <span className="cp_sl07_title">EN</span> */}
          </label>
          <label className="option">
            <input
              type="radio"
              name="option_lang"
              value={config.LANG_JAPANESE}
              checked={this.state.lang === config.LANG_JAPANESE}
              onChange={e => {
                this.setState({ lang: e.target.value });
                this.changeLang(e.target.value);
              }}
            />
            {/* <span className="cp_sl07_title">JP</span> */}
          </label>
        </div>
      );
    } else {
      return (
        <div id="sp-lang" className="cp_ipselect07">
          <input
            type="radio"
            name="option_lang_sp"
            onChange={e => this.setState({ lang: '' })}
          />
          <i className="toggle cp_sl07_arrowdown" />
          <i className="toggle cp_sl07_arrowup" />
          <span className="cp_sl07_selectlabel">LN</span>
          <label className="option">
            <input
              type="radio"
              name="option_lang_sp"
              value={config.LANG_ENGLISH}
              checked={this.state.lang === config.LANG_ENGLISH}
              onChange={e => {
                this.setState({ lang: e.target.value });
                this.changeLang(e.target.value);
              }}
              onClick={() => {
                this.props.handleDrawerToggle();
              }}
            />
            <span className="cp_sl07_title">EN</span>
          </label>
          <label className="option">
            <input
              type="radio"
              name="option_lang_sp"
              value={config.LANG_JAPANESE}
              checked={this.state.lang === config.LANG_JAPANESE}
              onChange={e => {
                this.setState({ lang: e.target.value });
                this.changeLang(e.target.value);
              }}
              onClick={() => {
                this.props.handleDrawerToggle();
              }}
            />
            <span className="cp_sl07_title">JP</span>
          </label>
        </div>
      );
    }
  }
}

export default Lang;
