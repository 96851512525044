/**
 * プールアカウント
 */
export default class PoolAccount {
  /** アドレス */
  Address;
  /** 表示時の状態 */
  Status;
  /** 優先度 */
  Priority;
  /** 有効開始日 */
  StartDate;
  /** 有効終了日 */
  EndDate;
  /** 一覧表示件数 */
  ViewCount;
  /** 有効・無効*/
  Active;
  /** 利用ERC721コントラクトアドレスリスト */
  ContractAddressList;

  constructor(doc) {
    this.Address = doc.data().Address;
    this.Status = doc.data().Status;
    this.Priority = doc.data().Priority;
    this.StartDate = doc.data().StartDate.toDate();
    this.EndDate = doc.data().EndDate.toDate();

    this.ViewCount = doc.data().ViewCount;
    this.Active = doc.data().Active;
    this.ContractAddressList = doc.data().ContractAddressList;
  }
}
